<div class="head">
    <span class="head_lf"><a (click)="back()">返回</a></span>
    
    忘记密码
    
    <span class="head_rg"><a routerLink="/login" routerLinkActive='active'>登录</a></span>
    </div>
    
    <div class="zhuce">
        <div class="text">
            <span>姓名</span>
            <input type="text" placeholder="请输入姓名" id="name"  [(ngModel)]="newUser.name" class="input">
        </div>
        <div class="text">
            <span>手机号</span>
            <input type="text" placeholder="请输入手机号" id="phoneNumber"  [(ngModel)]="newUser.phoneNumber" class="input" >
        </div>
        <div class="text">
            <span>身份证</span>
            <input type="text" placeholder="请输入身份证" id="idNumber"  [(ngModel)]="newUser.idNumber" class="input">
        </div>
        <div class="text">
            <span>密 码</span>
            <input type="password" placeholder="请输入新密码" id="password"  [(ngModel)]="newUser.password" class="input">
        </div>
        <div class="text">
            <span>确认密码</span>
            <input type="password" placeholder="请输入确认新密码" id="passwordAgain"  [(ngModel)]="newUser.passwordAgain" class="input">
        </div>

        <div class="btndl"><input type="submit" value="變 更"  (click)="doSubmit()" ></div>
    </div>