
    <div class="head">
    
        
        查詢
         
        </div>
        
        <div class="zhuce">   
            <div class="text">
                <span class="title">查詢條件：</span>
                <div>
                    <div class="widget-body no-padding">
                        <table id="dt-table" class="table table-striped table-bordered table-hover" style="width:100%">
                            <span class="inputSpan">姓名:</span>
                            <input type="text" placeholder="请输入姓名"   class="input" [(ngModel)]="patientInfoSearch.name">
                            <span class="inputSpan">住院号:</span>
                            <input type="text" placeholder="请输入住院号"   class="input" [(ngModel)]="patientInfoSearch.inpatientNumber"> 
                            <span class="inputSpan">身份證:</span>
                            <input type="text" placeholder="请输入住身份證"   class="input" [(ngModel)]="patientInfoSearch.idNumber">                        
                        </table>
                    </div>
            </div>
             
        </div>

            
            <div class="btndl"><input type="submit" value="搜 索"  (click)="doSubmit()" ></div>
 
            <div class="text" *ngIf="count != 0">
            <span class="title">查詢結果（{{count}}條記錄）：</span>
            <div >
                <div class="widget-body no-padding">
                    <table id="dt-table" class="table table-striped table-bordered table-hover" style="width:100%">
                        <thead class="table_head">                      
                            <tr>
                                <th>導入日期</th>
                                <th>姓名</th>
                                <th>性别</th>
                                <th>年齡</th>
                                <th>入院日期</th>
                                <th>联系方式</th>
                                <th>身份证</th>
                                <th>住院号</th>
                                <th>身高</th>
                                <th>体重</th>
                                <th>高血压</th>
                                <th>糖尿病</th>
                                <th>高血脂</th>
                                <th>慢病</th>
                                <th>临床诊断</th>
                                <th>其他檢測</th>
                            </tr>
                           
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of patientInfoList">
                                <th>{{item.importDate}}</th>
                                <th>{{item.name}} </th>
                                <th>{{item.sexFlg}}</th>
                                <th>{{item.age}}</th>
                                <th>{{item.dateOfAdmission}}</th>
                                <th>{{item.contact}}</th>
                                <th>{{item.idNumber}}</th>
                                <th>{{item.inpatientNumber}}</th>
                                <th>{{item.height}}</th>
                                <th>{{item.weight}}</th>
                                <th>{{item.hypertensionFlg}}</th>
                                <th>{{item.diabetesMellitusFlg}}</th>
                                <th>{{item.hyperlipemiaFlg}}</th>
                                <th>{{item.chronicDisease}}</th>
                                <th>{{item.clinicalDiagnosis}}</th>
                                <th><a (click)="find(item.patientInfoId)">詳 細</a>
                                </th>                       
                            </tr>
                        </tbody>
                    </table>
                </div>
        </div>
         
    </div>

    <div class="pageText" *ngIf="count != 0">
        <page [pageParams]="{pageSize:pageSize,totalNum:totalNum,curPage:pageSize/pageData,totalPage:totalPage,pageData:pageData,inputPageNo:inputPageNo}"
        (changeCurPage)="getPageData($event)"></page>
     
</div>


   

</div>