<div class="head">

    分析情况
    
    </div>
    <div>
        <a routerLink="/index" routerLinkActive='active' class='backNav'>返回导航</a>
    
     </div>
    <div class="zhuce">
        <div class="text" *ngFor="let item of analyseHistoryList" >
            <span>分析时间: {{item.analyseDate}} </span>
            <div class="next"><input type="submit" value="查 看" (click)="find(item.analyseHistoryId)"></div>
            
        </div>
        
        <div class="text1">
        <div class="btndl"><input type="submit" value="分 析" (click)="next()"></div>
    </div>
    </div>

        