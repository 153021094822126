   <div class="head">
    
    我的资料
    
    </div>
 <div>
    <a routerLink="/index" routerLinkActive='active' class='backNav'>返回导航</a>

 </div>
    <div class="zhuce">
     
        <div class="text">
            <span>姓名</span>
            <input type="text" placeholder="请输入姓名" id="name"  [(ngModel)]="newPersonalInfo.name" class="input" maxlength=10>
        </div>
        <div class="text">
            <span>性别<small>*</small></span>
            <select class="input" [(ngModel)]="newPersonalInfo.sexFlg" >
                <option value ="">请输入</option>
                <option value="1">男</option>
                <option value="2">女</option>
              </select>
        </div>


        <div class="text">
            <span>年龄<small>*</small></span>
            <input type="text" placeholder="请输入年龄"   [(ngModel)]="newPersonalInfo.age" class="input" maxlength=3>
        </div>
        <div class="text">
            <span>手機號碼</span>
            <input type="text" placeholder="请输入电话"   [(ngModel)]="newPersonalInfo.phoneNumber" class="input" maxlength=11>
        </div>
        <div class="text">
            <span>身份证</span>
            <input type="text" placeholder="请输入身份证"   [(ngModel)]="newPersonalInfo.idNumber" class="input" maxlength=18>
        </div> 
        <div class="text">
            <span>身高(cm)</span>
            <input type="text" placeholder="请输入身高"  [(ngModel)]="newPersonalInfo.height" class="input" maxlength=3>
        </div>
        <div class="text">
            <span>体重(kg)</span>
            <input type="text" placeholder="请输入体重"   [(ngModel)]="newPersonalInfo.weight" class="input" maxlength=5>
        </div>
        <div class="text">
            <span>腰围(cm)</span>
            <input type="text" placeholder="请输入腰围"   [(ngModel)]="newPersonalInfo.waistline" class="input" maxlength=5>
        </div>
        <div class="text">
            <span>门诊日期</span>
            <input type="date" placeholder="请输入门诊日期"  [(ngModel)]="newPersonalInfo.outpatientDate" >
        </div>
        <div class="text">
            <span>病程时间(月)</span>
            <input type="text" placeholder="请输入病程时间"   [(ngModel)]="newPersonalInfo.durationOfDisease" class="input" maxlength=3>
        </div>
        <div class="text">
            <span>就诊血压(收缩压)</span>
            <input type="text" placeholder="请输入就诊血压"   [(ngModel)]="newPersonalInfo.bloodPressureHigh" class="input" maxlength=3>
        </div>
        <div class="text">
            <span>就诊血压(舒张压)</span>
            <input type="text" placeholder="请输入就诊血压"   [(ngModel)]="newPersonalInfo.bloodPressureLow" class="input" maxlength=3>
        </div>
        <div class="text">
            <span>ABO血型</span>
            <select class="input" [(ngModel)]="newPersonalInfo.aboBloodGroupFlg">
                <option value ="">请输入</option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="O">O</option>
                <option value="AB">AB</option>
              </select>
        </div>
        <div class="text">
            <span>Rh血型</span>
            <select class="input" [(ngModel)]="newPersonalInfo.rhBloodGroupFlg">
                <option value ="">请输入</option>
                <option value="1">未查</option>
                <option value="2">阴性</option>
                <option value="3">阳性</option>
              </select>
        </div>
        <div class="text">
            <span>职业类型</span>
            <select class="input" [(ngModel)]="newPersonalInfo.occupationFlg">
                <option value ="">请输入</option>
                <option value ="1">国家公务员</option>
                <option value ="2">专业技术人员</option>
                <option value="3">工人</option>
                <option value="4">农名 </option>
                <option value="5">学生</option>
                <option value="6">现役军人</option>
                <option value ="7">自由职业者</option>
                <option value ="8">职员</option>
                <option value="9">企业管理人员 </option>
                <option value="10">个体经营者</option>
                <option value="11">无业人员</option>
                <option value="12">退（离）休人员</option>
                <option value ="13">其他</option>
              </select>
        </div>
        <div class="text">
            <span>民族</span>
            <select  class="input" [(ngModel)]="newPersonalInfo.nationFlg" (change)="selectChange();">
                <option value ="">请输入</option>
                <option value ="1">汉族</option>
                <option value ="2">少数民族</option> 
              </select>
              <span id="otherNationSpan">少数民族</span>
              <input type="text" id="otherNationInput" placeholder="请输入其他民族"   [(ngModel)]="newPersonalInfo.otherNation" class="input" maxlength=10>
        </div>



        <div class="text1">

        <div class="btndl"><input type="submit" value="保 存"  (click)="doSubmit()" ></div>
       
    </div>
    </div>