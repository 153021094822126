import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgetPasswordComponent } from './module/customer/authentication/components/forget-password/forget-password.component';
import { LoginComponent } from './module/customer/authentication/components/login/login.component';
import { RegisterComponent } from './module/customer/authentication/components/register/register.component';
import { IndexComponent } from './module/customer/userInfo/components/index/index.component';
import { BodyInfoComponent } from './module/customer/userInfo/components/body-info/body-info.component';
import { PersonalInfoComponent } from './module/customer/userInfo/components/personal-info/personal-info.component';
import { AnalyseHistoryComponent } from './module/customer/analyse/components/analyse-history/analyse-history.component';
import { PolypInformationComponent } from './module/customer/analyse/components/polyp-information/polyp-information.component';
import { LaboratoryExaminationComponent } from './module/customer/analyse/components/laboratory-examination/laboratory-examination.component';
import { HistoryInformationComponent } from './module/customer/analyse/components/history-information/history-information.component';


//admin
import { privateInfoComponent } from './module/customer/authentication/components/privateInfo/privateInfo.component';
import { DisclaimerComponent } from './module/customer/authentication/components/disclaimer/disclaimer.component';
import { AdminLoginComponent } from './module/admin/components/admin-login/admin-login.component';
import { ImportPatientInfoComponent } from './module/admin/components/importPatientInfo/importPatientInfo.component';
import { SearchPatientInfoComponent } from './module/admin/components/searchPatientInfo/searchPatientInfo.component';
import { FindPatientDetailInfoComponent } from './module/admin/components/findPatientDetailInfo/findPatientDetailInfo.component';
const routes: Routes = [


  {path:'index',component: IndexComponent},
  {path:'forgetPassword',component:ForgetPasswordComponent},
  {path:'login',component:LoginComponent},
  {path:'register',component:RegisterComponent},
  {path:'privateInfo',component:privateInfoComponent},
  {path:'disclaimer',component:DisclaimerComponent},

  {path:'personalInfo',component:PersonalInfoComponent},
  {path:'bodyInfo',component:BodyInfoComponent},
  {path:'analyseHistory',component:AnalyseHistoryComponent},
 
  {path:'polypInformation',component:PolypInformationComponent},
  {path:'laboratoryExamination',component:LaboratoryExaminationComponent},

  {path:'historyInformation',component:HistoryInformationComponent},
  {path:'**',redirectTo:'index'},
  
  // {path:'admin',component:AdminLoginComponent},
  {path:'import',component: ImportPatientInfoComponent},
  {path:'search',component: SearchPatientInfoComponent, data: {keep: true}} ,
  {path:'findDetail',component: FindPatientDetailInfoComponent},


  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
 