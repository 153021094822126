import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { polypInformation} from '../../../stage/polypInformation';
import { SessionService } from '../../../services/session.service';
import { analyseHistory} from '../../../stage/analyseHistory';
import { ValidationService } from '../../../services/validation.service';
@Component({
  selector: 'app-polyp-information',
  templateUrl: './polyp-information.component.html',
  styleUrls: ['./polyp-information.component.scss']
})
export class PolypInformationComponent implements OnInit {

  public newPolypInformation:polypInformation=new polypInformation();

  public analyseHistory:analyseHistory=new analyseHistory();
  constructor(public router:Router,public sessionService:SessionService,public validationService:ValidationService ) { 
  }

  ngOnInit(): void {

    if(this.sessionService.getSession("newAnalyse")){
      this.analyseHistory=this.sessionService.getSession("newAnalyse");

    }
    const newPolypInformation=this.analyseHistory.polypInformation;

    if(newPolypInformation){
      this.newPolypInformation=newPolypInformation;

    }

    this.gallstoneDivSelect();
    
  }  
  
  next(){


    if(!this.validationCheck()){
      return;
 
     }

    this.analyseHistory.polypInformation=  this.newPolypInformation

    
    this.sessionService.putSession("newAnalyse",this.analyseHistory);

        this.router.navigate(['/laboratoryExamination']);
  }


  gallstoneDivSelect(){
    let gallstoneDiv :any = document.getElementById("gallstoneDiv");
    if(this.newPolypInformation.gallstone!="1"){
      gallstoneDiv.style.display="none";

      this.newPolypInformation.size="";
      this.newPolypInformation.gallstoneTime="";

    }else{
      gallstoneDiv.style.display="";
    }

  }

  validationCheck(){


    if(!this.validationService.selectMustCheck("性別",this.newPolypInformation.sexFlg)){
      return false;
    }


    if(!this.validationService.inputNumberAndPointCheck("年齡",this.newPolypInformation.age)){
      return false;
    }


    if(!this.validationService.inputNumberAndPointCheck("初次发现息肉大小",this.newPolypInformation.polypFirst)){
      return false;
    }
    if(!this.validationService.inputNumberAndPointMustCheck("目前息肉大小",this.newPolypInformation.polypNow)){
      return false;
    }
    // if(!this.validationService.selectMustCheck("不能用其他原因解释的相关症状",this.newPolypInformation.relatedSymptoms)){
    //   return false;
    // }
    if(!this.validationService.selectMustCheck("息肉数目",this.newPolypInformation.polypCount)){
      return false;
    }
    if(!this.validationService.selectMustCheck("基底部",this.newPolypInformation.basalPart)){
      return false;
    }
    if(!this.validationService.selectMustCheck("是否合并胆囊结石",this.newPolypInformation.gallstone)){
      return false;
    }
    if(!this.validationService.dateCheck("发现胆囊结石时间",this.newPolypInformation.gallstoneTime)){
      return false;
    }
    if(!this.validationService.inputNumberAndPointCheck("胆囊结石(最大径)",this.newPolypInformation.size)){
      return false;
    }
    if(!this.validationService.selectMustCheck("合并原发性硬化性胆管炎",this.newPolypInformation.primarySclerosingCholangitis)){
      return false;
    }
    // if(!this.validationService.selectMustCheck("胆囊炎：至少发作一次",this.newPolypInformation.cholecystitis)){
    //   return false;
    // }
    if(!this.validationService.selectMustCheck("是否有過急性胆囊炎",this.newPolypInformation.isAcuteCholecystitis)){
      return false;
    }
    return true; 
    }


  back(){ history.go(-1);}
}