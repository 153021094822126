
    <div class="head">
    
        
        导入数据
        
        
        </div>
        
        <div class="zhuce">
            <div class="text">
                <span >读取文件(.xsl)</span>
                 <input type="file" id="excel-file" (change)="updateAbC($event)" multiple="false"  accept=".xlsx"> 
            </div>           
            <div class="btndl"><input type="submit" value="导 入"  (click)="doSubmit()" ></div>
        </div>
         
