import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http'
import { Observable } from 'rxjs';
import { patientInfo } from '../stage/patientInfo';
import { environment } from '../../../../environments/environment';
import { page } from '../stage/page';
@Injectable({
  providedIn: 'root'
})
export class AdminService {

  //public apiUrl:String = "http://localhost:8080/"
  public apiUrl:String = environment.apiUrl
  constructor(public httpClient:HttpClient) { }


  Login(token:string): Observable<{}>{


    const httpOptions ={headers: new HttpHeaders({'Content-Type':'application/json','token':token}) } 
    return this.httpClient.post(this.apiUrl+"adminLogin",httpOptions)

  }
  importPatientInfo(patientInfo:Array<patientInfo>,token:string): Observable<{}>{


    const httpOptions ={headers: new HttpHeaders({'Content-Type':'application/json','token':token}) } 
    return this.httpClient.post(this.apiUrl+"importPatientInfo",patientInfo,httpOptions)
    
  }

  getPatientInfo(patientInfoId:String,token:string): Observable<{}>{


    const httpOptions ={headers: new HttpHeaders({'Content-Type':'application/json','token':token}) } 
    return this.httpClient.post(this.apiUrl+"getPatientInfo",patientInfoId,httpOptions)



    
  }

  searchPatientInfo(patientInfoSearch:patientInfo,token:string): Observable<{}>{


    const httpOptions ={headers: new HttpHeaders({'Content-Type':'application/json','token':token}) } 
    return this.httpClient.post(this.apiUrl+"searchPatientInfo",patientInfoSearch,httpOptions)



    
  }

  searchPatientInfoPage(page:page,token:string): Observable<{}>{


    const httpOptions ={headers: new HttpHeaders({'Content-Type':'application/json','token':token}) } 
    return this.httpClient.post(this.apiUrl+"searchPatientInfoPage",page,httpOptions)



    
  }

}
