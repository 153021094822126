export class personalInfo{
  userId:string='';
  name:string='';//姓名
  sexFlg:string='';//性别
  age:string='';//年龄
  phoneNumber:string='';//手机号
  idNumber:string='';//身份证
  height:string='';//身高 
  weight:string='';//体重
  waistline:string='';//腰围
  outpatientDate:string='';//门诊日期
  durationOfDisease:string='';//病程时间
  bloodPressureHigh:string='';//就诊血压
  bloodPressureLow:string='';//就诊血压
  aboBloodGroupFlg:string='';//ABO血型
  rhBloodGroupFlg:string='';//Rh血型
  occupationFlg:string='';//职业类型
  nationFlg:string='' //民族
  otherNation:string='' //其他民族
};