import { Component, OnInit } from '@angular/core';
import { laboratoryExamination} from '../../../stage/laboratoryExamination';
import { analyseHistory} from '../../../stage/analyseHistory';
import { polypInformation} from '../../../stage/polypInformation';
import { AnalyseService } from '../../../services/analyse.service';
import { SessionService } from '../../../services/session.service';
import { Router } from '@angular/router';
import { ResultMessageComponent } from '../../../resultMessage/resultMessage.component';
import { MatDialog } from '@angular/material/dialog';
import { ValidationService } from '../../../services/validation.service';
@Component({
  selector: 'app-laboratory-examination',
  templateUrl: './laboratory-examination.component.html',
  styleUrls: ['./laboratory-examination.component.scss']
})
export class LaboratoryExaminationComponent implements OnInit {

  public analyseHistory:analyseHistory=new analyseHistory();
  public newLaboratoryExamination:laboratoryExamination=new laboratoryExamination();
  public newpolypInformation:polypInformation=new polypInformation();


  constructor(private dialog: MatDialog,public router:Router,public sessionService:SessionService,public analyseService:AnalyseService,public validationService:ValidationService ) { }

  ngOnInit(): void {



    
    this.analyseHistory=this.sessionService.getSession("newAnalyse");

    const newLaboratoryExamination=this.analyseHistory.laboratoryExamination;

    if(newLaboratoryExamination){
      this.newLaboratoryExamination=newLaboratoryExamination;

    }
    this.DyslipidemiaDivSelect();
  }
 
  doSubmit(){

    if(!this.validationCheck()){
      return;
 
     }


this.analyseHistory.laboratoryExamination=this.newLaboratoryExamination;


this.sessionService.putSession("newAnalyse", this.analyseHistory);


const user=this.sessionService.getSession("user");

this.analyseService.changeAnalyseHistory(this.analyseHistory,'111').toPromise().then(dataJson=>{
    
    const response = JSON.parse(JSON.stringify((<any>dataJson) ))


    let dialogRef =this.dialog.open(ResultMessageComponent,{ data: {
      analyseResult: response.data.analyseResult
    
      }});
    
     dialogRef.afterClosed().subscribe(result => {
      this.sessionService.remove("newAnalyse");
      this.router.navigate(['/index']); 
    }); 
  }).catch(err =>{
    this.validationService.showSysErroMessage();
    return;
  })


  }

  validationCheck(){

    if(this.newLaboratoryExamination.dyslipidemia=="1"){

      if(!this.validationService.inputNumberAndPointCheck("总胆固醇",this.newLaboratoryExamination.totalCholesterol)){
        return false;
      }
      if(!this.validationService.inputNumberAndPointCheck("甘油三脂",this.newLaboratoryExamination.triglyceride)){
        return false;
      }
      if(!this.validationService.inputNumberAndPointCheck("高密度脂蛋白胆固醇",this.newLaboratoryExamination.highDensityLipoprotein)){
        return false;
      }
      if(!this.validationService.inputNumberAndPointCheck("低密度脂蛋白胆固醇",this.newLaboratoryExamination.lowDensityLipoprotein)){
        return false;
      }
      if(!this.validationService.inputNumberAndPointCheck("非高密度脂蛋白胆固醇",this.newLaboratoryExamination.nonHighDensityLipoprotein)){
        return false;
      }

    }

    if(!this.validationService.selectMustCheck("总胆红素异常",this.newLaboratoryExamination.abnormalTotalBilirubin)){
      return false;
    }

    if(!this.validationService.inputNumberAndPointCheck("糖类抗原CA199",this.newLaboratoryExamination.ca199)){
      return false;
    }
    if(!this.validationService.inputNumberAndPointCheck("糖类抗原CA125",this.newLaboratoryExamination.ca125)){
      return false;
    }
    if(!this.validationService.inputNumberAndPointCheck("CA153",this.newLaboratoryExamination.ca153)){
      return false;
    }
    if(!this.validationService.inputNumberAndPointCheck("糖类抗原CA724",this.newLaboratoryExamination.ca724)){
      return false;
    }
    if(!this.validationService.inputNumberAndPointCheck("糖类抗原CA50",this.newLaboratoryExamination.ca50)){
      return false;
    }
    if(!this.validationService.inputNumberAndPointCheck("肿瘤相关抗原CA242",this.newLaboratoryExamination.c242)){
      return false;
    }
    if(!this.validationService.inputNumberAndPointCheck("AFP",this.newLaboratoryExamination.afp)){
      return false;
    }
    if(!this.validationService.inputNumberAndPointCheck("CEA",this.newLaboratoryExamination.cea)){
      return false;
    }
    return true; 
    }



  DyslipidemiaDivSelect(){

    let DyslipidemiaDiv :any = document.getElementById("dyslipidemiaDiv");
    if(this.newLaboratoryExamination.dyslipidemia!="1"){
      DyslipidemiaDiv.style.display="none";

      this.newLaboratoryExamination.totalCholesterol="";
      this.newLaboratoryExamination.triglyceride="";
      this.newLaboratoryExamination.highDensityLipoprotein="";
      this.newLaboratoryExamination.lowDensityLipoprotein="";
      this.newLaboratoryExamination.nonHighDensityLipoprotein="";



    }else{
      DyslipidemiaDiv.style.display="";
    }

  }


  back(){ history.go(-1);}


}
