import { Component, OnInit} from '@angular/core';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
@Component({

templateUrl: 'privateInfo.component.html',
styleUrls: ['./privateInfo.component.scss']
})
export class privateInfoComponent implements OnInit {

public paracont:any;
public value:any;



ngOnInit(): void {

  let okbutton :any = document.getElementById("okbutton");
  okbutton.disabled= true;



  const numbers = interval(1000);
  const takeFourNumbers = numbers.pipe(take(5));
  takeFourNumbers.subscribe(
      x => {
        this.paracont = "("+(5-x)+")";
 
      },
      error => {},
      () => {
          this.paracont="了解"
          okbutton.disabled= false;
      
     });   



}

constructor( 
  private router:Router
  ) {    
}

ok(){

  history.go(-1)


}



}