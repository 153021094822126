export class laboratoryExamination{
  
  analyseHistoryId:string='';
  laboratoryExaminationId:string='';
  dyslipidemia:string='';// 血脂异常

  totalCholesterol:string='';//总胆固醇
  triglyceride:string='';//甘油三脂
  highDensityLipoprotein:string='';//高密度脂蛋白胆固醇
  lowDensityLipoprotein:string='';//低密度脂蛋白胆固醇
  nonHighDensityLipoprotein:string='';//非高密度脂蛋白胆固醇


  abnormalTotalBilirubin:string='';// 血脂异常

  ca199:string='';//糖类抗原CA19-9
  ca125:string='';//糖类抗原CA-125
  ca153:string='';//CA153
  ca724:string='';//糖类抗原CA72-4
  ca50:string='';//糖类抗原CA50
  c242:string='';//肿瘤相关抗原CA242
  afp:string='';//AFP
  cea:string='';//CEA
  
};