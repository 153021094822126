<!-- <h1 mat-dialog-title>Message</h1>
 <mat-dialog-content> </mat-dialog-content>
<mat-dialog-actions>
 <button mat-raised-button (click)="closeMe()">Close</button>
</mat-dialog-actions> -->
<h1 >提示</h1>

<div >


    <ul>
        <li *ngFor="let item of message">{{item}}</li>
    </ul>
          
           
       <div>
    <div class="next"><input type="submit" (click)="yes()" value="同 意"></div>
    <div class="next"><input type="submit" (click)="no()" value="取 消"></div>
</div>
</div>



