<div class="head">

    配套相关实验室检查/B超等
    
    </div>
    
    <div class="zhuce"> 
        



        <div class="text">            
                <span>血脂异常</span>
                <select class="select" [(ngModel)]="newLaboratoryExamination.dyslipidemia" (change)="DyslipidemiaDivSelect()">
                    <option value="">请选择</option>
                    <option value="1">是</option>
                    <option value="2">否</option>
                </select> 
                <div id="dyslipidemiaDiv">
                <span>总胆固醇(CHOL)</span>
                <input type="text"  class="input" [(ngModel)]="newLaboratoryExamination.totalCholesterol" placeholder="请输入mmol/L"  maxlength="5"/>
       
                <span>甘油三脂(TG) </span>
                <input type="text"    class="input" [(ngModel)]="newLaboratoryExamination.triglyceride" placeholder="请输入mmol/L"  maxlength="5"/>
       
                <span>高密度脂蛋白胆固醇(HDL)  </span>
                <input type="text"     class="input" [(ngModel)]="newLaboratoryExamination.highDensityLipoprotein" placeholder="请输入mmol/L"  maxlength="5"/>
   
                <span>低密度脂蛋白胆固醇(LDL) </span>
                <input type="text"     class="input" [(ngModel)]="newLaboratoryExamination.lowDensityLipoprotein" placeholder="请输入mmol/L"  maxlength="5"/>
         
                <span>非高密度脂蛋白胆固醇(NHDL)  </span>
                <input type="text"     class="input" [(ngModel)]="newLaboratoryExamination.nonHighDensityLipoprotein" placeholder="请输入mmol/L"  maxlength="5"/>

            </div>
            
        </div>



        <div class="text">            
            <span>总胆红素异常<small>*</small></span>
            <select class="select" [(ngModel)]="newLaboratoryExamination.abnormalTotalBilirubin">
                <option value="">请选择</option>
                <option value="1">是</option>
                <option value="2">否</option>
            </select> 
    </div>

        <div class="text">
            <span>肿瘤标记物指标</span>

            <span>糖类抗原CA199</span>
            <input type="text"   class="input" placeholder="请输入U/ml"  [(ngModel)]="newLaboratoryExamination.ca199"  maxlength="5"/>

            <span>糖类抗原CA125</span>
            <input type="text"    class="input" placeholder="请输入U/ml"  [(ngModel)]="newLaboratoryExamination.ca125"  maxlength="5"/>

            <span>CA153</span>
            <input type="text"    class="input" placeholder="请输入U/ml"  [(ngModel)]="newLaboratoryExamination.ca153"  maxlength="5"/>

            <span>糖类抗原CA724</span>
            <input type="text"    class="input" placeholder="请输入U/ml"  [(ngModel)]="newLaboratoryExamination.ca724"  maxlength="5"/>

            <span>糖类抗原CA50</span>
            <input type="text"   class="input" placeholder="请输入U/ml"  [(ngModel)]="newLaboratoryExamination.ca50"  maxlength="5"/>

            <span>肿瘤相关抗原CA242</span>
            <input type="text"    class="input" placeholder="请输入U/ml"  [(ngModel)]="newLaboratoryExamination.c242"  maxlength="5"/>

            <span>AFP</span>
            <input type="text"    class="input" placeholder="请输入U/ml"  [(ngModel)]="newLaboratoryExamination.afp"  maxlength="5"/>

            <span>CEA</span>
            <input type="text"    class="input" placeholder="请输入U/ml"  [(ngModel)]="newLaboratoryExamination.cea"  maxlength="5"/>
        </div>



        <div class="text1">

        <div class="btndl">
        <input type="submit" value="提 交" (click)="doSubmit()">
        <input type="submit" value="返 回" (click)="back()" >
        </div>
    </div>