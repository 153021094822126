<!-- <h1 mat-dialog-title>Message</h1>
 <mat-dialog-content> </mat-dialog-content>
<mat-dialog-actions>
 <button mat-raised-button (click)="closeMe()">Close</button>
</mat-dialog-actions> -->
<h1>提示</h1>

<div class="dialogScss">

    <div class="text">
    <ul>
        <li *ngFor="let item of message">{{item}}</li>
    </ul>
          
  </div>     
        


<div class="next"><input type="submit" (click)="closeMe()" value="確 定"></div>

</div>