<div class="head">
    <span class="head_lf"></span>
    
    App隐私政
    
    <span class="head_rg"></span>
    </div>
<div class="w">
  <div class="border">
      <div class="borderl">
          <a href="#">胆囊疾病养护小贴士隐私政策&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
      </div>
      <div class="borderr">
          <div class="top1">
              <p>隐私政策</p>
              <p>PRIVACY POLICY</p>

          </div>
          <div class="bottom1">
              <div class="title-1">引言</div>
              <div class="icon-detitle"></div>
              <p class="D1">
                  本公司（以下简称
                  “我们”）非常重视用户的隐私和个人信息保护。您在使用我们的产品与/或服务时，我们可能会收集和使用您的相关信息。我们希望通过（以下简称“本隐私政策”）向您说明在您使用我们的产品与/或服务时，我们如何收集、使用、保存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。
              </p>
              <p class="D2">
                  <strong>本隐私政策将帮助您了解以下内容：</strong><br>
                  一、我们如何收集和使用您的个人信息<br>
                  二、我们如何使用 Cookies 和同类技术<br>
                  三、我们如何共享、转让、公开披露您的个人信息<br>
                  四、我们如何保护和保存您的个人信息<br>
                  五、您如何管理个人信息<br>
                  六、未成年人的个人信息保护<br>
                  七、通知和修订<br>
                  八、如何联系我们<br>
              </p>
              <P class="D3">
                  <strong>本隐私政策与您所使用的各种业务功能（以下统称“我们的产品与/或服务”）息息相关，希望您在使用我们的产品与/或服务前仔细阅读并确认您已经充分理解本隐私政策所写明的内容，并让您可以按照本隐私政策的指引做出您认为适当的选择。本隐私政策中涉及的相关术语，我们尽量简明扼要的表述，并提供进一步说明的链接，以便您更好地理解。您开始使用或在我们更新本隐私政策后（我们会及时提示您更新的情况）继续使用我们的产品与/或服务，即意味着您同意本隐私政策（含更新版本）内容，并且同意我们按照本隐私政策收集、使用、保存和共享您的相关信息。</strong>
              </P>
              <p class="D4">
                  <strong>请您特别注意：本隐私政策适用于我们以网站、客户端、小程序以及随技术发展出现的新形态向您提供的各项产品和服务。如我们及关联公司的产品或服务中使用了京东商城的产品或服务（如使用京东商城账户登录）但未设独立隐私权政策的，则本隐私政策同样适用于该部分产品或服务。我们及关联公司就其向您提供的产品或服务单独设有隐私权政策的，则相应产品或服务适用相应隐私权政策。</strong>
              </p>
              <p class="D5">
                  如对本隐私政策或相关事宜有任何问题，发送邮件至1595836192@qq.com或拨打我们的任何一部客服电话等多种方式与我们联系。
              </p>
              <div class="title-2">目录</div>
              <div class="icon-detitle1"></div>
              <p class="D6">
                  <strong>一、我们如何收集和使用您的个人信息</strong>
              </p>
              <p class="D7">
                  个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇的个人信息。本隐私政策中涉及的个人信息包括：基本信息（包括个人姓名、生日、性别、住址、个人电话号码、电子邮箱）;个人身份信息（包括身份证、军官证、护照、驾驶证等）；个人生物识别信息（包括声纹、面部识别特征等）；网络身份标识信息（包括个人信息主体账号、IP地址、个人数字证书等）；个人财产信息（包括交易和消费记录、余额、京豆、优惠券、京东E卡、游戏类兑换码等虚拟财产信息）；通讯录；个人上网记录（包括网页浏览记录、软件使用记录、点击记录）；个人常用设备信息（包括硬件型号、设备MAC地址、操作系统类型、软件列表唯一设备识别码（如IMEI/android
                  ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息等在内的描述个人常用设备基本情况的信息））；个人位置信息（包括行程信息、精准定位信息、住宿信息、经纬度等）；其中，字体加粗部分为涉及的个人敏感信息。
              </p>
              <p class="D8">
                  我们仅会出于以下目的，收集和使用您的个人信息：
              </p>
              <p class="D9">
                  <strong>（一）您须授权我们收集和使用您个人信息的情形</strong>
              </p>
              <p class="D10">
                  <strong>
                      我们的产品与/或服务包括一些核心功能，这些功能包含了实现网上购物所必须的功能及保障交易安全所必须的功能。我们可能会收集和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的产品与/或服务。这些功能包括：
                  </strong>
              </p>
              <p class="D11">
                  <strong>1、实现网上购物所必须的功能</strong>
              </p>
              <p class="D12">
                  <strong>（1）用户注册</strong><br>
                  您至少需要向我们提供您准备使用的京东账户名、密码、您本人的手机号码、电子邮箱地址（用于验证邮箱），我们将通过发送短信验证码或邮件的方式来验证您的身份是否有效。您的账户名为您的默认昵称，您可以修改和补充您的昵称、性别、生日、兴趣爱好以及您的实名认证相关信息，这些信息均属于您的“账户信息”。您补充的账户信息将有助于我们为您提供个性化的商品推荐和更优的购物体验，但如果您不提供这些补充信息，不会影响您使用网上购物的基本功能。<br>
                  <br>
                  <strong>（2）商品信息展示和搜索</strong><br>
                  <strong>为了让您快速地找到您所需要的商品，我们可能会收集您使用我们的产品与/或服务的设备信息（包括设备名称、设备型号、设备识别码、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络ID（PLMN））、浏览器类型来为您提供商品信息展示的最优方式。我们也会为了不断改进和优化上述的功能来使用您的上述信息。</strong><br>
                  <br>
                  您也可以通过搜索来精准地找到您所需要的商品或服务。我们会保留您的搜索内容以方便您重复输入或为您展示与您搜索内容相关联的商品或服务。<br>
                  <br>
                  <strong>请您注意，单独的设备信息、浏览器类型或仅搜索关键词信息无法识别您的身份，不属于您的个人信息，我们有权以任何的目的对其进行使用；只有当您的设备信息、浏览器类型或搜索关键词信息与您的其他信息相互结合使用并可以识别您的身份时，在结合使用期间，我们会将您的设备信息、浏览器类型或搜索关键词信息作为您的个人信息，按照本隐私政策对其进行处理与保护。</strong><br>
                  <br>
                  为了将您感兴趣的商品或服务信息展示给您，或在您搜索时向您展示您可能希望找到的商品或服务，我们可能会收集您的订单信息、浏览信息、您的兴趣爱好（您可以在账户信息中填写）进行数据分析以形成用户画像。我们还可能为了提供服务及改进服务质量的合理需要而获得的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息，以及您与我们的关联方、我们合作伙伴之间互动时我们获得的相关信息。对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务。我们可能会将来自某项服务的信息与来自其他服务的信息结合起来，以便为您提供服务、个性化内容和建议。如果您不希望受个性化内容的影响，您可以在商品展示页选择按照分类进行浏览和查找商品和服务。我们还在搜索结果中向您提供了按照价格、销量排序等不针对个人特征的选项；未经您的明确同意，我们不会将您的相关个人信息提供给第三方。<br>
                  <br>
                  <strong>（3）下单</strong><br>
                  当您准备对您购物车内的商品进行结算时，京东系统会生成您购买该商品的订单。您需要在订单中至少填写您的收货人姓名、收货地址以及手机号码，同时该订单中会载明订单号、您所购买的商品或服务信息、您应支付的货款金额及支付方式；您可以另外填写收货人的固定电话、邮箱地址信息以增加更多的联系方式从而确保商品可以准确送达，但不填写这些信息不影响您订单的生成。<br>
                  <br>
                  <strong>您在京东上预订机票、火车票、酒店、跟团旅行、购买手机号卡或合约机、办理宽带等业务时，您还可能需要根据国家法律法规或服务提供方（包括票务销售方、酒店、旅行社及其授权的代理方、基础电信运营商、移动转售运营商等）的要求提供您的实名信息，这些实名信息可能包括您的身份信息（比如您的出生证明、户口本、身份证、军官证、护照、驾驶证等载明您身份信息的证件复印件或号码）、您本人的照片或视频、姓名、电话号码。这些订单中将可能包含您的行程、酒店地址、您指定的服务地址或设备安装地址（可能是您的家庭地址）信息。</strong><br>
                  <br>
                  上述所有信息构成您的“订单信息”，我们将使用您的订单信息来进行您的身份核验、确定交易、支付结算、完成配送、为您查询订单以及提供客服咨询与售后服务。我们还会使用您的订单信息来判断您的交易是否存在异常以保护您的交易安全。<br>
                  <br>
                  <strong>（4）支付功能</strong><br>
                  在您下单后，您可以选择京东的关联方或与京东合作的第三方支付机构（包括京东支付、微信支付及银联、网联等支付通道，以下统称“支付机构”）所提供的支付服务。支付功能本身并不收集您的个人信息，但我们需要将您的京东订单号及交易金额信息与这些支付机构共享以实现其确认您的支付指令并完成支付。“关联方”指一方现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的法定代表人。“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。<br>
                  <br>
                  <strong>（5）交付产品与/或服务功能</strong><br>
                  在当您下单并选择货到付款或在线完成支付后，京东、京东的关联方、供应商、与京东合作的商家或第三方配送公司（以下简称“配送公司”）将为您完成订单的交付。您知晓并同意京东、京东的关联方、供应商、与京东合作的商家或配送公司会在上述环节内使用您的订单信息以保证您的订购的商品能够安全送达。<br>
                  <br>
                  我们的配送员在为您提供配送服务的同时，基于某些业务法律要求实名认证的需求，会协助您完成实名认证，例如在您购买手机号卡服务时。我们在此环节会使用您的身份证通过国家有权认证机构的专有设备对您的身份进行核验，我们不会在此环节收集您的身份证信息，且我们的配送员均须遵守公司保密制度的规定。<br>
                  <br>
                  <strong>（6）客服与售后功能</strong><br>
                  我们的电话客服和售后功能会使用您的账号信息和订单信息。
                  <br>
                  为保证您的账号安全，我们的呼叫中心客服和在线客服会使用您的账号信息与您核验您的身份。当您需要我们提供与您订单信息相关的客服与售后服务时，我们将会查询您的订单信息。您有可能会在与我们的客服人员沟通时，提供上述信息外的其他信息，例如当您要求我们变更配送地址、联系人或联系电话时。<br>
                  <br>
                  <strong>2、保障交易安全所必须的功能</strong><br>
                  <br>
                  为提高您使用我们的产品与/或服务时系统的安全性，更准确地预防钓鱼网站欺诈和保护账户安全，我们可能会通过了解您的浏览信息、订单信息、您常用的软件信息、设备信息来判断您的账号风险，并可能会记录一些我们认为有风险的链接（“URL”）。我们也会收集您的设备信息对京东系统问题进行分析、统计流量并排查可能存在的风险，在您选择向我们发送异常信息时予以排查。<br>
              </p>
              <p class="D13">
                  <strong>（二）您可自主选择提供个人信息的情形</strong>
              </p>
              <p class="D14">
                  1、为使您购物更便捷或更有乐趣，从而提升您在京东的网上购物体验，我们的以下扩展功能中可能会收集和使用您的个人信息。如果您不提供这些个人信息，您依然可以进行网上购物，但您可能无法使用为您带来购物乐趣的扩展功能或在购买某些商品时需要重复填写一些信息。这些扩展功能包括：<br>
                  <br>
                  <strong>（1）基于位置信息的个性化推荐功能：我们会收集您的位置信息（我们仅收集您当时所处的地理位置，但不会将您各时段的位置信息进行结合以判断您的行踪轨迹）来判断您所处的地点，自动为您推荐您所在区域可以购买的产品与/或服务，</strong>例如向您推荐离您最近的可选酒店。<br>
                  <br>
                  （2）基于摄像头（相机）的扩展功能：您可以使用这项扩展功能完成视频拍摄、拍照、扫码以及人脸识别登录的功能。当您使用人脸识别登录时我们会收集您的面部信息。未来我们可能会将人脸识别技术应用于更多场景，届时我们会再次与您确认您是否愿意我们使用您的面部信息来实现这些扩展功能。<br>
                  <br>
                  （3）基于图片上传的扩展功能：您可以在京东上传您的照片来实现拍照购物功能和晒单、评价及问答功能，我们会使用您所上传的图片或视频来识别您需要购买的产品与/或服务，或者使用包含您所上传图片或视频的晒单、评价及问答内容。<br>
                  <br>
                  （4）基于语音技术的扩展功能：您可以直接使用麦克风来进行语音购物或与我们的客服进行咨询和互动。在这些功能中我们会收集您的录音内容以识别您的购物需求以及客服与售后需求。<br>
                  <br>
                  （5）基于通讯录信息的扩展功能：在这项功能中我们将收集您的通讯录信息以方便您在购物时不需再手动输入您通讯录中联系人的信息（例如您可以直接为通讯录中的电话号码充值）。为了增加您购物时的社交乐趣，在获得您的同意时我们也可以判断您的好友是否也同为京东用户，并在京东为你们的交流建立联系。<br>
                  <br>
                  2、上述扩展功能可能需要您在您的设备中向我们开启您的地理位置（位置信息）、相机（摄像头）、相册（图片库）、麦克风以及通讯录的访问权限，以实现这些功能所涉及的信息的收集和使用。您可以在京东商城通过访问“账户设置—通用—隐私设置”页面逐项查看您上述权限的开启状态，并可以决定将这些权限随时的开启或关闭（我们会指引您在您的设备系统中完成设置）。请您注意，您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。<br>
                  <br>
                  <strong>（三）您应充分知晓，以下情形中，我们收集、使用个人信息无需征得您的授权同意：<br>
                      <br>
                      1、与国家安全、国防安全有关的；<br>
                      2、与公共安全、公共卫生、重大公共利益有关的；<br>
                      3、与犯罪侦查、起诉、审判和判决执行等有关的；<br>
                      4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br>
                      5、所涉及的个人信息是个人信息主体或监护人自行向社会公众公开的；<br>
                      6、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；<br>
                      7、根据您的要求签订和履行合同所必需的；<br>
                      8、用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；<br>
                      9、为合法的新闻报道所必需的；<br>
                      10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；<br>
                      11、法律法规规定的其他情形。</strong><br>
                  <br>
                  <br>
                  <strong>（四）我们从第三方获得您个人信息的情形</strong><br>
                  <br>
                  我们可能从第三方获取您授权共享的账户信息（头像、昵称），并在您同意本隐私政策后将您的第三方账户与您的京东账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与/或服务。我们会将依据与第三方的约定，对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。<br>
                  <br>
                  <strong>（五）您个人信息使用的规则</strong><br>
                  <br>
                  1、我们会根据本隐私政策的约定，为实现我们的产品与/或服务功能而对所收集的个人信息进行使用。<br>
                  <br>
                  2、在收集您的个人信息后，我们将通过技术手段对信息进行去标识化处理，去标识化处理的信息将无法识别主体。请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。<br>
                  <br>
                  3、请您了解并同意，对于您在使用我们的产品与/或服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则将被视为在您使用我们的产品与/或服务期间持续授权同意我们使用。在您注销账号时，我们将停止使用并删除您的个人信息，具体请详见本隐私政策附件《京东账户注销须知》。<br>
                  <br>
                  4、我们会对我们的产品与/或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。<br>
                  <br>
                  5、当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。<br>
                  <br>
                  6、当我们要将您的个人信息用于本隐私政策未载明的其它用途时，或基于特定目的收集而来的个人信息用于其他目的时，会事先征求您的明示同意。请您理解，我们向您提供的产品与/或服务将不断更新变化。如果您选择使用本隐私政策中尚未列明的其他产品与/或服务时，我们会在收集您的个人信息前通过协议、页面提示等方式向您详细说明信息收集的目的、方式、范围并征求您的同意。若您不同意提供前述信息，您可能无法使用该项产品与/或服务，但不影响您使用现有产品与/或服务。
                  <br>
              </p>
          </div>
      </div>
      <div class="next"><input id="okbutton" type="submit"  (click)="ok()" value="{{paracont}}"></div>
  </div>

</div>