import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { patientInfo } from '../../stage/patientInfo';
import { NavigationEnd,Router,NavigationExtras } from '@angular/router';
import { page } from '../../stage/page';

import { filter } from 'rxjs/operators';
//npm install xlsx
 
//npm install @types/xlsx
@Component({
  selector: 'app-admin-searchPatientInfo',
  templateUrl: './searchPatientInfo.component.html',
  styleUrls: ['./searchPatientInfo.component.scss']
})
export class SearchPatientInfoComponent implements OnInit {

  constructor(private adminService: AdminService,public router:Router) {   }

  public isShowFoot:String="display="

  private data:any;//存放Excel中的数据

  public patientInfoList:any;
  
  public totalNum = 0;  //总数据条数
  public pageSize = 10; //每页数条数
  public pageData = this.pageSize;  //每页数据
  public totalPage = 0;//总页数
  public curPage = 1;//当前页码

  public inputPageNo=1;

  public page:page =new page();
  public arr = Array;
 
  public count:any=0;

  public patientInfoSearch:patientInfo=new patientInfo();
    
  getPageData(pageNo:any) {

    let self = this;

    self.curPage = pageNo;

    //this.pageSize = this.pageData * pageNo;

    this.searchPatientInfoPage();
  }

  ngOnInit(): void {

  }

  searchPatientInfo(patientInfoSearch:patientInfo) {



    this.adminService.searchPatientInfo(patientInfoSearch,'123').toPromise().then(dataJson=>{
    

      const response = JSON.parse(JSON.stringify((<any>dataJson) ))

      this.count=response.data


      
      if(this.count!=0){
        
        this.totalNum=this.count

        this.totalPage=Math.floor((this.totalNum - 1) / this.pageData) + 1;

        this.searchPatientInfoPage();

      }

    
    }).catch(err =>{
     

    })


  }


  searchPatientInfoPage() {

    this.page.data= this.patientInfoSearch;

    this.page.pageSize=this.pageSize

    this.page.curPage=this.curPage

    this.adminService.searchPatientInfoPage(this.page,'123').toPromise().then(dataJson=>{
    

      const response = JSON.parse(JSON.stringify((<any>dataJson) ))

      this.patientInfoList = response.data;
      
    }).catch(err =>{
     

    })


  }




doSubmit(){

this.searchPatientInfo(this.patientInfoSearch);


}

find(patientInfoId:string){

  let NavigationExtras:NavigationExtras={
    queryParams:{'patientInfoId':patientInfoId},

    
    }

  this.router.navigate(['findDetail'],NavigationExtras); 
}



}
