
import { Component, OnInit} from '@angular/core';
import { AlertController } from '@ionic/angular';
import { SessionService } from '../../../services/session.service';
import { Router } from '@angular/router';
@Component({
  selector: 'popup-common',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})

export class IndexComponent implements OnInit {


  constructor(
    private alertCtrl: AlertController,
    private sessionService: SessionService,
    private router:Router

  ) { }
  public name:any="登录/注册";

  public user:any;
  ngOnInit(): void {

    // this.user=this.sessionService.getSession("user");


    // if(this.user){
    //   this.name= "你好: "+this.user.name;
    //  }

    

  }
  login(){

  //2023年1月22日注释---------------------
    // if(!this.user){
    //   this.router.navigate(['/login']); 
    //   return;
    //  }
 //2023年1月22日注释---------------------
  }


  async presentConfirm() {//普通弹框

    const  alert = await this.alertCtrl.create({
      header: '是否退出登录',
      message: '请确认是否要继续？',
      buttons: [
        {
          text: '否',
          role: 'cancel',
          handler: () => {
            console.log('取消继续');
          }
        },
        {
          text: '是',
          handler: () => {
            this.sessionService.remove("user");
            this.router.navigate(['/index']); 
            this.name="登录/注册";
            this.user=null;
          }
        }
      ]
    });

    await alert.present();

  }

}
