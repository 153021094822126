<div class="head">


    分析详情

    </div>
    <div>
        <a routerLink="/index" routerLinkActive='active' class='backNav'>返回导航</a>
    
     </div>
    <div class="zhuce">
        <div class="text">
            <span>初次发现息肉大小</span>
            <input type="text"    class="input"  [(ngModel)]="newPolypInformation.polypFirst" disabled/>
        </div>
        
        <div class="text">
            <span>目前息肉大小(来源超声)<small>*</small></span>
            <input type="text"      class="input" [(ngModel)]="newPolypInformation.polypNow"   disabled/>
        </div>
        <div class="text">
            <span>是否明确息肉为假性息肉 </span>
            <select class="select" [(ngModel)]="newPolypInformation.isFakePolypFlg" disabled>
                <option value="">请选择</option>
                <option value="1">是</option>
                <option value="2">否</option>
                </select>   
            <span>伴随症状 </span>
            <select class="select" [(ngModel)]="newPolypInformation.concomitantSymptoms" disabled>
                <option value="">请选择</option>
                <option value="1">慧尾征</option>
                <option value="2">回声</option>
                <option value="3">以上都有</option>
                </select>       
        </div>
        <div class="text">
            <span>息肉数目   </span>

            <select class="select" [(ngModel)]="newPolypInformation.polypCount" disabled>
                <option value="">请选择</option>
                <option value="1">单发</option>
                <option value="2">多发</option>
                </select>  
        </div>
        <div class="text">
            <span>位置</span>
            <select class="select" [(ngModel)]="newPolypInformation.position" disabled>
                <option value="">请选择</option>
                <option value="1">胆囊底</option>
                <option value="2">胆囊体</option>
                <option value="3">胆囊颈</option>
                <option value="4">不详</option>
                </select> 
        </div>

        <div class="text">
            <span>附着面 </span>
            <select class="select" [(ngModel)]="newPolypInformation.attachmentSurface" disabled>
            <option value="">请选择</option>
            <option value="1">肝脏面</option>
            <option value="2">游离面</option>
            <option value="3">不详</option>
            </select> 
        </div>
        <div class="text">
            <span>基底部<small>*</small> </span>
            <select class="select" [(ngModel)]="newPolypInformation.basalPart" disabled>
                <option value="">请选择</option>
                <option value="1">无蒂息肉（包括局灶性胆囊壁增厚＞4mm）</option>
                <option value="2">有蒂息肉</option>
                <option value="3">不详</option>
                </select> 

        </div>
        <div class="text">
            <span>胆囊壁</span>


            <select class="select" [(ngModel)]="newPolypInformation.gallbladderWall" disabled>
                <option value="">请选择</option>
                <option value="1">光滑</option>
                <option value="2">毛糙</option>
                <option value="2">以上两者都有</option>
                </select>  
        </div>
        <div class="text">
            <span>是否合并胆囊结石<small>*</small></span>
            <select class="select" [(ngModel)]="newPolypInformation.gallstone" disabled>
                <option value="">请选择</option>
                <option value="1">是</option>
                <option value="2">否</option>
                </select>  
            <span>胆囊结石(最大径)(mm)</span>
            <input type="text" class="input"  [(ngModel)]="newPolypInformation.size" disabled/>
            <span>发现胆囊结石时间</span>
            <input type="date"  class="input" [(ngModel)]="newPolypInformation.gallstoneTime"  disabled>
        </div>


        <div class="text">
            <span>合并原发性硬化性胆管炎<small>*</small></span>
            <select class="select" [(ngModel)]="newPolypInformation.primarySclerosingCholangitis" disabled>
                <option value="">请选择</option>
                <option value="1">是</option>
                <option value="2">否</option>
                </select>  
        </div>
        <div class="text">
            <span>是否有過急性胆囊炎<small>*</small></span>
            <select class="select" [(ngModel)]="newPolypInformation.isAcuteCholecystitis" disabled>
                <option value="">请选择</option>
                <option value="1">是</option>
                <option value="2">否</option>
                </select>  
        </div>

        <div class="text">
            <span>目前合并黄疸 </span>
            <select class="select" [(ngModel)]="newPolypInformation.isJaundice" disabled>
                <option value="">请选择</option>
                <option value="1">是</option>
                <option value="2">否</option>
                </select>  
        </div>

        <div class="text">
            <span>是否合并脂肪肝</span>
            <select class="select" [(ngModel)]="newPolypInformation.isFattyIiver" disabled>
                <option value="">请选择</option>
                <option value="1">是</option>
                <option value="2">否</option>
                </select>  
        </div> 

        <div class="text">
            <span>是否合并胆道结石</span>
            <select class="select" [(ngModel)]="newPolypInformation.isCholelithiasis" disabled>
                <option value="">请选择</option>
                <option value="1">是</option>
                <option value="2">否</option>
                </select>  
        </div> 
        <div class="text">
            <span>是否合并胰腺异常    </span>
            <select class="select" [(ngModel)]="newPolypInformation.isPancreaticAbnormalities" disabled>
                <option value="">请选择</option>
                <option value="1">是</option>
                <option value="2">否</option>
                </select>  
        </div> 
        <div class="text">            
            <span>血脂异常</span>
            <select class="select" [(ngModel)]="newLaboratoryExamination.dyslipidemia" disabled>
                <option value="">请选择</option>
                <option value="1">是</option>
                <option value="2">否</option>
            </select> 
            <div id="dyslipidemiaDiv">
            <span>总胆固醇(CHOL)</span>
            <input type="text"  class="input" [(ngModel)]="newLaboratoryExamination.totalCholesterol" disabled/>
   
            <span>甘油三脂(TG) </span>
            <input type="text"    class="input" [(ngModel)]="newLaboratoryExamination.triglyceride" disabled/>
   
            <span>高密度脂蛋白胆固醇(HDL)  </span>
            <input type="text"     class="input" [(ngModel)]="newLaboratoryExamination.highDensityLipoprotein" disabled/>

            <span>低密度脂蛋白胆固醇(LDL) </span>
            <input type="text"     class="input" [(ngModel)]="newLaboratoryExamination.lowDensityLipoprotein" disabled/>
     
            <span>非高密度脂蛋白胆固醇(NHDL)  </span>
            <input type="text"     class="input" [(ngModel)]="newLaboratoryExamination.nonHighDensityLipoprotein" disabled/>

        </div>
        
    </div>
    <div class="text">            
        <span>总胆红素异常<small>*</small></span>
        <select class="select" [(ngModel)]="newLaboratoryExamination.abnormalTotalBilirubin" disabled>
            <option value="">请选择</option>
            <option value="1">是</option>
            <option value="2">否</option>
        </select> 
    </div>

    <div class="text">
        <span>肿瘤标记物指标</span>

        <span>糖类抗原CA19-9</span>
        <input type="text"   class="input"   [(ngModel)]="newLaboratoryExamination.ca199"  disabled/>

        <span>糖类抗原CA-125</span>
        <input type="text"    class="input"   [(ngModel)]="newLaboratoryExamination.ca125"  disabled/>

        <span>CA153</span>
        <input type="text"    class="input"   [(ngModel)]="newLaboratoryExamination.ca153"  disabled/>

        <span>糖类抗原CA72-4</span>
        <input type="text"    class="input"  [(ngModel)]="newLaboratoryExamination.ca724"  disabled/>

        <span>糖类抗原CA50</span>
        <input type="text"   class="input"  [(ngModel)]="newLaboratoryExamination.ca50"  disabled/>

        <span>肿瘤相关抗原CA242</span>
        <input type="text"    class="input"   [(ngModel)]="newLaboratoryExamination.c242"  disabled/>

        <span>AFP</span>
        <input type="text"    class="input"   [(ngModel)]="newLaboratoryExamination.afp"  disabled/>

        <span>CEA</span>
        <input type="text"    class="input"  [(ngModel)]="newLaboratoryExamination.cea"  disabled/>
    </div>
    <div class="text1">
        <div class="btndl"><input type="submit" value="結 果" (click)="next()">  <input type="submit" value="返 回" (click)="back()" ></div>
    </div>
    </div>