import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { patientInfo } from '../../stage/patientInfo';
import {Router,ActivatedRoute } from '@angular/router';
//npm install xlsx
 
//npm install @types/xlsx
@Component({
  selector: 'app-admin-FindpatientDetailInfo',
  templateUrl: './findPatientDetailInfo.component.html',
  styleUrls: ['./findPatientDetailInfo.component.scss']
})
export class FindPatientDetailInfoComponent implements OnInit {

  constructor(private adminService: AdminService,public activatedRoute:ActivatedRoute,public router:Router) { 



  }

  public patientInfo:patientInfo =new patientInfo() ;

  
    
 

  ngOnInit(): void {

  

    this.activatedRoute.queryParams.subscribe((data)=>{

      this.getPatientInfo(data.patientInfoId)

      
    })



  }

  getPatientInfo(patientInfoId:String) {
    this.adminService.getPatientInfo(patientInfoId,'123').toPromise().then(dataJson=>{
    

      const response = JSON.parse(JSON.stringify((<any>dataJson) ))
      this.patientInfo=response.data
    
    
    }).catch(err =>{
     

    })


  }

  back(){ history.go(-1);}

}
