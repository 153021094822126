//病理检查
export class pathologicalExamination{
	//病理号
	pathologyNumber:string='';

	//临床诊断
	clinicalDiagnosis:string='';

	//术中冰冻
	intraoperativeFreezing:string='';

	//病理诊断
	pathologicDiagnosis:string='';

	//特殊检查
	specialInspection:string='';

	//肉眼所见
	visibleToTheNakedEye:string='';
};