import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../customer/services/session.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-translate',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.scss']
})
export class TranslateComponent implements OnInit {

  constructor(private router:Router,private sessionService:SessionService) { }
public ishow:Boolean=false;

  ngOnInit(




  ): void {
    var asideDom:any=document.getElementById('aside');
    asideDom.style.display="none";

  }
  showAside(){

    //原生js获取dom节点
    var asideDom:any=document.getElementById('aside');
    if(!this.ishow){

      this.ishow=true;
      asideDom.style.transform="translate(0,0)";
      asideDom.style.display="";
    }else{


      asideDom.style.transform="translate(100%,0)";
      asideDom.style.display="none";
      this.ishow=false;
    }






 }

 closeAside(){
  var asideDom:any=document.getElementById('aside');
  asideDom.style.transform="translate(100%,0)";
  asideDom.style.display="none";
  this.ishow=false;


 }


 closeLogin(){

  const user=this.sessionService.getSession("user");

  if(!user){
    this.sessionService.remove("user");
    this.router.navigate(['/login']); 
    return;
   }

      
}

}
