export class fullSetOfInfectiousDiseases{
//乙肝表面抗原(YP)
hepatitisBSurfaceAntigen_YP:string='';
//乙肝表面抗体(YP)
 hepatitisBSurfaceAntibody_YP:string='';
//HIV Ag/Ab
 hiv_Ag_Ab:string='';
//丙肝抗体
 hepatitisCAntibody:string='';
//乙肝核心抗体(YP)
 hepatitisBCoreAntibody_YP:string='';
//乙肝e抗原(YP)
 hepatitisBEAntigen_YP:string='';
//乙肝e抗体(YP)
 hepatitisBEAntibody_YP:string='';
};