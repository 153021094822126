
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';


@Injectable()
export class   AlterSerProvider {
  constructor(
    private alertController: AlertController,
  ) {

  }

  async ErropresentConfirm(header:string,message:string,text:string) {//普通弹框

    const  alert = await this.alertController.create({
      header: header,
      message: message,
      cssClass:"alterScss",
      buttons: [
        {
          text: text,
          role: 'cancel'
          
        }
      ]
    });

    await alert.present();

  }
}
