<div class="head">
  <span class="head_lf"></span>
  
  App免责声明
  
  <span class="head_rg"></span>
  </div>

<div class="w">
  <div class="border">
      <div class="borderl">
          <a href="#">胆囊疾病养护小贴士APP&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
      </div>
      <div class="borderr">
          <div class="top1">
              <p></p>

          </div>
          <div class="bottom1">
              <div class="title-1">总则</div>
              <div class="icon-detitle"></div>
              <P class="D3">
                  <strong>一切移动客户端用户在下载并浏览xxxAPP软件时均被视为已经仔细阅读本条款并完全同意。凡以任何方式使用本APP，或直接、间接使用本APP资料者，均被视为自愿接受本网页相关声明和用户服务协议的约束。</strong>
              </P>
              <p class="D4">
                  <strong>胆囊疾病养护小贴士上传的内容并不代表(胆囊疾病养护小贴士)APP之意见及观点，也不意味着本网页同其观点或证实其内容的真实性。</strong>
              </p>
              <p class="D5">
                <strong>胆囊疾病养护小贴士APP上传的文字、图片、音视频等资料均由本APP用户提供，其真实性、准确性和合法性由信息发布人负责。胆囊疾病养护小贴士APP不提供任何保证，并不承担任何法律责任。</strong>
              </p>
              <p class="D5">
                <strong>胆囊疾病养护小贴士APP不保证为向用户提供便利而设置的外部链接的准确性和完整性，同时，对于该外部链接指向的不由胆囊疾病养护小贴士APP实际控制的任何网页上的内容，胆囊疾病养护小贴士APP不承担任何责任。</strong>
              </p>
              <p class="D5">
                <strong>用户明确并同意其使用胆囊疾病养护小贴士APP网络服务所存在的风险将完全由其本人承担;因其使用胆囊疾病养护小贴士APP网络服务而产生的一切后果也由其本人承担，胆囊疾病养护小贴士APP对此不承担任何责任。</strong>
              </p>
              <p class="D5">
                <strong>除胆囊疾病养护小贴士APP注明之服务条款外，其它因不当使用本APP而导致的任何意外、疏忽、合约毁坏、诽谤、版权或其他知识产权侵犯及其所造成的任何损失，胆囊疾病养护小贴士APP概不负责，亦不承担任何法律责任。</strong>
              </p>
              <p class="D5">
                <strong>对于因不可抗力或因黑客攻击、通讯线路中断等胆囊疾病养护小贴士APP不能控制的原因造成的网络服务中断或其他缺陷，导致用户不能正常使用胆囊疾病养护小贴士APP，胆囊疾病养护小贴士APP不承担任何责任，但将尽力减少因此给用户造成的损失或影响。</strong>
              </p>
              <p class="D5">
                <strong>本声明未涉及的问题请参见国家有关法律法规，当本声明与国家有关法律法规冲突时，以国家法律法规为准。</strong>
              </p>
              <p class="D5">
                <strong>本网页相关声明版权及其修改权、更新权和最终解释权均属胆囊疾病养护小贴士APP所有。</strong>
              </p>
          </div>
      </div>
      <div class="next"><input id="okbutton" type="submit"  (click)="ok()" value="{{paracont}}"></div>
  </div>

</div>