 <div class="head">

    个人身体情况
    
    </div>
    <div>
        <a routerLink="/index" routerLinkActive='active' class='backNav'>返回导航</a>
    
     </div>
    <div class="zhuce">
        <div class="text">
            <span>吸烟史</span>
            <select  class="input"  [(ngModel)]="newbodyInfo.smokingHistoryFlg" (change)="smokingHistoryFlgSelect()">
                <option value ="">请输入</option>
                <option value ="1">有</option>
                <option value ="0">无</option>
              </select>
              <div id="smokeDiv">
            <span class="typespan"><input type="checkbox" [(ngModel)]="newbodyInfo.smokingTypeCheckFlg"/>电子烟</span>
            <span class="typespan">每天几支</span>
            <input type="text"    class="input" placeholder="请输入每天几支" [(ngModel)]="newbodyInfo.smokeCount" maxlength="2"/>
            <span class="typespan">持续多少年</span>
            <input type="text"     class="input" placeholder="请输入持续多少年" [(ngModel)]="newbodyInfo.smokingHistoryYear" maxlength="2"/>       
        </div>

    </div>
        <div class="text">
        <span>饮酒史</span>
        <select  class="input" [(ngModel)]="newbodyInfo.wineHistoryFlg" (change)="wineHistoryFlgSelect()">
            <option value ="">请输入</option>
            <option value ="1">有</option>
            <option value ="0">无</option>
          </select>
          <div id="wineDiv">
          <span>饮酒(偏好)</span>
         <select class="input" [(ngModel)]="newbodyInfo.wineLikeFlg">
              <option value="">请输入</option>
                <option value="1">黄酒</option>
                <option value="2">啤酒</option>
                <option value="3">两者都有</option>
         </select>  
        <span>每天几两</span>
            <input type="text"   class="input" placeholder="请输入每天几两" [(ngModel)]="newbodyInfo.wineCount" maxlength="2"/>
            <span>持续多少年</span>
            <input type="text"     class="input" placeholder="请输入持续多少年" [(ngModel)]="newbodyInfo.wineHistoryYear" maxlength="2"/>    
        </div>
        
    </div>
        <div class="text">
            <span>糖尿病</span>
            <select  class="input" [(ngModel)]="newbodyInfo.diabetesMellitusFlg" (change)="diabetesMellitusFlgSelect()">
                <option value ="">请输入</option>
                <option value="0">无</option>
                <option value="1">I型</option>
                <option value="2">II型</option>
              </select>
              <div id="bloodSugarDiv">
              <span>控制情况：空腹血糖</span>
              <input type="text"    class="input" placeholder="请输入空腹血糖"   id = "bloodSugarBeforeInput" [(ngModel)]="newbodyInfo.bloodSugarBefore" maxlength="5"/>
              <span>控制情况：餐后血糖</span>
              <input type="text"    class="input" placeholder="请输入餐后血糖"  id = "bloodSugarAfterInput" [(ngModel)]="newbodyInfo.bloodSugarAfter"  maxlength="5"/>
              <span>服药(主要药物)(选填)：</span>
              <input type="text"    class="input" placeholder="请输入药名"      id = "takingMedicineInput" [(ngModel)]="newbodyInfo.takingMedicine" maxlength="100"/>
            </div>
        </div>
        

        <div class="text">
            <span>脂肪肝</span>
            <select  class="input" [(ngModel)]="newbodyInfo.fattyLiverFlg" >
                <option value ="">请输入</option>
                <option value ="1">有</option>
                <option value ="0">无</option>
              </select>
            <span>肝硬化</span>
            <select  class="input" [(ngModel)]="newbodyInfo.cirrhosisFlg" >
                <option value ="">请输入</option>
                <option value ="1">有</option>
                <option value ="0">无</option>
            </select>
            
        </div>


        <div class="text">
            <span>高脂血症</span>
            <select  class="input" [(ngModel)]="newbodyInfo.hyperlipidemiaFlg" >
                <option value ="">请输入</option>
                <option value ="1">有</option>
                <option value ="0">无</option>
              </select>            
        </div>

        <div class="text">
            <span>胰腺炎</span>
            <select  class="input" [(ngModel)]="newbodyInfo.pancreatitisFlg" >
                <option value ="">请输入</option>
                <option value ="1">有</option>
                <option value ="0">无</option>
            </select>
        </div>

        <div class="text">
            <span>胃炎</span>
            <select  class="input" [(ngModel)]="newbodyInfo.gastritisFlg" >
                <option value ="">请输入</option>
                <option value ="1">有</option>
                <option value ="0">无</option>
              </select>
        </div>

        <div class="text">
            <span>胃溃疡</span>
            <select  class="input" [(ngModel)]="newbodyInfo.gastricUlcerFlg" >
                <option value ="">请输入</option>
                <option value ="1">有</option>
                <option value ="0">无</option>
            </select>
        </div>


        <div class="text">
            <span>心脑血管疾病</span>
            <select  class="input"  [(ngModel)]="newbodyInfo.cerebrovascularDiseaCsesFlg" (change)="cerebrovascularDiseaCsesFlgSelect()">
                <option value="">请选择</option>
                <option value="0">无</option>
                <option value="1">心绞痛</option>
                <option value="2">脑卒中</option>
              </select>
              <div id="cerebrovascularDiseaCsesDiv">
              <span><input type="checkbox"  [(ngModel)]="newbodyInfo.cerebrovascularDiseaCsesOtherCheckFlg" (change)="cerebrovascularDiseaCsesOtherCheckFlg()"/>其它心血管疾病</span>
              <input type="text"    class="input" placeholder="请输入其它心血管疾病"  id = "cerebrovascularDiseaCsesOtherInput"  [(ngModel)]="newbodyInfo.cerebrovascularDiseaCsesOther" maxlength="100"/>
            </div>
        </div>

        <div class="text">
            <span>肝炎</span>
            <select  class="input"  [(ngModel)]="newbodyInfo.hepatitisFlg" >
                <option value="">请选择</option>
                <option value ="0">无</option>
                <option value="1">甲</option>
                <option value="2">乙</option>
                <option value="3">丙</option>
                <option value="4">戊 </option>
                <option value="5">未治疗</option>
                <option value="6">经治疗</option>
              </select>
        </div>

        <div class="text">
            <span>其他部位息肉</span>
            <select  class="input" [(ngModel)]="newbodyInfo.polypsInOtherParts">
                <option value ="">请输入</option>
                <option value ="0">无</option>
                <option value ="1">胃息肉</option>
                <option value ="2">腸息肉</option>
                <option value ="3">其他</option>
              </select>
        </div>


        <div class="text">
            <span>肿瘤性疾病</span>
            <input type="text"    class="input" placeholder="请输入肿瘤性疾病"  id = "neoplasticDiseasesInput" [(ngModel)]="newbodyInfo.neoplasticDiseases" maxlength="100"/>
        </div>
        
        <div class="text">
            <span>其他自身免疫疾病</span>
            <select  class="input" [(ngModel)]="newbodyInfo.otherAutoimmuneDiseases">
                <option value ="">请输入</option>
                <option value ="0">无</option>
                <option value ="1">有</option>

              </select>
        </div>


        <div class="text">
            <span>是否有全麻手术史</span>
            <select  class="input" [(ngModel)]="newbodyInfo.isGeneralAnesthesiaFlg"> 
                <option value ="">请输入</option>
                <option value ="0">无</option>
                <option value ="1">有</option>
              </select>
        </div>
        <div class="text1">
            <div class="btndl"><input type="submit" value="保 存"  (click)="doSubmit()" ></div>
        </div>
    </div>