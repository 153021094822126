import { Injectable } from '@angular/core';
//npm i crypto-js -S 
//npm i lodash -S 
import { AES, enc } from 'crypto-js';

@Injectable({
    providedIn: 'root'
  })
export class SessionService {

    private json: Object = {};
    private tobeLocalSavedJson: Object = {};
    private tobeSessionSavedJson: Object = {};
    private timeoutId: any;

    
    
    
    
    
        /**
     * 得到session的值
     * @param {string} key
     * @returns {any}
     */
    public getSession(key: string,isPersistence?: boolean): any {

        isPersistence = isPersistence || false;
        let Storage=null;
        let valueStr = null;
            try {

                if (isPersistence) { 
                    valueStr = localStorage.getItem(key);
        
                } else {
                    valueStr = sessionStorage.getItem(key)
                }

                if (valueStr) {
                    Storage = JSON.parse(AES.decrypt(valueStr, key).toString(enc.Utf8));   
                }
            } catch (e) {
                console.error(`localStorage access denied!`);
                return null;
            }
            return Storage;
      
    }

    /**
     * 添加session
     *
     * @param {string} key
     * @param value
     * @param {boolean} isPersistence
     */
    public putSession(key: string, value: any, isPersistence?: boolean): void {
        isPersistence = isPersistence || false;
        // if (isNull(value) || isUndefined(value) || isNaN(value)) {
        //     this.remove(key);
        //     return;
        // }
       let encryptValue:any=AES.encrypt(JSON.stringify(value), key);

       

        if (isPersistence) { 
            localStorage.setItem(key,  encryptValue)

        } else {
            sessionStorage.setItem(key, encryptValue)
        }

        try {
            this._delaySave();
        } catch (e) {
            console.error(`localStorage access denied!`);
        }
    }
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    /**
     * 得到session的值
     * @param {string} key
     * @returns {any}
     */
    // public get(key: string): any {
    //     console.debug('begin get(),key is %s', key);
    //     if (has(this.json, key)) {
    //         try {
    //             let valueStr = sessionStorage.getItem(key) || localStorage.getItem(key);
    //             if (valueStr) {
    //                 this.json[key] = JSON.parse(AES.decrypt(valueStr, key)
    //                     .toString(enc.Utf8));
    //             }
    //         } catch (e) {
    //             console.error(`localStorage access denied!`);
    //             return null;
    //         }
    //         return this.json[key];
    //     }
    // }

    /**
     * 添加session
     *
     * @param {string} key
     * @param value
     * @param {boolean} isPersistence
     */
    // public put(key: string, value: any, isPersistence?: boolean): void {
    //     isPersistence = isPersistence || false;
    //     if (isNull(value) || isUndefined(value) || isNaN(value)) {
    //         this.remove(key);
    //         return;
    //     }
    //     this.json[key] = value;
    //     if (isPersistence) {
    //         this.tobeLocalSavedJson[key] = value;
    //     } else {
    //         this.tobeSessionSavedJson[key] = value;
    //     }
    //     try {
    //         this._delaySave();
    //     } catch (e) {
    //         console.error(`localStorage access denied!`);
    //     }
    // }

    /**
     * 清除所有session
     * @param {boolean} isAlsoClearPersistent
     */
    // public clear(isAlsoClearPersistent?: boolean) {
    //     this.json = {};
    //     try {
    //         sessionStorage.clear();
    //     } catch (e) {
    //         console.error(`localStorage access denied!`);
    //     }
    //     if (isAlsoClearPersistent) {
    //         try {
    //             localStorage.clear();
    //         } catch (e) {
    //             console.error(`localStorage access denied!`);
    //         }
    //     }
    //     this.tobeLocalSavedJson = {};
    //     this.tobeSessionSavedJson = {};
    // }

    /**
     * 移除对应key的session
     * @param {string} key
     */
     public remove(key: string) {
        //  delete this.json[key];
        //  delete this.tobeSessionSavedJson[key];
        //  delete  this.tobeLocalSavedJson[key];

         try {
             sessionStorage.removeItem(key);
             localStorage.removeItem(key);
         } catch (e) {
             console.error(`localStorage access denied!`);
         }
     }

    private _delaySave(): void {
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
            try {
                // this.saveToStorge(this.tobeLocalSavedJson, localStorage);
                // this.saveToStorge(this.tobeSessionSavedJson, sessionStorage);
                // this.tobeLocalSavedJson = {};
                // this.tobeSessionSavedJson = {};
            } catch (e) {
                console.error(`localStorage access denied!`);
            }
        }, 100);
    }

    // private saveToStorge(json, storge) {
    //     each(json, (value: any, key: any) => {
    //         let encryptValue = AES.encrypt(JSON.stringify(value), key);
    //         try {
    //             storge.setItem(key, encryptValue);
    //         } catch (e) {
    //             console.error(`localStorage access denied!`);
    //         }
    //     });

    // }
}