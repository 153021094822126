//凝血常规
export class coagulationRoutine{
	//国际标准化比率
	internationalStandardizationRatio:string='';
	
	//凝血酶原时间
	prothrombinTime:string='';
	
	//凝血酶时间
	ThrombinTime:string='';
	
	//纤维蛋白原
	fibrinogen:string='';
	
	//部分凝血活酶时间
	partialThromboplastinTime:string='';
};