//肝肾功能及血脂全套
export class liverAndKidneyFunction{
//血清游离脂肪酸(NEFA)
serumFreeFattyAcids_NEFA:string='';
//小而密低密度脂蛋白(sd-LDL)
smallDenseLowDensityLipoprotein_SD_LDL:string='';
//甘油三脂(TG)
triglyceride_TG:string='';
//载脂蛋白B(APOB)
apolipoproteinB_apoB:string='';
//胱抑素C(CysC)
cystatinC_CysC:string='';
//甘胆酸(CG)
glycocholicAcid_CG:string='';
//载脂蛋白A1(APOA)
ApolipoproteinA1_ApoA:string='';
//高密度脂蛋白胆固醇(HDL)
highDensityLipoproteinCholesterol_HDL:string='';
//白球比例(A:G)
whiteballRatio_A_G:string='';
//低密度脂蛋白胆固醇(LDL)
lowDensityLipoproteinCholesterol_LDL:string='';
//氯(CL)
chlorine_CL:string='';
//天门冬氨酸氨基转移酶(AST)
aspartateAminotransferase_AST:string='';
//eGFR-MDRD(eGFR(MDRD))
eGFR_MDRD_eGFR_MDRD:string='';
//eGFR-EPI Cr
eGFR_EPI_Cr:string='';
//丙氨酸氨基转移酶(ALT)
alanineAminotransferase_ALT:string='';
//钠(NA)
sodium_NA:string='';
//岩藻糖苷酶(AFU)
fucosidase_AFU:string='';
//乳酸脱氢酶(LDH)
lactateDehydrogenase_LDH:string='';
//脂蛋白(a)(LPa)
lipoprotein_a_LPA:string='';
//非高密度脂蛋白胆固醇(NHDL)
nonHighDensityLipoproteinCholesterol_nhdl:string='';
//直接胆红素(DBIL)
directBilirubin_DBIL:string='';
//谷氨酸脱氢酶(GLDH)
glutamateDehydrogenase_GLDH:string='';
//尿酸(URIC)
uricAcid_uric:string='';
//前白蛋白(PA)
prealbumin_PA:string='';
//球蛋白(GLB)
globulin_GLB:string='';
//载脂蛋白E(APOE)
apolipoproteinE_ApoE:string='';
//总胆固醇(CHOL)
totalCholesterol_Chol:string='';
//尿素氮(BUN)
ureaNitrogen_BUN:string='';
//纤维连接蛋白(FN)
Fibronectin_FN:string='';
//谷氨酰转肽酶(GGT)
glutamyl_transpeptidase_GGT:string='';
//钾(K)
potassium_k:string='';
//白蛋白(ALB)
albumin_ALB:string='';
//总胆红素(TBIL)
totalBilirubin_TBIL:string='';
//空腹血糖(GLU)
fastingBloodGlucose_Glu:string='';
//总胆汁酸(TBA)
totalBileAcid_TBA:string='';
//碱性磷酸酶(ALP)
alkalinePhosphatase_ALP:string='';
//肌酐(CREA)
creatinineCrea:string='';
//总蛋白(TP)
totalProtein_TP:string='';
};