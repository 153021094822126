import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {HttpClient,HttpHeaders} from '@angular/common/http'
import { Observable } from 'rxjs';
declare var wx: any;

@Injectable({
  providedIn: 'root'
})
export class WxService {

  constructor(public httpClient:HttpClient) { }
  public apiUrl:String = environment.apiUrl
  
  
  getWXConfigSignature(): Observable<{}>{

    const url:String=window.location.href.split('#')[0]
    const httpOptions ={headers: new HttpHeaders({'Content-Type':'application/json'}) } 
    return this.httpClient.post(this.apiUrl+"getWXConfigSignature",url,httpOptions)

  }

  public getSign(data:any): Observable<{}> {

    // 将 wx.config 转为 Observable对象
      // 获取签名数据
      return new Observable(sign => { 
        wx.config({
          debug: true,
          appId: data.appid,
          timestamp: data.timestamp,
          nonceStr: data.nonceStr,
          signature: data.signature,
          jsApiList: [
            'onMenuShareTimeline',
            'onMenuShareAppMessage'
          ]
        })

        wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
          wx.onMenuShareTimeline({
              title: '【乐淘精品】发红包啦', // 分享标题
              // desc: 'test列表', // 分享描述
              link: 'https//www.baidu.com', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: '', // 分享图标
          }, function (res:any) {
              console.info("success")
          });
          

          // wx.onMenuShareAppMessage({
          //     title: '【乐淘精品】', // 分享标题
          //     link: '【乐淘精品】发红包啦,小伙伴们赶快来', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          //     imgUrl: 'http://www.innshine.com/wechat/images/down.jpg', // 分享图标
          // }, function (res:any) {
          //     console.info("success")
          // });
      });

      wx.error(function (res:any) {
        //打印错误消息。及把 debug:false,设置为debug:ture就可以直接在网页上看到弹出的错误提示
        alert("错误error" + JSON.stringify(res));
    });
     
      })
  }

public as(){

  
}

}
