//胆汁酸全套
export class completeSetOfBileAcids{
//石胆酸
lithocholicAcid:string='';
//牛磺石胆酸
taurocholicAcid1:string='';
//甘氨石胆酸
glycineCholicAcid:string='';
//熊脱氧胆酸
ursodeoxycholicAcid:string='';
//胆酸
cholicAcid:string='';
//谷氨酸
glutamate:string='';
//鹅脱氧胆酸
chenodeoxycholicAcid:string='';
//鸟氨酸
ornithine:string='';
//牛磺鹅脱氧胆酸
taurineChenodeoxycholicAcid:string='';
//甘氨鹅脱氧胆酸
glycineChenodeoxycholicAcid:string='';
//脱氧胆酸
deoxycholicAcid:string='';
//亮氨酸
leucine:string='';
//脯氨酸
proline:string='';
//缬氨酸
valine:string='';
//甘氨脱氧胆酸
glycodeoxycholicAcid:string='';
//甘氨熊脱氧胆酸
glycoursodeoxycholicAcid:string='';
//牛磺脱氧胆酸
taurodeoxycholicAcid:string='';
//丙氨酸
alanine:string='';
//瓜氨酸
citrulline:string='';
//甘氨酸
glycine:string='';
//牛磺胆酸
taurocholicAcid2:string='';
//甘氨胆酸
glycocholicAcid:string='';
//牛磺熊脱氧胆酸
taurineUrsodeoxycholicAcid:string='';
//酪氨酸
tyrosine:string='';
//同型半胱氨酸(质谱)
homocysteine_massSpectrometry:string='';
//精氨酸
arginine:string='';
};