//淋巴细胞技术
export class lymphocyteTechnique{

//淋巴细胞绝对值
lymphocyteAbsoluteValue:string='';
//CD4/CD8比值
cd4AndCd8Ratio:string='';
//T淋巴细胞绝对值
tLymphocyteAbsoluteValue:string='';
//B淋巴细胞绝对值
absoluteValueOfBLymphocytes:string='';
//自然杀伤细胞CD56+CD16
naturalKillerCell_CD56_CD16:string='';
//Ts淋巴细胞  CD8
tSLymphocyte_CD8:string='';
//自然杀伤细胞绝对值
absoluteValueOfNaturalKillerCells:string='';
//Ts淋巴细胞绝对值
tSLymphocyteAbsoluteValue:string='';
//Th淋巴细胞CD4
thLymphocyte_CD4:string='';
//T淋巴细胞CD3
tLymphocyte_CD3:string='';
//Th淋巴细胞绝对值
absoluteValueOfThLymphocyte:string='';
//B淋巴细胞CD19
bLymphocyte_CD19:string='';
};