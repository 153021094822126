import { lymphocyteTechnique } from './lymphocyteTechnique';
import { liverAndKidneyFunction } from './liverAndKidneyFunction';
import { pathologicalExamination } from './pathologicalExamination';
import { fullSetOfInfectiousDiseases } from './fullSetOfInfectiousDiseases';
import { routineBloodTest } from './routineBloodTest';
import { completeSetOfBileAcids } from './completeSetOfBileAcids';
import { bileAcid } from './bileAcid';
import { coagulationRoutine } from './coagulationRoutine';;
import { laboratoryExamination } from '../../customer/stage/laboratoryExamination';
import { polypInformation } from '../../customer/stage/polypInformation';
export class patientInfo{

	//日期
	recordDate:string='';
	


	 //入院日期
	dateOfAdmission:string='';

	//住院号
	inpatientNumber:string='';


	//姓名
	name :string='';
	//性别
    sexFlg:string='';
	//年齡
    age:string='';

	//联系方式
	contact:string='';
	//身份证
    idNumber:string='';

	//身高
    height:string='';
	//体重
	weight:string='';


	//病理检查
	pathologicalExamination :pathologicalExamination=new pathologicalExamination;
		

	//高血压
	hypertensionFlg='';
	//糖尿病
	diabetesMellitusFlg='';
	//高血脂
	hyperlipemiaFlg='';
	//慢病
	chronicDisease='';

	// 临床诊断
	clinicalDiagnosis:string='';

	//胆汁酸
    bileAcid:bileAcid=new bileAcid;

	//凝血常规
	coagulationRoutine:coagulationRoutine=new coagulationRoutine;

	//血常规
	routineBloodTest:routineBloodTest=new routineBloodTest;
	
	//肝肾功能及血脂全套
	liverAndKidneyFunction:liverAndKidneyFunction=new liverAndKidneyFunction;

	//肿瘤标记物
	laboratoryExamination:laboratoryExamination =new laboratoryExamination;
	
	//胆汁酸全套
	completeSetOfBileAcids:completeSetOfBileAcids=new completeSetOfBileAcids;
	
	//淋巴细胞技术
	lymphocyteTechnique:lymphocyteTechnique=new lymphocyteTechnique;

	//传染病全套
	fullSetOfInfectiousDiseases:fullSetOfInfectiousDiseases=new fullSetOfInfectiousDiseases;

	//超声结果接随访
	polypInformation:polypInformation=new polypInformation;

};