import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './module/customer/authentication/components/login/login.component';
import { RegisterComponent } from './module/customer/authentication/components/register/register.component';
import { IndexComponent } from './module/customer/userInfo/components/index/index.component';
import { BodyInfoComponent } from './module/customer/userInfo/components/body-info/body-info.component';
import { PersonalInfoComponent } from './module/customer/userInfo/components/personal-info/personal-info.component';
import { AnalyseHistoryComponent } from './module/customer/analyse/components/analyse-history/analyse-history.component';
import { AuthenticationService } from './module/customer/services/authentication.service';
import { PersonalInfoService } from './module/customer/services/personalInfo.service';
import { BusinessCheckService } from './module/customer/services/businessCheck.service';
import { SessionService } from './module/customer/services/session.service';
import { ValidationService } from './module/customer/services/validation.service';
import { CommonService } from './module/customer/services/common.service';
import { AnalyseService } from './module/customer/services/analyse.service';
import { WxService } from './module/customer/services/wx.service';


import {HttpClientModule,HttpClientJsonpModule} from '@angular/common/http';
import { ForgetPasswordComponent } from './module/customer/authentication/components/forget-password/forget-password.component';
import { PolypInformationComponent } from './module/customer/analyse/components/polyp-information/polyp-information.component';
import { LaboratoryExaminationComponent } from './module/customer/analyse/components/laboratory-examination/laboratory-examination.component';
import { HistoryInformationComponent } from './module/customer/analyse/components/history-information/history-information.component';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { privateInfoComponent } from './module/customer/authentication/components/privateInfo/privateInfo.component'
import { DisclaimerComponent } from './module/customer/authentication/components/disclaimer/disclaimer.component'
import { MessageComponent } from './module/customer/message/message.component'
import { DuplicateMessageComponent } from './module/customer/duplicateMessage/duplicateMessage.component'
import { ResultMessageComponent } from './module/customer/resultMessage/resultMessage.component'

//ng add @angular/material
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule } from '@angular/material/dialog';


//admin
import { AdminLoginComponent } from './module/admin/components/admin-login/admin-login.component';
import { ImportPatientInfoComponent } from './module/admin/components/importPatientInfo/importPatientInfo.component';
import { SearchPatientInfoComponent } from './module/admin/components/searchPatientInfo/searchPatientInfo.component';
import { FindPatientDetailInfoComponent } from './module/admin/components/findPatientDetailInfo/findPatientDetailInfo.component';
import { PageComponent } from './module/common/page/page.component';

import {SimpleReuseStrategy} from './SimpleReuseStrategy ';
import {RouteReuseStrategy, RouterModule, Routes} from '@angular/router';
import { TranslateComponent } from './module/common/translate/translate.component';
import { LoadingSerProvider } from './module/common/loading/loadingSerProvider';
import { AlterSerProvider } from './module/common/alter/alterSerProvider';

import { IonicModule } from "@ionic/angular";

 
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    IndexComponent,
    BodyInfoComponent,
    PersonalInfoComponent,
    AnalyseHistoryComponent,
    ForgetPasswordComponent,
    PolypInformationComponent,
    LaboratoryExaminationComponent,
    HistoryInformationComponent,
    MessageComponent,
    DuplicateMessageComponent,
    ResultMessageComponent,
    AdminLoginComponent,
    ImportPatientInfoComponent,
    SearchPatientInfoComponent,
    FindPatientDetailInfoComponent,
    PageComponent,
    privateInfoComponent,
    TranslateComponent,
    DisclaimerComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserAnimationsModule,
    MatDialogModule, 
    MatInputModule, 
    MatButtonModule, 
    MatCardModule, 
    MatFormFieldModule,
    IonicModule.forRoot(),
  ],
  providers: [
    AuthenticationService,
    PersonalInfoService,
    CommonService,
    ValidationService,
    SessionService,
    AnalyseService,
    BusinessCheckService,
    LoadingSerProvider,
    AlterSerProvider,
    WxService,{ provide: RouteReuseStrategy, useClass: SimpleReuseStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
