import { Component, OnInit} from '@angular/core';
import { bodyInfo } from '../../../stage/bodyInfo';
import { BodyInfoService } from '../../../services/bodyInfo.service';
import { Router } from '@angular/router';
import { SessionService } from '../../../services/session.service';
import { ValidationService } from '../../../services/validation.service';
import { LoadingSerProvider } from '../../../../common/loading/loadingSerProvider';
@Component({
  selector: 'app-body-info',
  templateUrl: './body-info.component.html',
  styleUrls: ['./body-info.component.scss']
})
export class BodyInfoComponent implements OnInit {

  public newbodyInfo:bodyInfo=new bodyInfo();

  public smokeDiv:any;

  public otherNationInput:any;
 
  public smokingTypeCheckFlg:Boolean=false;

  constructor(
    private router:Router,
    private bodyInfoService: BodyInfoService,
    public  validationService:ValidationService,
    private sessionService:SessionService,
    private loadingSerProvider:LoadingSerProvider
    ) { }

  ngOnInit(): void {


    const user=this.sessionService.getSession("user");

    if(!user){
      this.router.navigate(['/login']); 
      return;
     }


    this.smokeDiv=  document.getElementById("smokeDiv");

    this.smokeDiv.style.display="";

        
    this.loadingSerProvider.show("请稍等");
    this.bodyInfoService.selectBodyInfo(user.userId,user.token).toPromise().then(dataJson=>{


      const response = JSON.parse(JSON.stringify((<any>dataJson) ))
    
      this.newbodyInfo = response.data;
    


      if(!this.newbodyInfo){

        this.newbodyInfo=new bodyInfo();

      }


      this.newbodyInfo.userId=user.userId
      this.smokingHistoryFlgSelect();
      this.wineHistoryFlgSelect();
      this.diabetesMellitusFlgSelect();
      this.cerebrovascularDiseaCsesFlgSelect();
   
     
      this.loadingSerProvider.hide()
    }).catch(err =>{
      this.loadingSerProvider.hide()
      this.validationService.showSysErroMessage();
      return;
    })
  }

//吸烟史
smokingHistoryFlgSelect(){
  let smokeDiv :any = document.getElementById("smokeDiv");
  if(this.newbodyInfo.smokingHistoryFlg=="0"||this.newbodyInfo.smokingHistoryFlg==""){
    smokeDiv.style.display="none";
    this.newbodyInfo.smokingTypeCheckFlg=false;//电子烟
    this.newbodyInfo.smokeCount="";//每天几支
    this.newbodyInfo.smokingHistoryYear="";//吸烟年限
  }else{
    smokeDiv.style.display="";
  }

}


//饮酒史
wineHistoryFlgSelect(){
  let wineDiv :any = document.getElementById("wineDiv");
  if(this.newbodyInfo.wineHistoryFlg=="0"||this.newbodyInfo.wineHistoryFlg==""){

    wineDiv.style.display="none";
    this.newbodyInfo.wineLikeFlg="";//饮酒号 
    this.newbodyInfo.wineCount="";//每天几两
    this.newbodyInfo.wineHistoryYear="";//饮酒年限
  }else{
    wineDiv.style.display="";


  }

}





//糖尿病
diabetesMellitusFlgSelect(){
  let bloodSugarDiv :any = document.getElementById("bloodSugarDiv");

  if(this.newbodyInfo.diabetesMellitusFlg=="0"||this.newbodyInfo.diabetesMellitusFlg==""){

    bloodSugarDiv.style.display="none";
    this.newbodyInfo.bloodSugarBefore="";

    this.newbodyInfo.bloodSugarAfter="";

    this.newbodyInfo.takingMedicine="";

  }else{
    bloodSugarDiv.style.display="";




  }


}

//心脑血管疾病
cerebrovascularDiseaCsesFlgSelect(){
  let cerebrovascularDiseaCsesDiv :any = document.getElementById("cerebrovascularDiseaCsesDiv");
  if(this.newbodyInfo.cerebrovascularDiseaCsesFlg=="0"||this.newbodyInfo.cerebrovascularDiseaCsesFlg==""){

    cerebrovascularDiseaCsesDiv.style.display="none";
  
    this.newbodyInfo.cerebrovascularDiseaCsesOtherCheckFlg=false;



  }else{
    cerebrovascularDiseaCsesDiv.style.display="";
  }

  this.cerebrovascularDiseaCsesOtherCheckFlg();

}

//其它心血管疾病：
cerebrovascularDiseaCsesOtherCheckFlg(){

  let cerebrovascularDiseaCsesOtherInput :any = document.getElementById("cerebrovascularDiseaCsesOtherInput");
  if(this.newbodyInfo.cerebrovascularDiseaCsesOtherCheckFlg){
    cerebrovascularDiseaCsesOtherInput.disabled=false;
  

  }else{

    cerebrovascularDiseaCsesOtherInput.disabled=true;
    this.newbodyInfo.cerebrovascularDiseaCsesOther=""
  }

}



validationCheck(){
  
  //吸烟史
  if(this.newbodyInfo.smokingHistoryFlg=="1"){

    if(!this.validationService.inputCheck("每天几支",this.newbodyInfo.smokeCount)){
  
      return false;
      }
  
      if(!this.validationService.inputCheck("吸烟年限",this.newbodyInfo.smokingHistoryYear)){
    
        return false;
      

  }
  }
//饮酒史
  if(this.newbodyInfo.wineHistoryFlg=="1"){

    if(!this.validationService.inputCheck("每天几两",this.newbodyInfo.wineCount)){
  
      return false;
      }
  
      if(!this.validationService.inputCheck("持续多少年",this.newbodyInfo.wineHistoryYear)){
    
        return false;
      

  }  
  }

//糖尿病
if(this.newbodyInfo.diabetesMellitusFlg!="0"){

  if(!this.validationService.inputNumberAndPointMustCheck("控制情况：空腹血糖",this.newbodyInfo.bloodSugarBefore)){

    return false;
    }

    if(!this.validationService.inputNumberAndPointMustCheck("控制情况：餐后血糖",this.newbodyInfo.bloodSugarAfter)){
  
      return false;
}  
}

//其它心血管疾病
if(this.newbodyInfo.cerebrovascularDiseaCsesOtherCheckFlg){

  if(!this.validationService.inputMustCheck("其它心血管疾病",this.newbodyInfo.cerebrovascularDiseaCsesOther)){

    return false;
    }

}



  return true; 
}


  doSubmit(){

    if(!this.validationCheck()){
      return; 
     }


    const user=this.sessionService.getSession("user");


    this.bodyInfoService.changeBodyInfo(this.newbodyInfo,user.token).toPromise().then(dataJson=>{
      const response = JSON.parse(JSON.stringify((<any>dataJson) ))

      if(response.code=="004"){
        
        this.validationService.showMessage("保存成功")
        return;
      }else{
        this.validationService.showMessage("保存失败")

      }
    
     
  
  
      
    }).catch(err =>{
      this.validationService.showSysErroMessage();

    })




      }


}
