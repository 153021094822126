import {Component, Inject, Injectable} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { Router } from '@angular/router';
@Component({

templateUrl: 'resultMessage.component.html',
styleUrls: ['./resultMessage.component.scss']
})
export class ResultMessageComponent {


public analyseResult:String="2";

 constructor(private dialogRef: MatDialogRef<ResultMessageComponent>, @Inject(MAT_DIALOG_DATA) public data: any,public router:Router) {

     this.analyseResult=data.analyseResult
 }
 public yes() {

 this.dialogRef.close("1");
 }

 public no() {

this.dialogRef.close("2");
}
}