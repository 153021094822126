export class bodyInfo{
  userId:string='';
  bodyInfoId:string='';
  //吸烟
  smokingHistoryFlg:string='';//吸烟史
  smokingTypeCheckFlg:boolean=false;//电子烟




  smokeCount:string='';//每天几支
  smokingHistoryYear:string='';//吸烟年限
  //饮酒
  wineHistoryFlg:string='';//饮酒史 
  wineLikeFlg:string='';//饮酒号 
  wineCount:string='';//每天几两
  wineHistoryYear:string='';//饮酒年限

  //糖尿病
  diabetesMellitusFlg:string='';//糖尿病

  bloodSugarBeforeCheckFlg:boolean=false;//空腹血糖
  bloodSugarBefore:string='';//空腹血糖


  bloodSugarAfterCheckFlg:boolean=false;///餐后血糖
  bloodSugarAfter:string='';//餐后血糖
  
  
  takingMedicineCheckFlg:boolean=false;//服药
  takingMedicine:string='';//服药

  fattyLiverFlg:string='';//脂肪肝
  cirrhosisFlg:string='';//肝硬化

  hyperlipidemiaFlg:string='';//高脂血症

  pancreatitisFlg:string='';//胰腺炎
  gastritisFlg:string='';//胃炎
  gastricUlcerFlg:string='';//胃溃疡

  cerebrovascularDiseaCsesFlg:string='';//心脑血管疾病

  cerebrovascularDiseaCsesOtherCheckFlg:boolean=false;//其它心血管疾病
  cerebrovascularDiseaCsesOther:string='';//其它心血管疾病

  hepatitisFlg:string='';//肝炎


  polypsInOtherPartsCheckFlg:boolean=false;//其他部位息肉
  polypsInOtherParts:string='';//其他部位息肉
 
  neoplasticDiseasesCheckFlg:boolean=false;//肿瘤性疾病
  neoplasticDiseases:string='';//肿瘤性疾病

  otherAutoimmuneDiseasesCheckFlg:boolean=false;//其他自身免疫疾病
  otherAutoimmuneDiseases:string='';//其他自身免疫疾病

  otherDiseasesCheckFlg:boolean=false;//其他疾病史
  otherDiseases:string='';//其他疾病史
  isGeneralAnesthesiaFlg:string='';//是否有全麻手术史



};