
import { Injectable } from '@angular/core';
import{ LoadingController, ToastController  } from'@ionic/angular';


@Injectable()
export class   LoadingSerProvider {
  constructor(
    private loadingCtrl: LoadingController,
    private toastController: ToastController
  ) {
 
  }


  public loadingIsOpen: any = false;


//loading加载
  async show(message: string) {
    this.loadingIsOpen = true;
    return await this.loadingCtrl.create({
      message: message,
      duration: 7000,
    }).then(a => {
      a.present().then(() => {
        if (!this.loadingIsOpen) {
          a.dismiss()
        }
      });
    });
  }
//loading结束
  async hide() {
    if (this.loadingIsOpen == true) {
      this.loadingIsOpen = false;
      return await this.loadingCtrl.dismiss();
    }
    return this.loadingIsOpen
  }


  // 显示loading
   async showLoading(message: string) {

    const loader = await this.loadingCtrl.create({
      message: message
    });
    await loader.present();

 }

  async dismiss() {

  await this.loadingCtrl.dismiss();
}

    /**
     * Toast全局提示
     * @param {ToastController} toastCtrl
     * @param {string} message
     * @returns {toast}
     * @memberof BaseUI
     */
    protected async showToast(toastCtrl: ToastController, message: string) {
      const toast = await toastCtrl.create({
          message: message,
          duration: 2000,  // 默认展示的时长
          position: 'top'
      });
      await toast.present();
      return toast;
  }

  // show() {

  //   console.log("show")

  //   this.loading = await loadingCtrl.create({
  //     spinner: 'bubbles'
  //   });
  //   this.loading.getTop();

  // }

  // async dismiss(loadingCtrl: LoadingController) {
  //   let topLoader = await loadingCtrl.getTop();
  //   while (topLoader) {
  //     if (!(await topLoader.dismiss())) {
  //       // throw new Error('Could not dismiss the topmost loader. Aborting...');
  //       break
  //     }
  //     topLoader = await loadingCtrl.getTop();
  //   }
  // }
  // 隐藏loading

  // protected async hide(loadingCtrl: LoadingController) {
  //   if (this.loadingIsOpen == true) {
  //     this.loadingIsOpen = false;
  //      await loadingCtrl.dismiss();
  //   }
  // }
}
