import { Injectable,LOCALE_ID,Inject  } from '@angular/core';
import { formatDate } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(@Inject(LOCALE_ID) private locale: string ) { }


  getCurrentDate() {
    return formatDate(new Date(), 'yyyy/MM/dd', this.locale);
  }

}
