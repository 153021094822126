import { Component, OnInit  } from '@angular/core';
import { personalInfo } from '../../../stage/personalInfo';
import { Router } from '@angular/router';
import { SessionService } from '../../../services/session.service';
import { PersonalInfoService } from '../../../services/personalInfo.service';
import { ValidationService } from '../../../services/validation.service';
import { LoadingSerProvider } from '../../../../common/loading/loadingSerProvider'

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss']
})
export class PersonalInfoComponent   implements OnInit {

  public newPersonalInfo:personalInfo=new personalInfo();
  
  public otherNationSpan:any;

  public otherNationInput:any;
  

  
  constructor(
    public loadingSerProvider:LoadingSerProvider,
    private personalInfoService:PersonalInfoService,
    public validationService:ValidationService,
    private router:Router,
    private sessionService:SessionService    
    ) 
    { 
     
  }

  ngOnInit(): void {



     const user=this.sessionService.getSession("user");


     if(!user){
      this.router.navigate(['/login']); 
      return;
     }
     this.loadingSerProvider.show("请稍等");
     this.otherNationSpan=  document.getElementById("otherNationSpan");
     this.otherNationInput =  document.getElementById("otherNationInput");
    
    this.personalInfoService.selectPersonalInfo(user.userId,user.token).toPromise().then(dataJson=>{


   const response = JSON.parse(JSON.stringify((<any>dataJson) ))

  this.newPersonalInfo = response.data;

  if(!this.newPersonalInfo){

    this.newPersonalInfo=new personalInfo();


    this.newPersonalInfo.name=user.name;
  
    this.newPersonalInfo.phoneNumber=user.phoneNumber;
    
    this.newPersonalInfo.idNumber=user.idNumber;
  }
  this.newPersonalInfo.userId=user.userId
    
  this.selectChange();

  this.loadingSerProvider.hide()
}).catch(err =>{
  this.loadingSerProvider.hide()
  this.validationService.showSysErroMessage();
  return;

})
    }

  


  doSubmit(){

    if(!this.validationCheck()){
      return; 
     }


    const user=this.sessionService.getSession("user");
 


    this.personalInfoService.changePersonalInfo(this.newPersonalInfo,user.token).toPromise().then(dataJson=>{
      const response = JSON.parse(JSON.stringify((<any>dataJson) ))
      if(response.code=="004"){
        
        this.validationService.showMessage("保存成功")
        return;
      }else{
        this.validationService.showMessage("保存失败")

      }
   
      
    }).catch(err =>{
      this.validationService.showSysErroMessage();
    })



      }




selectChange(){

  if(this.newPersonalInfo.nationFlg=="2"){
    this.otherNationSpan.style.display="";
    this.otherNationInput.style.display="";

    
  }else{
    this.otherNationSpan.style.display="none";
    this.otherNationInput.style.display="none";
    //其他民族
    this.newPersonalInfo.otherNation=""; 
  }

}


validationCheck(){
  
  if(!this.validationService.selectMustCheck("性别",this.newPersonalInfo.sexFlg)){
  
    return false;
    }
  
  if(!this.validationService.inputCheck("年龄",this.newPersonalInfo.age)){
  
      return false;
  }
  if(!this.validationService.inputNumberCheck("手機號碼",this.newPersonalInfo.phoneNumber)){
  
    return false;
}
if(!this.validationService.inputNumberCheck("身份证",this.newPersonalInfo.idNumber)){
  
  return false;
}
if(!this.validationService.IdNmberCheck(this.newPersonalInfo.idNumber)){
  
  return false;
}
if(!this.validationService.inputNumberCheck("身高(cm)",this.newPersonalInfo.height)){
  
  return false;
}
if(!this.validationService.inputNumberCheck("体重(kg)",this.newPersonalInfo.weight)){
  
  return false;
}
if(!this.validationService.inputNumberCheck("腰围(cm)",this.newPersonalInfo.waistline)){
  
  return false;
}

if(!this.validationService.dateCheck("门诊日期",this.newPersonalInfo.outpatientDate)){
  
  return false;
}
if(!this.validationService.inputNumberCheck("病程时间(月)",this.newPersonalInfo.durationOfDisease)){
  
  return false;
}
if(!this.validationService.inputNumberCheck("就诊血压(收缩压)",this.newPersonalInfo.bloodPressureHigh)){
  
  return false;
}
if(!this.validationService.inputNumberCheck("就诊血压(舒张压)",this.newPersonalInfo.bloodPressureLow,)){
  
  return false;
}

if(this.newPersonalInfo.nationFlg=="2"){
  if(!this.validationService.inputMustCheck("少数民族",this.newPersonalInfo.otherNation)){
  
    return false;
  }
}

  return true; 
  }


}
