
    <div class="head">
    
        
        病人詳情
        
        
        </div>
        <div class="zhuce">   
            <div class="text" >
                <span class="title">一般情况:</span>
                <div >
                    <div class="widget-body no-padding">
                        <table id="dt-table" class="table table-striped table-bordered table-hover" style="width:100%">
                            <thead class="table_head">                      
                                <tr>
                                    <th>姓名</th>
                                    <th>性别</th>
                                    <th>年龄</th>
                                    <th>入院日期</th>
                                    <th>联系方式</th>
                                    <th>住院号</th>
                                    <th>身高</th>
                                    <th>体重</th>
                                    <th>身份证</th>
                                    <th>高血压</th>
                                    <th>糖尿病</th>
                                    <th>高血脂</th>
                                    <th>慢病</th>
                                    <th>临床诊断</th>
                                </tr>
                               
                            </thead>
                            <tbody class="table_body">
                                <tr>
                                    <th>{{patientInfo.name}}</th>
                                    <th>{{patientInfo.sexFlg}}</th>
                                    <th>{{patientInfo.age}}</th>
                                    <th>{{patientInfo.contact}}</th>
                                    <th>{{patientInfo.idNumber}}</th>
                                    <th>{{patientInfo.height}}</th>
                                    <th>{{patientInfo.weight}}</th>
                                    <th>{{patientInfo.hypertensionFlg}}</th>
                                    <th>{{patientInfo.diabetesMellitusFlg}}</th>
                                    <th>{{patientInfo.hyperlipemiaFlg}}</th>
                                    <th>{{patientInfo.chronicDisease}}</th>
                                    <th>{{patientInfo.clinicalDiagnosis}}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
            </div>
             
        </div>      
        <div class="text" >
        <span class="title">病理检查:</span>
        <div >
            <div class="widget-body no-padding">
                <table id="dt-table" class="table table-striped table-bordered table-hover" style="width:100%">
                    <thead class="table_head">                      
                        <tr>
                            <th>病理号</th>
                            <th>临床诊断</th>
                            <th>术中冰冻</th>
                            <th>病理诊断</th>
                            <th>特殊检查</th>
                            <th>肉眼所见</th>
                        </tr>
                       
                    </thead>
                    <tbody>
                        <tr>
                            <th>{{patientInfo.pathologicalExamination.pathologyNumber}}</th>
                            <th>{{patientInfo.pathologicalExamination.clinicalDiagnosis}}</th>
                            <th>{{patientInfo.pathologicalExamination.intraoperativeFreezing}}</th>
                            <th>{{patientInfo.pathologicalExamination.pathologicDiagnosis}}</th>
                            <th>{{patientInfo.pathologicalExamination.specialInspection}}</th>
                            <th>{{patientInfo.pathologicalExamination.visibleToTheNakedEye}}</th>
                        </tr>
                    </tbody>
                </table>
            </div>
    </div>
     
</div>
<div class="text" >
    <span class="title">胆汁酸:</span>
    <div >
        <div class="widget-body no-padding">
            <table id="dt-table" class="table table-striped table-bordered table-hover" style="width:100%">
                <thead class="table_head">                      
                    <tr>
                        <th>CA</th>
                        <th>CA per</th>
                        <th>DCA</th>
                        <th>DCA per</th>
                        <th>CDCA</th>
                        <th>CDCA per</th>
                        <th>UDCA/HDCA</th>
                        <th>UDCA per</th>
                        <th>LCA</th>
                        <th>LCA per</th>
                        <th>GCA</th>
                        <th>GCA per</th>
                        <th>GLCA</th>
                        <th>GLCA per</th>
                        <th>GDCA</th>
                        <th>GDCA per</th>
                        <th>GCDCA</th>
                        <th>GCDCA per</th>
                        <th>GUDCA</th>
                        <th>GUDCA per</th>
                        <th>TCA</th>
                        <th>TCA per</th>
                        <th>TLCA</th>
                        <th>TLCA per</th>
                        <th>TDCA</th>
                        <th>TDCA per</th>
                        <th>TCDCA</th>
                        <th>TCDCA per</th>
                        <th>TUDCA</th>
                        <th>TUDCA per</th>
                        <th>15种胆汁酸</th>
                    </tr>                   
                </thead>
                <tbody>
                    <tr>
                        <th>{{patientInfo.bileAcid.cA}}</th>
                        <th>{{patientInfo.bileAcid.cAPer}}</th>
                        <th>{{patientInfo.bileAcid.dCA}}</th>
                        <th>{{patientInfo.bileAcid.dCAPer}}</th>
                        <th>{{patientInfo.bileAcid.cDCA}}</th>
                        <th>{{patientInfo.bileAcid.cDCAPer}}</th>
                        <th>{{patientInfo.bileAcid.uDCAOrHDCA}}</th>
                        <th>{{patientInfo.bileAcid.uDCAPer}}</th>
                        <th>{{patientInfo.bileAcid.lCA}}</th>
                        <th>{{patientInfo.bileAcid.lCAPer}}</th>
                        <th>{{patientInfo.bileAcid.gCA}}</th>
                        <th>{{patientInfo.bileAcid.gCAPer}}</th>
                        <th>{{patientInfo.bileAcid.gLCA}}</th>
                        <th>{{patientInfo.bileAcid.gLCAPer}}</th>
                        <th>{{patientInfo.bileAcid.gDCA}}</th>
                        <th>{{patientInfo.bileAcid.gDCAPer}}</th>
                        <th>{{patientInfo.bileAcid.gCDCA}}</th>
                        <th>{{patientInfo.bileAcid.gCDCAPer}}</th>
                        <th>{{patientInfo.bileAcid.gUDCA}}</th>
                        <th>{{patientInfo.bileAcid.gUDCAPer}}</th>
                        <th>{{patientInfo.bileAcid.tCA}}</th>
                        <th>{{patientInfo.bileAcid.tCAPer}}</th>
                        <th>{{patientInfo.bileAcid.tLCA}}</th>
                        <th>{{patientInfo.bileAcid.tLCAPer}}</th>
                        <th>{{patientInfo.bileAcid.tDCA}}</th>        
                        <th>{{patientInfo.bileAcid.tDCAPer}}</th>   
                        <th>{{patientInfo.bileAcid.tCDCA}}</th>   
                        <th>{{patientInfo.bileAcid.tCDCAPer}}</th>     
                        <th>{{patientInfo.bileAcid.tUDCA}}</th>     
                        <th>{{patientInfo.bileAcid.tUDCAPer}}</th>     
                        <th>{{patientInfo.bileAcid.bileAcid15}}</th>     
                    </tr>
                </tbody>
            </table>
        </div>
</div>
 
</div>
<div class="text" >
    <span class="title">凝血常规:</span>
    <div >
        <div class="widget-body no-padding">
            <table id="dt-table" class="table table-striped table-bordered table-hover" style="width:100%">
                <thead class="table_head">                      
                    <tr>
                        <th>国际标准化比率</th>
                        <th>凝血酶原时间</th>
                        <th>凝血酶时间</th>
                        <th>纤维蛋白原</th>
                        <th>部分凝血活酶时间</th>
                        
                    </tr>                   
                </thead>
                <tbody>
                    <tr>
                        <th>{{patientInfo.coagulationRoutine.internationalStandardizationRatio}}</th>   
                        <th>{{patientInfo.coagulationRoutine.prothrombinTime}}</th>     
                        <th>{{patientInfo.coagulationRoutine.ThrombinTime}}</th>     
                        <th>{{patientInfo.coagulationRoutine.fibrinogen}}</th>     
                        <th>{{patientInfo.coagulationRoutine.partialThromboplastinTime}}</th>              
                    </tr>
                </tbody>
            </table>
        </div>
</div>
 
</div> 
<div class="text" >
    <span class="title">血常规:</span>
    <div >
        <div class="widget-body no-padding">
            <table id="dt-table" class="table table-striped table-bordered table-hover" style="width:100%">
                <thead class="table_head">                      
                    <tr>
                        <th>ABO血型</th>
                        <th>有核红细胞百分比</th>
                        <th>有核红细胞绝对值</th>
                        <th>嗜碱性粒细胞绝对值</th>
                        <th>嗜酸性粒细胞绝对值</th>
                        <th>单核细胞绝对值</th>
                        <th>红细胞压积</th>
                        <th>嗜碱性粒细胞百分比</th>
                        <th>平均血小板体积</th>
                        <th>血小板分布宽度</th>
                        <th>红细胞分布宽度CV</th>
                        <th>血红蛋白</th>
                        <th>淋巴细胞绝对值</th>
                        <th>血小板比积</th>
                        <th>嗜酸性粒细胞百分比</th>
                        <th>血小板计数</th>
                        <th>大血小板比率</th>
                        <th>平均血红蛋白量</th>
                        <th>嗜中性粒细胞绝对值</th>
                        <th>平均血红蛋白浓度</th>
                        <th>淋巴细胞百分比</th>
                        <th>红细胞分布宽度SD</th>
                        <th>红细胞计数</th>
                        <th>白细胞计数</th>
                        <th>嗜中性粒细胞百分比</th>
                        <th>单核细胞百分比</th>
                        <th>平均红细胞体积</th>                       
                    </tr>                   
                </thead>
                <tbody>
                    <tr>
                        <th>{{patientInfo.routineBloodTest.aBOBloodGroup}}</th>   
                        <th>{{patientInfo.routineBloodTest.percentageOfNucleatedRedBloodCells}}</th>     
                        <th>{{patientInfo.routineBloodTest.absoluteValueOfNucleatedRedBloodCells}}</th>     
                        <th>{{patientInfo.routineBloodTest.basophilAbsoluteValue}}</th>     
                        <th>{{patientInfo.routineBloodTest.eosinophilAbsoluteValue}}</th>    
                        <th>{{patientInfo.routineBloodTest.absoluteValueOfMonocytes}}</th>   
                        <th>{{patientInfo.routineBloodTest.hematocrit}}</th>     
                        <th>{{patientInfo.routineBloodTest.percentageOfBasophils}}</th>     
                        <th>{{patientInfo.routineBloodTest.meanPlateletVolume}}</th>     
                        <th>{{patientInfo.routineBloodTest.plateletDistributionWidth}}</th>    
                        <th>{{patientInfo.routineBloodTest.erythrocyteDistributionWidthCV}}</th>   
                        <th>{{patientInfo.routineBloodTest.hemoglobin}}</th>     
                        <th>{{patientInfo.routineBloodTest.lymphocyteAbsoluteValue}}</th>     
                        <th>{{patientInfo.routineBloodTest.plateletSpecificVolume}}</th>     
                        <th>{{patientInfo.routineBloodTest.percentageOfEosinophils}}</th>
                        <th>{{patientInfo.routineBloodTest.plateletCount}}</th>   
                        <th>{{patientInfo.routineBloodTest.largePlateletRatio}}</th>     
                        <th>{{patientInfo.routineBloodTest.meanHemoglobin}}</th>     
                        <th>{{patientInfo.routineBloodTest.neutrophilAbsoluteValue}}</th>     
                        <th>{{patientInfo.routineBloodTest.meanHemoglobinConcentration}}</th>    
                        <th>{{patientInfo.routineBloodTest.percentageOfLymphocytes}}</th>   
                        <th>{{patientInfo.routineBloodTest.redBloodCellDistributionWidthSd}}</th>     
                        <th>{{patientInfo.routineBloodTest.redBloodCellCount}}</th>     
                        <th>{{patientInfo.routineBloodTest.leukocyteCount}}</th>     
                        <th>{{patientInfo.routineBloodTest.percentageOfNeutrophils}}</th>    
                        <th>{{patientInfo.routineBloodTest.percentageOfMonocytes}}</th>   
                        <th>{{patientInfo.routineBloodTest.meanRedBloodCellVolume}}</th>                  
                    </tr>
                </tbody>
            </table>
        </div>
</div>
 
</div> 
       

<div class="text" >
    <span class="title">肝功能:</span>
    <div >
        <div class="widget-body no-padding">
            <table id="dt-table" class="table table-striped table-bordered table-hover" style="width:100%">
                <thead class="table_head">                      
                    <tr>
                        <th>血清游离脂肪酸(NEFA)</th>
                        <th>小而密低密度脂蛋白(sd-LDL)</th>
                        <th>甘油三脂(TG)</th>
                        <th>载脂蛋白B(APOB)</th>
                        <th>胱抑素C(CysC)</th>
                        <th>甘胆酸(CG)</th>
                        <th>载脂蛋白A1(APOA)</th>
                        <th>高密度脂蛋白胆固醇(HDL)</th>
                        <th>白球比例(A:G)</th>
                        <th>低密度脂蛋白胆固醇(LDL)</th>
                        <th>氯(CL)</th>
                        <th>天门冬氨酸氨基转移酶(AST)</th>
                        <th>eGFR-MDRD(eGFR(MDRD))</th>
                        <th>eGFR-EPI Cr</th>
                        <th>丙氨酸氨基转移酶(ALT)</th>
                        <th>钠(NA)</th>
                        <th>岩藻糖苷酶(AFU)</th>
                        <th>乳酸脱氢酶(LDH)</th>
                        <th>脂蛋白(a)(LPa)</th>
                        <th>非高密度脂蛋白胆固醇(NHDL)</th>
                        <th>直接胆红素(DBIL)</th>
                        <th>谷氨酸脱氢酶(GLDH)</th>
                        <th>尿酸(URIC)</th>
                        <th>前白蛋白(PA)</th>
                        <th>球蛋白(GLB)</th>
                        <th>载脂蛋白E(APOE)</th>
                        <th>总胆固醇(CHOL)</th>
                        <th>尿素氮(BUN)</th>
                        <th>纤维连接蛋白(FN)</th>
                        <th>谷氨酰转肽酶(GGT)</th>
                        <th>钾(K)</th>
                        <th>白蛋白(ALB)</th>
                        <th>总胆红素(TBIL)</th>
                        <th>空腹血糖(GLU)</th>
                        <th>总胆汁酸(TBA)</th>
                        <th>碱性磷酸酶(ALP)</th>
                        <th>肌酐(CREA)</th>
                        <th>总蛋白(TP)</th>                      
                    </tr>                   
                </thead>
                <tbody>
                    <tr>
                        <th>{{patientInfo.liverAndKidneyFunction.serumFreeFattyAcids_NEFA}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.smallDenseLowDensityLipoprotein_SD_LDL}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.triglyceride_TG}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.apolipoproteinB_apoB}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.cystatinC_CysC}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.glycocholicAcid_CG}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.ApolipoproteinA1_ApoA}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.highDensityLipoproteinCholesterol_HDL}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.whiteballRatio_A_G}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.lowDensityLipoproteinCholesterol_LDL}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.chlorine_CL}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.aspartateAminotransferase_AST}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.eGFR_MDRD_eGFR_MDRD}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.eGFR_EPI_Cr}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.alanineAminotransferase_ALT}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.sodium_NA}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.fucosidase_AFU}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.lactateDehydrogenase_LDH}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.lipoprotein_a_LPA}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.nonHighDensityLipoproteinCholesterol_nhdl}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.directBilirubin_DBIL}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.glutamateDehydrogenase_GLDH}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.uricAcid_uric}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.prealbumin_PA}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.globulin_GLB}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.apolipoproteinE_ApoE}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.totalCholesterol_Chol}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.ureaNitrogen_BUN}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.Fibronectin_FN}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.glutamyl_transpeptidase_GGT}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.potassium_k}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.albumin_ALB}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.totalBilirubin_TBIL}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.fastingBloodGlucose_Glu}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.totalBileAcid_TBA}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.alkalinePhosphatase_ALP}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.creatinineCrea}}</th>  
                        <th>{{patientInfo.liverAndKidneyFunction.totalProtein_TP}}</th>          
                    </tr>
                </tbody>
            </table>
        </div>
</div>
 
</div> 
<div class="text" >
    <span class="title">肿瘤标记物:</span>
    <div >
        <div class="widget-body no-padding">
            <table id="dt-table" class="table table-striped table-bordered table-hover" style="width:100%">
                <thead class="table_head">                      
                    <tr>
                        <th>癌胚抗原(CEA)</th>
                        <th>糖类抗原(CA724)</th>
                        <th>糖类抗原(CA125)</th>
                        <th>甲胎蛋白(AFP)</th>
                        <th>糖类抗原(CA19-9)</th>
                        <th>糖类抗原CA50</th>
                        <th>糖类抗原242</th>                     
                    </tr>                   
                </thead>
                <tbody>
                    <tr>
                        <th>{{patientInfo.laboratoryExamination.cea}}</th>  
                        <th>{{patientInfo.laboratoryExamination.ca724}}</th>  
                        <th>{{patientInfo.laboratoryExamination.ca125}}</th>  
                        <th>{{patientInfo.laboratoryExamination.afp}}</th>  
                        <th>{{patientInfo.laboratoryExamination.ca199}}</th>  
                        <th>{{patientInfo.laboratoryExamination.ca50}}</th>  
                        <th>{{patientInfo.laboratoryExamination.c242}}</th>           
                    </tr>
                </tbody>
            </table>
        </div>
</div>
 
</div> 
<div class="text" >
    <span class="title">胆汁酸全套:</span>
    <div >
        <div class="widget-body no-padding">
            <table id="dt-table" class="table table-striped table-bordered table-hover" style="width:100%">
                <thead class="table_head">                      
                    <tr>
                        <th>石胆酸</th>
                        <th>牛磺石胆酸</th>
                        <th>甘氨石胆酸</th>
                        <th>熊脱氧胆酸</th>
                        <th>胆酸</th>
                        <th>谷氨酸</th>
                        <th>鹅脱氧胆酸</th>
                        <th>鸟氨酸</th>
                        <th>牛磺鹅脱氧胆酸</th>
                        <th>甘氨鹅脱氧胆酸</th>
                        <th>脱氧胆酸</th>
                        <th>亮氨酸</th>
                        <th>脯氨酸</th>
                        <th>缬氨酸</th>
                        <th>甘氨脱氧胆酸</th>
                        <th>甘氨熊脱氧胆酸</th>
                        <th>牛磺脱氧胆酸</th>
                        <th>丙氨酸</th>
                        <th>瓜氨酸</th>
                        <th>甘氨酸</th>
                        <th>牛磺胆酸</th>
                        <th>甘氨胆酸</th>
                        <th>牛磺熊脱氧胆酸</th>
                        <th>酪氨酸</th>
                        <th>同型半胱氨酸(质谱)</th>
                        <th>精氨酸</th>                   
                    </tr>                   
                </thead>
                <tbody>
                    <tr>
                        <th>{{patientInfo.completeSetOfBileAcids.lithocholicAcid}}</th>  
                        <th>{{patientInfo.completeSetOfBileAcids.taurocholicAcid1}}</th>  
                        <th>{{patientInfo.completeSetOfBileAcids.glycineCholicAcid}}</th>  
                        <th>{{patientInfo.completeSetOfBileAcids.ursodeoxycholicAcid}}</th>  
                        <th>{{patientInfo.completeSetOfBileAcids.cholicAcid}}</th>  
                        <th>{{patientInfo.completeSetOfBileAcids.glutamate}}</th>  
                        <th>{{patientInfo.completeSetOfBileAcids.chenodeoxycholicAcid}}</th>  
                        <th>{{patientInfo.completeSetOfBileAcids.ornithine}}</th>  
                        <th>{{patientInfo.completeSetOfBileAcids.taurineChenodeoxycholicAcid}}</th>  
                        <th>{{patientInfo.completeSetOfBileAcids.glycineChenodeoxycholicAcid}}</th>  
                        <th>{{patientInfo.completeSetOfBileAcids.deoxycholicAcid}}</th>  
                        <th>{{patientInfo.completeSetOfBileAcids.leucine}}</th>  
                        <th>{{patientInfo.completeSetOfBileAcids.proline}}</th>  
                        <th>{{patientInfo.completeSetOfBileAcids.valine}}</th>  
                        <th>{{patientInfo.completeSetOfBileAcids.glycodeoxycholicAcid}}</th>  
                        <th>{{patientInfo.completeSetOfBileAcids.glycoursodeoxycholicAcid}}</th>  
                        <th>{{patientInfo.completeSetOfBileAcids.taurodeoxycholicAcid}}</th>  
                        <th>{{patientInfo.completeSetOfBileAcids.alanine}}</th>            
                        <th>{{patientInfo.completeSetOfBileAcids.citrulline}}</th>       
                        <th>{{patientInfo.completeSetOfBileAcids.glycine}}</th>       
                        <th>{{patientInfo.completeSetOfBileAcids.taurocholicAcid2}}</th>       
                        <th>{{patientInfo.completeSetOfBileAcids.glycocholicAcid}}</th>       
                        <th>{{patientInfo.completeSetOfBileAcids.taurineUrsodeoxycholicAcid}}</th>       
                        <th>{{patientInfo.completeSetOfBileAcids.tyrosine}}</th>       
                        <th>{{patientInfo.completeSetOfBileAcids.homocysteine_massSpectrometry}}</th>       
                        <th>{{patientInfo.completeSetOfBileAcids.arginine}}</th>    
                    </tr>
                </tbody>
            </table>
        </div>
</div>
</div>
<div class="text" >
    <span class="title">淋巴细胞技术:</span>
    <div >
        <div class="widget-body no-padding">
            <table id="dt-table" class="table table-striped table-bordered table-hover" style="width:100%">
                <thead class="table_head">                      
                    <tr>
                        <th>淋巴细胞绝对值</th>
                        <th>CD4/CD8比值</th>
                        <th>T淋巴细胞绝对值</th>
                        <th>B淋巴细胞绝对值</th>
                        <th>自然杀伤细胞CD56+CD16</th>
                        <th>Ts淋巴细胞  CD8</th>
                        <th>自然杀伤细胞绝对值</th>
                        <th>Ts淋巴细胞绝对值</th>
                        <th>Th淋巴细胞CD4</th>
                        <th>T淋巴细胞CD3</th>
                        <th>Th淋巴细胞绝对值</th>
                        <th>B淋巴细胞CD19</th>                   
                    </tr>                   
                </thead>
                <tbody>
                    <tr>
                        <th>{{patientInfo.lymphocyteTechnique.lymphocyteAbsoluteValue}}</th>       
                        <th>{{patientInfo.lymphocyteTechnique.cd4AndCd8Ratio}}</th>       
                        <th>{{patientInfo.lymphocyteTechnique.tLymphocyteAbsoluteValue}}</th> 
                        <th>{{patientInfo.lymphocyteTechnique.absoluteValueOfBLymphocytes}}</th>       
                        <th>{{patientInfo.lymphocyteTechnique.naturalKillerCell_CD56_CD16}}</th>       
                        <th>{{patientInfo.lymphocyteTechnique.tSLymphocyte_CD8}}</th>       
                        <th>{{patientInfo.lymphocyteTechnique.absoluteValueOfNaturalKillerCells}}</th>       
                        <th>{{patientInfo.lymphocyteTechnique.tSLymphocyteAbsoluteValue}}</th>       
                        <th>{{patientInfo.lymphocyteTechnique.thLymphocyte_CD4}}</th>    
                        <th>{{patientInfo.lymphocyteTechnique.tLymphocyte_CD3}}</th>       
                        <th>{{patientInfo.lymphocyteTechnique.absoluteValueOfThLymphocyte}}</th>       
                        <th>{{patientInfo.lymphocyteTechnique.bLymphocyte_CD19}}</th>       
                    </tr>
                </tbody>
            </table>
        </div>
</div>
</div> 

<div class="text" >
    <span class="title">传染病全套:</span>
    <div >
        <div class="widget-body no-padding">
            <table id="dt-table" class="table table-striped table-bordered table-hover" style="width:100%">
                <thead class="table_head">                      
                    <tr>
                        <th>乙肝表面抗原(YP)</th>
                        <th>乙肝表面抗体(YP)</th>
                        <th>HIV Ag/Ab</th>
                        <th>丙肝抗体</th>
                        <th>乙肝核心抗体(YP)</th>
                        <th>乙肝e抗原(YP)</th>
                        <th>乙肝e抗体(YP)</th>                                       
                    </tr>                   
                </thead>
                <tbody>
                    <tr>
                        <th>{{patientInfo.fullSetOfInfectiousDiseases.hepatitisBSurfaceAntigen_YP}}</th>       
                        <th>{{patientInfo.fullSetOfInfectiousDiseases.hepatitisBSurfaceAntibody_YP}}</th>   
                        <th>{{patientInfo.fullSetOfInfectiousDiseases.hiv_Ag_Ab}}</th>       
                        <th>{{patientInfo.fullSetOfInfectiousDiseases.hepatitisCAntibody}}</th>      
                        <th>{{patientInfo.fullSetOfInfectiousDiseases.hepatitisBCoreAntibody_YP}}</th>       
                        <th>{{patientInfo.fullSetOfInfectiousDiseases.hepatitisBEAntigen_YP}}</th>   
                        <th>{{patientInfo.fullSetOfInfectiousDiseases.hepatitisBEAntibody_YP}}</th>       
                    </tr>
                </tbody>
            </table>
        </div>
</div>
</div> 
<div class="text" >
    <span class="title">超声结果接随访:</span>
    <div >
        <div class="widget-body no-padding">
            <table id="dt-table" class="table table-striped table-bordered table-hover" style="width:100%">
                <thead class="table_head">                      
                    <tr>
                        <th>初次发现息肉大小</th>
                        <th>目前息肉大小</th>
                        <th>是否明确息肉为假性息肉</th>
                        <th>胆囊息肉伴随存在不能用其他原因解释的相关症状</th>
                        <th>息肉数目</th>
                        <th>位置</th>
                        <th>附着面</th>
                        <th>基底部</th>
                        <th>胆囊壁（多选）</th>
                        <th>是否合并胆囊结石</th>
                        <th>发现胆囊结石时间</th>
                        <th>合并原发性硬化性胆管炎</th>
                        <th>胆囊炎：至少发作一次</th>
                        <th>目前是否合并急性胆囊炎</th>
                        <th>目前合并黄疸</th>
                        <th>是否合并脂肪肝</th>
                        <th>是否合并胆道结石</th>
                        <th>是否合并胰腺异常</th>                                    
                    </tr>                   
                </thead>
                <tbody>
                    <tr>
                        <th>{{patientInfo.polypInformation.polypFirst}}</th>  
                        <th>{{patientInfo.polypInformation.polypNow}}</th>  
                        <th>{{patientInfo.polypInformation.isFakePolypFlg}}</th>  
                        <th>{{patientInfo.polypInformation.concomitantSymptoms}}</th>  
                        <th>{{patientInfo.polypInformation.polypCount}}</th>  
                        <th>{{patientInfo.polypInformation.position}}</th>  
                        <th>{{patientInfo.polypInformation.attachmentSurface}}</th>  
                        <th>{{patientInfo.polypInformation.basalPart}}</th>      
                        <th>{{patientInfo.polypInformation.gallbladderWall}}</th>  
                        <th>{{patientInfo.polypInformation.gallstone}}</th>  
                        <th>{{patientInfo.polypInformation.gallstoneTime}}</th>  
                        <th>{{patientInfo.polypInformation.primarySclerosingCholangitis}}</th>  
                        <th>{{patientInfo.polypInformation.isAcuteCholecystitis}}</th>  
                        <th>{{patientInfo.polypInformation.isJaundice}}</th>  
                        <th>{{patientInfo.polypInformation.isFattyIiver}}</th>  
                        <th>{{patientInfo.polypInformation.isCholelithiasis}}</th>        
                        <th>{{patientInfo.polypInformation.isPancreaticAbnormalities}}</th>       
                    </tr>
                </tbody>
            </table>
        </div>
</div>
</div> 
<div class="text1">
    <div class="btndl"><input type="submit" value="返 回" (click)="back()"></div>
</div>
        </div>
         
