import {Component, Inject, Injectable} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
@Component({

templateUrl: 'duplicateMessage.component.html',
styleUrls: ['./duplicateMessage.component.scss']
})
export class DuplicateMessageComponent {

public message:[];
 constructor(private dialogRef: MatDialogRef<DuplicateMessageComponent>, @Inject(MAT_DIALOG_DATA) public data: any,public router:Router) {
     this.message=data.message
 }
 public yes() {

 this.dialogRef.close("1");
 }

 public no() {

this.dialogRef.close("2");
}
}