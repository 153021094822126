<!--分页组建-->
<div class="text-right margin-bottom" *ngIf="pageParams.totalPage>1 && pageParams.totalNum>0">
  <a class="pull-left text-sm">总计 {{pageParams.totalNum}} 条，第 {{pageParams.curPage}} / {{pageParams.totalPage}} 页</a>
  <button class="btn btn-primary next" [routerLink]="['./']" (click)="changePage(pageParams.curPage-1)" [queryParams]="{pageNo:pageParams.curPage-1}"
    [disabled]="pageParams.curPage==1">上一页</button>
  <!-- <button class="btn btn-primary" [routerLink]="['./']" [disabled]="pageParams.curPage==page.pageNo" (click)="changePage(page.pageNo)"
    [queryParams]="page" *ngFor="let page of getPageList(pageParams)">
    {{page.pageNo}}
  </button> -->

  <input type="text" class="btn btn-primary" [disabled]="pageParams.curPage==pageParams.totalPage" [(ngModel)]="pageParams.inputPageNo" > 
  <button class="btn btn-primary" [routerLink]="['./']" (click)="changePage(pageParams.inputPageNo)" [disabled]="pageParams.curPage==pageParams.totalPage">
    跳轉
  </button>

  <button class="btn btn-primary next" [routerLink]="['./']" (click)="changePage(pageParams.curPage-(-1))" [queryParams]="{pageNo:pageParams.curPage-(-1)}"
    [disabled]="pageParams.curPage==pageParams.totalPage">下一页</button>
</div>
<div class="text-center text-sm text-dark-gray" *ngIf="!pageParams.totalNum">
  无数据
</div>