import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http'
import { user } from '../stage/user';
import { Observable } from 'rxjs';
import { Token } from '@angular/compiler/src/ml_parser/lexer';
import { environment } from '../../../../environments/environment';
declare var wx: any;
// declare var wx: any;
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public apiUrl:String = environment.apiUrl
//public apiUrl:String = "http://localhost:8080/"
//public apiUrl:String = "http://8.131.92.240:8080/"

  constructor(public httpClient:HttpClient) {
  }

  register(user:user): Observable<{}>{


    const httpOptions ={headers: new HttpHeaders({'Content-Type':'application/json','token':'1232111111111'}) } 
    return this.httpClient.post(this.apiUrl+"register",user,httpOptions)

// const httpOptions ={headers: new HttpHeaders({'Content-Type':'application/json'}) } 
//  this.httpClient.post(this.apiUrl+"register",user,httpOptions).toPromise().then((data:any)=>{

//   this.apiUrl= data.msg;
//   //this.list=data.result
//    })

 



   
// const httpOptions ={headers: new HttpHeaders({'Content-Type':'application/json'}) } 
// this.httpClient.post(api,user,httpOptions).subscribe((data:any)=>{
//   console.log(data.msg)
  
//   //this.list=data.result
//       })


  }

  login(user:user){

    const httpOptions ={headers: new HttpHeaders({'Content-Type':'application/json'}) } 
    return this.httpClient.post(this.apiUrl+"login",user,httpOptions);
    //  this.httpClient.post(this.apiUrl+"register",user,httpOptions).toPromise().then((data:any)=>{
 

    //   return data;

 
    // }).catch((data:any)=>{

    //  console.log("异常");
    //     })
  
  //this.list=data.result
   }

  
   getOpenId(code:String): Observable<{}>{

    const httpOptions ={headers: new HttpHeaders({'Content-Type':'application/json'}) } 
    return this.httpClient.post(this.apiUrl+"getOpenId",code,httpOptions)

  }


   loginByToken(user:user,token:string){

    const httpOptions ={headers: new HttpHeaders({'Content-Type':'application/json','token':token}) } 
    return this.httpClient.post(this.apiUrl+"login",user,httpOptions);
    //  this.httpClient.post(this.apiUrl+"register",user,httpOptions).toPromise().then((data:any)=>{
 

    //   return data;

 
    // }).catch((data:any)=>{

    //  console.log("异常");
    //     })
  
  //this.list=data.result
   }


   forgotPassword(user:user){


    const httpOptions ={headers: new HttpHeaders({'Content-Type':'application/json','token':'1232111111111'}) } 
    return this.httpClient.post(this.apiUrl+"forgotPassword",user,httpOptions);
    //  this.httpClient.post(this.apiUrl+"register",user,httpOptions).toPromise().then((data:any)=>{
 

    //   return data;

 
    // }).catch((data:any)=>{

    //  console.log("异常");
    //     })
  
  //this.list=data.result
   }


   
// const httpOptions ={headers: new HttpHeaders({'Content-Type':'application/json'}) } 
// this.httpClient.post(api,user,httpOptions).subscribe((data:any)=>{
//   console.log(data.msg)
  
//   //this.list=data.result
//       })
}



