import { patientInfo } from './patientInfo';
export class page{

	pageSize:any;

	curPage:any;
	//病理检查
	//data:patientInfo =new patientInfo();
	data:any;

};