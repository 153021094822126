//血常规
export class routineBloodTest{
  //	ABO血型
aBOBloodGroup:string='';
//	有核红细胞百分比
percentageOfNucleatedRedBloodCells:string='';
//	有核红细胞绝对值
absoluteValueOfNucleatedRedBloodCells:string='';
//	嗜碱性粒细胞绝对值
basophilAbsoluteValue:string='';
//	嗜酸性粒细胞绝对值
eosinophilAbsoluteValue:string='';
//	单核细胞绝对值
absoluteValueOfMonocytes:string='';
//	红细胞压积
hematocrit:string='';
//	嗜碱性粒细胞百分比
percentageOfBasophils:string='';
//	平均血小板体积
meanPlateletVolume:string='';
//	血小板分布宽度
plateletDistributionWidth:string='';
//	红细胞分布宽度CV
erythrocyteDistributionWidthCV:string='';
//	血红蛋白
hemoglobin:string='';
//	淋巴细胞绝对值
lymphocyteAbsoluteValue:string='';
//	血小板比积
plateletSpecificVolume:string='';
//	嗜酸性粒细胞百分比
percentageOfEosinophils:string='';
//	血小板计数
plateletCount:string='';
//	大血小板比率
largePlateletRatio:string='';
//	平均血红蛋白量
meanHemoglobin:string='';
//	嗜中性粒细胞绝对值
neutrophilAbsoluteValue:string='';
//	平均血红蛋白浓度
meanHemoglobinConcentration:string='';
//	淋巴细胞百分比
percentageOfLymphocytes:string='';
//	红细胞分布宽度SD
redBloodCellDistributionWidthSd:string='';
//	红细胞计数
redBloodCellCount:string='';
//	白细胞计数
leukocyteCount:string='';
//	嗜中性粒细胞百分比
percentageOfNeutrophils:string='';
//	单核细胞百分比
percentageOfMonocytes:string='';
//	平均红细胞体积
meanRedBloodCellVolume:string='';
};