import {Component, Inject, Injectable} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { Router } from '@angular/router';
@Component({

templateUrl: 'message.component.html',
styleUrls: ['./message.component.scss']
})
export class MessageComponent {

public message:[];
 constructor(private dialogRef: MatDialogRef<MessageComponent>, @Inject(MAT_DIALOG_DATA) public data: any,public router:Router) {
     this.message=data.message
 }
 public closeMe() {

 this.dialogRef.close();
 }
}