
    <div class="head">
        
        用户登录
        

        </div>
        
        <div class="zhuce">
            <div class="text">
                <!-- <iframe src="//player.bilibili.com/player.html?aid=69570770&cid=120575067&page=1&danmaku=0" allowfullscreen="allowfullscreen" width="100%" height="500" scrolling="no" frameborder="0" sandbox="allow-top-navigation allow-same-origin allow-forms allow-scripts"></iframe> -->
                <!-- <iframe src="https://xbeibeix.com/api/bilibili/biliplayer/?url=https://www.bilibili.com/video/BV1cr4y1a7vf?spm_id_from=444.41.0.0" allowfullscreen="allowfullscreen" width="100%" height="500" scrolling="no" frameborder="0" sandbox="allow-top-navigation allow-same-origin allow-forms allow-scripts"></iframe> -->
                <span >手机号</span>
                <input type="text" placeholder="请输入手机号" id="phoneNumber"  [(ngModel)]="newUser.phoneNumber" class="input" maxlength=11>
        
            </div>           
            <div class="text">
                <span >密 码</span>
                <input type="password" placeholder="请输入密码" id="password"  [(ngModel)]="newUser.password" class="input" maxlength=16>
            </div>
            <div class="tip"><a href="#" routerLink="/forgetPassword" routerLinkActive="active" >忘記密碼</a></div>
            <div class="tip"><a routerLink="/register" routerLinkActive='active'>没有注册</a></div>

            <div class="btndl"><input type="submit" value="登 錄"  (click)="doSubmit()" ></div>
        </div>
         