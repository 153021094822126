import { Component, OnInit } from '@angular/core';
import { user } from '../../../stage/user';
import { AuthenticationService } from '../../../services/authentication.service';
import { ValidationService } from '../../../services/validation.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  public newUser:user=new user();

  constructor(public authenticationService:AuthenticationService,public validationService:ValidationService,public router:Router) { }

  ngOnInit(): void {
  
  
  }
  
  
  doSubmit(){


    if(!this.validationCheck()){
     return;

    }

    
    this.authenticationService.forgotPassword(this.newUser).subscribe(
      dataJson => {

        const response = JSON.parse(JSON.stringify((<any>dataJson) ))

        if(response.code!="000"){
          
          this.validationService.errorMessage=response.codeMessage; 
          return;
        }

        this.router.navigate(['/index']);
        //返回结果处
        },
        error => {
   
          this.validationService.showSysErroMessage();

          return;
        }
    );
    }

      validationCheck(){

        if(!this.validationService.passwordCheck(this.newUser.password,this.newUser.passwordAgain)){
          return false;
        }
        
        if(!this.validationService.phoneNumberCheck(this.newUser.phoneNumber)){
        
          return false;
          }
        if(!this.validationService.IdNmberCheck(this.newUser.idNumber)){
        
          return false;
        
        }
        return true; 
        }
        back(){ history.go(-1);}
}
