 <h1 >病情建議參考</h1>

<div >



    <ul *ngIf="analyseResult == '1' ">
        <li >1.恶性风险增加，.建议胆囊切除术</li>
        <li >2.无法手术的，根据下面提示超声随访</li>
        <li >超声随访:<ul>             
            <li >第一次：6個月</li>
            <li >第二次：1年</li>
            <li >第三次：2年</li>
            <li >第四次：3年</li>
            <li >第五次：4年</li>
            <li >第六次：5年</li>
        </ul></li> 
    </ul>
         
    <ul *ngIf="analyseResult == '2' ">
        <li >1.建议门诊就诊</li>
        <li >2.无法手术的，根据下面提示超声随访</li>
        <li >超声随访:<ul>             
            <li >第一次：6個月</li>
            <li >第二次：1年</li>
            <li >第三次：2年</li>
            <li >第四次：3年</li>
            <li >第五次：4年</li>
            <li >第六次：5年</li>
        </ul></li> 
    </ul>

    <ul *ngIf="analyseResult == '3' ">
        <li >1.根据下面提示超声随访</li>
        <li >超声随访:<ul>             
            <li >第一次：6個月</li>
            <li >第二次：1年</li>
            <li >第三次：2年</li>
            <li >第四次：3年</li>
            <li >第五次：4年</li>
            <li >第六次：5年</li>
        </ul></li> 
    </ul>
     

  
    <ul *ngIf="analyseResult == '4' ">
        <li >1.下面提示超声随访</li>
        <li >超声随访:<ul>             
            <li >第一次：1年</li>
            <li >第二次：3年</li>
            <li >第三次：5年</li>
        </ul></li> 
    </ul>
  
    <ul >
        <li class="red">注：以上建議僅供參考，不承擔何法律責任</li>
    </ul>
       
       <div>
    <div class="next"><input type="submit" (click)="yes()" value="確 定"></div>
</div>
</div>

  
