import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http'
import { Observable } from 'rxjs';
import { personalInfo } from '../stage/personalInfo';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PersonalInfoService {

  public apiUrl:String = environment.apiUrl

  constructor(public httpClient:HttpClient) { }


  selectPersonalInfo(userId:string,token:string): Observable<{}>{

    
    const httpOptions ={headers: new HttpHeaders({'Content-Type':'application/json','token':token}) } 
    return this.httpClient.post(this.apiUrl+"selectPersonalInfo",userId,httpOptions)

  }

  changePersonalInfo(personalInfo:personalInfo,token:string): Observable<{}>{


    const httpOptions ={headers: new HttpHeaders({'Content-Type':'application/json','token':token}) } 
    return this.httpClient.post(this.apiUrl+"changePersonalInfo",personalInfo,httpOptions)

  }

}
