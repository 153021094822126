import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'gallbladderanalysis';
  public isShowFoot:String=""
public isAside:boolean=true; 

  showAside(){

    //原生js获取dom节点


    var asideDom:any=document.getElementById('aside');

    asideDom.style.transform="translate(0,0)";

    this.isAside=false;

 }

hideAside(){

   //原生js获取dom节点

   var asideDom:any=document.getElementById('aside');

   asideDom.style.transform="translate(100%,0)";

}

}

