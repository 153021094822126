
  <div class="main">
    <div class="nav">
       <div class="a" >
        <a (click)="showAside()">
        <span class="span" id="bg1">导航</span>
        </a>
       </div> 
    </div>
    </div>
    



  <aside id="aside">
  
  

    <a (click)="closeAside()">
        关闭导航栏
    </a>


    <div class="zhuce">
       
        <div class="text">
            <a routerLink="/personalInfo" routerLinkActive='active'>
            我的资料
            </a>
        </div>

        <div class="text">
            <a routerLink="/bodyInfo" routerLinkActive='active'>
            身体情况
            </a>
        </div>
        <div class="text">
           <a routerLink="/analyseHistory" routerLinkActive='active'>
            我的分析
            </a>
        </div>

        <div class="text">
            <a (click)="closeLogin()">
            退出登录
            </a>
        </div>

        <div class="text">
            关于我们
        </div>
    </div>
   
  </aside>