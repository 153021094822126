import { Component, OnInit } from '@angular/core';
import { polypInformation} from '../../../stage/polypInformation';
import { laboratoryExamination} from '../../../stage/laboratoryExamination';
import {Router,ActivatedRoute } from '@angular/router';
import { AnalyseService } from '../../../services/analyse.service';
import { SessionService } from '../../../services/session.service';
import { MatDialog } from '@angular/material/dialog';
import { ResultMessageComponent } from '../../../resultMessage/resultMessage.component';
import { LoadingSerProvider } from '../../../../common/loading/loadingSerProvider'
import { ValidationService } from '../../../services/validation.service';
@Component({
  selector: 'app-history-information',
  templateUrl: './history-information.component.html',
  styleUrls: ['./history-information.component.scss']
})
export class HistoryInformationComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    public activatedRoute:ActivatedRoute,
    public router:Router,
    public analyseService:AnalyseService,
    public sessionService:SessionService,
    private loadingSerProvider:LoadingSerProvider,
    private validationService:ValidationService) { 
  }


  public newPolypInformation:polypInformation=new polypInformation();
  public newLaboratoryExamination:laboratoryExamination=new laboratoryExamination();
  public result:string="";


  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe((data)=>{

      const user=this.sessionService.getSession("user");
      this.loadingSerProvider.show("请稍等");
      this.analyseService.getAnalyseInfoById(data.analyseHistoryId,user.token).toPromise().then(dataJson=>{
      

          const response = JSON.parse(JSON.stringify((<any>dataJson) ))

          this.newPolypInformation= response.data.polypInformation;
          this.newLaboratoryExamination= response.data.laboratoryExamination;


          this.result=response.data.analyseResult

          this.loadingSerProvider.hide()
          // 返回结果处
        }).catch(err =>{
          this.loadingSerProvider.hide()
          this.validationService.showSysErroMessage();
          return;
        })
    })

  }
      next(){
      

        let dialogRef =this.dialog.open(ResultMessageComponent,{ data: {
          analyseResult: this.result
        
          }});
        
         dialogRef.afterClosed().subscribe(result => {
          this.router.navigate(['/analyseHistory']); 
        }); 

  }


  back(){ history.go(-1);}

}