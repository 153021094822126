<div class="head">
    <span class="head_lf"><a href="index.html">返回</a></span>
    
    用户注册
    
    <span class="head_rg"><a routerLink="/login" routerLinkActive='active'>登录</a></span>
    </div>
    
    <div class="zhuce">
        <div class="text">
            <span class="span">姓名</span>
            <input type="text" placeholder="请输入姓名" id="name"  [(ngModel)]="newUser.name" class="input" maxlength=10>
        </div>
        <div class="text">
            <span class="span">手机号</span>
            <input type="text" placeholder="请输入手机号" id="phoneNumber"  [(ngModel)]="newUser.phoneNumber" class="input" maxlength=11>
            <!-- <span class="yzm"><input type="button" value="获取验证码"></span> -->
        </div>
        <div class="text">
            <span class="span">身份证</span>
            <input type="text" placeholder="请输入身份证" id="idNumber"  [(ngModel)]="newUser.idNumber" class="input" maxlength=18>
        </div>
        <div class="text">
            <span>密 码</span>
            <input type="password" placeholder="请输入小于6位密码" id="password"  [(ngModel)]="newUser.password" class="input" maxlength=16>
        </div>
        <div class="text">
            <span>确认密码</span>
            <input type="password" placeholder="请输入确认密码" id="passwordAgain"  [(ngModel)]="newUser.passwordAgain" class="input" maxlength=16>
        </div>
        <div class="text">
            <span><a routerLink="/disclaimer" routerLinkActive='active' class='backNav'>免责声明</a></span>
            <select  class="select" [(ngModel)]="checkDisclaimerFlg" >
                <option value="1" >同意</option>
                <option value="2">不同意</option>
            </select> 
        </div>
        <div class="text">
            <span><a routerLink="/privateInfo" routerLinkActive='active' class='backNav'>隐私声明</a></span>
            <select class="select" [(ngModel)]="checkPrivateInfoFlg" >
                <option value="1" >同意</option>
                <option value="2">不同意</option>
            </select> 
        </div>


        <div class="btndl"><input type="submit" id="okbutton" value="注 册"  (click)="doSubmit()" ></div>
    </div>




    