<div class="head">
    <span class="head_lf"></span>
    
    导航
    
    <span class="head_rg"></span>
    </div>
    
    <div class="zhuce">
        <!--2023年1月22日注释----------------------->
        <!-- <a href="javascript:;" (click)="login()"> 
            <div class="text">
               <h4>{{name}}</h4> 
            </div>
        </a> -->



        <!-- <a routerLink="/personalInfo" routerLinkActive='active'>     
            <div class="text"> -->
               <!-- <span class='indexSpan'>          -->
                    <!-- <img src="assets/svg/accessibility-sharp.svg">    -->
                    <!-- <span>  我的资料</span>
            </div>
        </a> -->

    <!-- <a routerLink="/bodyInfo" routerLinkActive='active'> 
        <div class="text">
            <span>身体状况</span>
        </div>
    </a> -->
    <!--2023年1月22日注释----------------------->
    

    <!--2023年1月22日注释----------------------->
    <!-- <a routerLink="/analyseHistory" routerLinkActive='active'> 
        <div class="text">
             <span>我的分析</span>
        </div>
    </a> -->
    <!--2023年1月22日注释----------------------->
    
    <!--2023年1月22日注释----------------------->
    <a routerLink="/polypInformation" routerLinkActive='active'> 
        <div class="text">
             <span>分析病情</span>
           
        </div>
    </a>
    <!--2023年1月22日注释----------------------->
    
    
    
    
    <!--2023年1月22日注释----------------------->
    <!-- <a href="javascript:;" (click)="presentConfirm()" > 
        <div class="text" *ngIf="user!=null">
            <span>退出登录</span>
        </div>
    </a> -->
    <!--2023年1月22日注释----------------------->
    <a routerLink="/" routerLinkActive='active' > 
        <div class="text">
            <span>关于我们</span>
        </div>
    </a>
    <a routerLink="/disclaimer" routerLinkActive='active'> 
        <div class="text">
            <span>关于免责</span>
        </div>
    </a>
    <a routerLink="/privateInfo" routerLinkActive='active'> 
        <div class="text">
            <span>关于隐私</span>
        </div>
    </a>
    </div>