import { Component,OnInit } from '@angular/core';
import { user } from '../../../stage/user';
import { AuthenticationService } from '../../../services/authentication.service';
import { ValidationService } from '../../../services/validation.service';
import { Router,ActivatedRoute } from '@angular/router';
import { SessionService } from '../../../services/session.service';
import { WxService } from '../../../services/wx.service';
// import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { LoadingSerProvider } from '../../../../common/loading/loadingSerProvider'
import { MatDialog } from '@angular/material/dialog';

declare var wx: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent  implements OnInit {

  public newUser:user=new user();

 public code:any=null;

  constructor(
    public matDialog:MatDialog,
    public loadingSerProvider:LoadingSerProvider,
    public WxService :WxService,
    public authenticationService:AuthenticationService,
    public sessionService:SessionService,
    public validationService:ValidationService,
    public route:Router,  
    public activatedRoute:ActivatedRoute,
  
  ) {  }

  ngOnInit(): void {

    
   
  //   if (wx && wx.miniProgram) {
  //   this.activatedRoute.queryParams.subscribe((data)=>{

  //     this.code=data.code;
  //   })


  // }
    // let token =this.sessionService.get("token");
    // console.log("ngOnInit    "+token);

    // if(token!=""){
 

    //   this.authenticationService.loginByToken(this.newUser,token).subscribe(
    //     dataJson => {
  
    //       const data = (<any>dataJson)
  
    //       this.sessionService.put("userId",data.user.userId)
  
          
    //       this.route.navigate(['/personalInfo']);
  
    //       //返回结果处
    //       },
    //       error => {
    //         console.log(1321321); 
    //       }
    // );

    // }

  
    
  }

  doSubmit(){


    if(!this.validationCheck()){
      return; 
 
     }
 

  //    if (wx && wx.miniProgram) {

  //     this.WxService.getWXConfigSignature().toPromise().then(dataJson=>{
      
  //       const response = JSON.parse(JSON.stringify((<any>dataJson) ))

  //       if(response.code!="000"){
            
  //         this.validationService.errorMessage=response.codeMessage; 
  //         return;
  //       }
  
  //       this.WxService.getSign(response.data).toPromise().then(dataJson=>{
      
  //         const response = JSON.parse(JSON.stringify((<any>dataJson) ))        

  //       }).catch(err =>{
        
  //     this.validationService.showSysErroMessage();

  //     return;
  //       })
  
  //     }).catch(err =>{
       
  //     this.validationService.showSysErroMessage();

  //     return;
  //     })
  //  }




 

     this.authenticationService.login(this.newUser).toPromise().then(dataJson=>{
      
      const response = JSON.parse(JSON.stringify((<any>dataJson) ))

      if(response.code!="000"){
        
        this.validationService.showMessage("用户名不存在或者密码不对")
        return;
      }
      this.sessionService.putSession("user",response.data)
      
     // this.sessionService.putSession("token",data.user.token)

      
      this.route.navigate(['/index']);


    }).catch(err =>{

      this.validationService.showSysErroMessage();
    })







      }

      validationCheck(){

        if(!this.validationService.loginPasswordCheck(this.newUser.password)){
          return false;
        }
        
        if(!this.validationService.phoneNumberCheck(this.newUser.phoneNumber)){
        
          return false;
          }
        
        return true; 
        }
        
}
