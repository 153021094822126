<div class="head">

    息肉相关情况

    </div>
    
    <div class="zhuce">

        <div class="text">
            <span>性别<small>*</small></span>
            <select class="input" [(ngModel)]="newPolypInformation.sexFlg" >
                <option value ="">请输入</option>
                <option value="1">男</option>
                <option value="2">女</option>
              </select>
        </div>


        <div class="text">
            <span>年龄<small>*</small></span>
            <input type="text" placeholder="请输入年龄"   [(ngModel)]="newPolypInformation.age" class="input" maxlength=3>
        </div>

        <div class="text">
            <span>初次发现息肉大小</span>
            <input type="text"    class="input"  [(ngModel)]="newPolypInformation.polypFirst" placeholder="请输入(mm)"  maxlength="5"/>
        </div>
        
        <div class="text">
            <span>目前息肉大小(来源超声)<small>*</small></span>
            <input type="text"      class="input" [(ngModel)]="newPolypInformation.polypNow" placeholder="请输入(mm)"  maxlength="5"/>
        </div>
        <div class="text">
            <span>是否明确息肉为假性息肉 </span>
            <select class="select" [(ngModel)]="newPolypInformation.isFakePolypFlg" >
                <option value="">请选择</option>
                <option value="1">是</option>
                <option value="2">否</option>
                </select>   
            <span>伴随症状 </span>
            <select class="select" [(ngModel)]="newPolypInformation.concomitantSymptoms" >
                <option value="">请选择</option>
                <option value="1">慧尾征</option>
                <option value="2">回声</option>
                <option value="3">以上都有</option>
                </select>       
        </div>
        <div class="text">
            <span>息肉数目<small>*</small></span>
            <select class="select" [(ngModel)]="newPolypInformation.polypCount">
                <option value="">请选择</option>
                <option value="1">单发</option>
                <option value="2">多发</option>
                </select>  
        </div>
        <div class="text">
            <span>位置</span>
            <select class="select" [(ngModel)]="newPolypInformation.position" >
                <option value="">请选择</option>
                <option value="1">胆囊底</option>
                <option value="2">胆囊体</option>
                <option value="3">胆囊颈</option>
                <option value="4">不详</option>
                </select> 
        </div>

        <div class="text">
            <span>附着面</span>
            <select class="select" [(ngModel)]="newPolypInformation.attachmentSurface">
            <option value="">请选择</option>
            <option value="1">肝脏面</option>
            <option value="2">游离面</option>
            <option value="3">不详</option>
            </select> 
        </div>
        <div class="text">
            <span>基底部<small>*</small> </span>
            <select class="select" [(ngModel)]="newPolypInformation.basalPart">
                <option value="">请选择</option>
                <option value="1">无蒂息肉(局灶性胆囊壁增厚＞4mm)</option>
                <option value="2">有蒂息肉</option>
                <option value="3">不详</option>
                </select> 

        </div>
        <div class="text">
            <span>胆囊壁</span>
            <select class="select" [(ngModel)]="newPolypInformation.gallbladderWall">
                <option value="">请选择</option>
                <option value="1">光滑</option>
                <option value="2">毛糙</option>
                <option value="2">以上两者都有</option>
                </select>  
        </div>
        <div class="text">
            <span>是否合并胆囊结石<small>*</small></span>
            <select class="select" [(ngModel)]="newPolypInformation.gallstone" (change)="gallstoneDivSelect()">
                <option value="">请选择</option>
                <option value="1">是</option>
                <option value="2">否</option>
                </select>  
            <div id="gallstoneDiv">
            <span>胆囊结石(最大径)(mm)</span>
            <input type="text" class="input" placeholder="请输入(mm)" [(ngModel)]="newPolypInformation.size" maxlength="5"/>
            <span>发现胆囊结石时间</span>
            <input type="date"  class="input" [(ngModel)]="newPolypInformation.gallstoneTime">
            </div>
        </div>


        <div class="text">
            <span>合并原发性硬化性胆管炎<small>*</small></span>
            <select class="select" [(ngModel)]="newPolypInformation.primarySclerosingCholangitis">
                <option value="">请选择</option>
                <option value="1">是</option>
                <option value="2">否</option>
                </select>  
        </div>
        <div class="text">
            <span>是否有過急性胆囊炎<small>*</small></span>
            <select class="select" [(ngModel)]="newPolypInformation.isAcuteCholecystitis">
                <option value="">请选择</option>
                <option value="1">是</option>
                <option value="2">否</option>
                </select>  
        </div>

        <div class="text">
            <span>目前合并黄疸 </span>
            <select class="select" [(ngModel)]="newPolypInformation.isJaundice">
                <option value="">请选择</option>
                <option value="1">是</option>
                <option value="2">否</option>
                </select>  
        </div>

        <div class="text">
            <span>是否合并脂肪肝</span>
            <select class="select" [(ngModel)]="newPolypInformation.isFattyIiver">
                <option value="">请选择</option>
                <option value="1">是</option>
                <option value="2">否</option>
                </select>  
        </div> 

        <div class="text">
            <span>是否合并胆道结石</span>
            <select class="select" [(ngModel)]="newPolypInformation.isCholelithiasis">
                <option value="">请选择</option>
                <option value="1">是</option>
                <option value="2">否</option>
                </select>  
        </div> 
        <div class="text">
            <span>是否合并胰腺异常    </span>
            <select class="select" [(ngModel)]="newPolypInformation.isPancreaticAbnormalities">
                <option value="">请选择</option>
                <option value="1">是</option>
                <option value="2">否</option>
                </select>  
        </div> 



        <div class="text1">

        <div class="btndl">
        <input type="submit" value="下 一 页" (click)="next()">
        <input type="submit" value="返 回" (click)="back()" >
        </div>

    </div>
    </div>