import { Component,OnInit } from '@angular/core';
import { user } from '../../../stage/user';
import { AuthenticationService } from '../../../services/authentication.service';
import { ValidationService } from '../../../services/validation.service';
import { Router, ɵangular_packages_router_router_n } from '@angular/router';
import { AlterSerProvider } from '../../../../common/alter/alterSerProvider'
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public newUser:user=new user();
  
  public privateInfocheckFlg:Boolean=true
  
public checkDisclaimerFlg:any="1";

  
public checkPrivateInfoFlg:any="1";

  constructor(
    public authenticationService:AuthenticationService,
    public validationService:ValidationService,
    public route:Router,
    private alterSerProvider:AlterSerProvider ) { 
  }

  ngOnInit(): void {

  }


  doSubmit(){




  if(this.checkDisclaimerFlg!="1"){
    this.alterSerProvider.ErropresentConfirm("错误","请同意免责声明才能注册","确定")
    return;

  }

  if(this.checkPrivateInfoFlg!="1"){
    this.alterSerProvider.ErropresentConfirm("错误","请同意免责声明才能注册","确定")
    return;
  }



    if(!this.validationCheck()){
     return;

    }

    
    this.authenticationService.register(this.newUser).subscribe(
      dataJson => {

        const response = JSON.parse(JSON.stringify((<any>dataJson) ))
      
        if(response.code!="000"){
        
          this.validationService.showMessage("注册失败")
          return;
        }

        
        this.validationService.showMessage("注册成功")

        this.route.navigate(['/login']);

        //返回结果处
        },
        error => {
         
      this.validationService.showSysErroMessage();

        }
  );
  }

validationCheck(){


if(!this.validationService.phoneNumberCheck(this.newUser.phoneNumber)){

  return false;
}

if(!this.validationService.passwordCheck(this.newUser.password,this.newUser.passwordAgain)){
    return false;
}

if(!this.validationService.IdNmberCheck(this.newUser.idNumber)){

  return false;

}
return true; 
}



}
