import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http'
import { Observable } from 'rxjs';
import { bodyInfo } from '../stage/bodyInfo';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BodyInfoService {


  public apiUrl:String = environment.apiUrl

  //public apiUrl:String = "http://localhost:8080/"
  constructor(public httpClient:HttpClient) { }


  selectBodyInfo(userId:String,token:String): Observable<{}>{


    const httpOptions ={headers: new HttpHeaders({'Content-Type':'application/json','token':''+token}) } 
    return this.httpClient.post(this.apiUrl+"selectBodyInfo",userId,httpOptions)

  }

  changeBodyInfo(bodyInfo:bodyInfo,token:String): Observable<{}>{


    const httpOptions ={headers: new HttpHeaders({'Content-Type':'application/json','token':''+token}) } 
    return this.httpClient.post(this.apiUrl+"changeBodyInfo",bodyInfo,httpOptions)

  }

}
