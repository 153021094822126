import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { MessageComponent } from '.././message/message.component';
import { MatDialog } from '@angular/material/dialog';
@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor(
    public commonService:CommonService,
    public dialog:MatDialog
    ) { }

  public errorMessage:string="";

  public SysError:string="";

  public header:string="错误";

  public button:string="确认";


openDialog(message:string){

  let dialogRef =this.dialog.open(MessageComponent,{ data: {
    message: ['     '+message+'     ']
    }});

}



  showMessage(message:string){
    this.openDialog(message);
    }

  // 系统异常
  showSysErroMessage(){
      this.SysError="系统异常请稍后重试;"
      this.openDialog( this.SysError);
      }

  
  // 电话号码校验
  phoneNumberCheck(phoneNumber:string){

//let number = '^1[3|4|5|7|8][0-9]{9}$';
if(!(/^1\d{10}$/.test(phoneNumber))){ 

  
  this.errorMessage="手机号码有误，请重填;"
  this.openDialog(this.errorMessage);
  return false; 
  } 
  return true; 
  }

  // 身份校验
  IdNmberCheck(idNmber:string){

  if(idNmber.length==15){

if(!/^[1-9][0-9]{5}19[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}[0-9Xx]$/.test(idNmber)){
  this.errorMessage="15位身份證，请重填"; 
  this.openDialog(this.errorMessage);
  return false; 
}

  }else if(idNmber.length==18){


if(!/^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}[0-9Xx]$/.test(idNmber)){

  this.errorMessage="18位身份證，请重填"; 
  this.openDialog(this.errorMessage);
  return false; 
}
  }else{
this.errorMessage="請輸入身份證15或者18，请重填"; 
this.openDialog(this.errorMessage);
return false; 

  }

  return true
}



  // 密码校验
  loginPasswordCheck(password1:string){

  if(password1== ""){
    
    this.errorMessage="请输入的密码！"; 
    this.openDialog(this.errorMessage);
    return false; 
    
  } else if(password1.length < 6  ){
    
    this.errorMessage="密码长度不能小于6位"; 
    this.openDialog(this.errorMessage);
    return false; 
    
  } 

return true; 
  
}

  // 密码校验
  passwordCheck(password1:string,password2:string){

  if(password1== ""){

    this.errorMessage="请输入的密码！"; 
    this.openDialog(this.errorMessage);
    return false; 

  } else if(password2 == ""){

    this.errorMessage="请再次重复确认的的密码！"; 
    this.openDialog(this.errorMessage);
    return false; 

  } else if(password1.length < 6 || password2.length < 6 ){

    this.errorMessage="密码长度不能小于6位"; 
    this.openDialog(this.errorMessage);
    return false; 

  } else if(password1 != password2){

    this.errorMessage="两次输入的密码不一致！"; 
    this.openDialog(this.errorMessage);
    return false; 
  }

  return true; 

  }
  // dateCheck
  dateCheck(inputName:string,inputValue:string){

    
    const analyseDate:string =this.commonService.getCurrentDate()
    if(inputValue!=""&&new Date(inputValue)>new Date()){
      
    this.errorMessage="("+inputName+")不晚於當前時間！"; 
    this.openDialog(this.errorMessage);
    return false; 
    }
    

    return true; 

} 

  
  // 下拉框必须入力
  selectMustCheck(selectName:string,selectValue:string){

    if(selectValue== ""){
      
    this.errorMessage="("+selectName+")请選擇！"; 
    this.openDialog(this.errorMessage);
    return false; 
    }

    return true; 

} 

  // 输入框必须入力
  inputMustCheck(selectName:string,selectValue:string){

    if(selectValue== ""){
      
    this.errorMessage="("+selectName+")请選擇！"; 
    this.openDialog(this.errorMessage);
    return false; 
    }

    return true; 

} 



   // 输入框必须入力+必须输入数字
inputCheck(inputName:string,inputValue:string){

  if(inputValue== ""){
  
    this.errorMessage="请输入("+inputName+")"; 
    this.openDialog(this.errorMessage);
    return false; 

  } else if(!/^[0-9]*$/.test(inputValue)){

    this.errorMessage="("+inputName+")请输入的数字！"; 
    this.openDialog(this.errorMessage);
    return false; 
  }

  return true; 
  
  } 

 // 输入框必须输入数字
 inputNumberCheck(inputName:string,inputValue:string){

  if(inputValue!= ""&&!/^[0-9]*$/.test(inputValue)){

    this.errorMessage="("+inputName+")请输入的数字！"; 
    this.openDialog(this.errorMessage);
    return false; 
}

    return true; 

}  




 // 输入框必须输入数字或者點
 inputNumberAndPointCheck(inputName:string,inputValue:string){

  if(inputValue!= ""&&!/^(\-)?\d+(\.\d{1})*[0-9]*$/.test(inputValue)){

    this.errorMessage="("+inputName+")请输入的数字！"; 
    this.openDialog(this.errorMessage);
    return false; 
}


    return true; 

}  

   // 输入框必须入力+必须输入数字或者點
   inputNumberAndPointMustCheck(inputName:string,inputValue:string){

    if(inputValue== ""){
    
      this.errorMessage="请输入("+inputName+")"; 
      this.openDialog(this.errorMessage);
      return false; 
  
    } else if(!/^(\-)?\d+(\.\d{1})*[0-9]*$/.test(inputValue)){
  
      this.errorMessage="("+inputName+")请输入的数字！"; 
      this.openDialog(this.errorMessage);
      return false; 
    }

    return true; 
    
    } 
  
}
