import { Injectable } from "@angular/core";
import { PersonalInfoService } from './personalInfo.service';
@Injectable()
export class BusinessCheckService {
 

 public personalInfo:any=null;
  constructor(public personalInfoService:PersonalInfoService) {}
 
personInfoCheck(userId:string,token:string){

  let isCheck =false;

  this.personalInfoService.selectPersonalInfo(userId,token).subscribe(
    dataJson => {

      const data = (<any>dataJson)

      this.personalInfo = data.personalInfo;
      

      if( this.personalInfo){
        
        if( this.personalInfo.sexFlg || this.personalInfo.sexFlg==""){
  
          isCheck=true; 
        }
        
        if( this.personalInfo.age || this.personalInfo.age!=""){
        
          isCheck=true;  
  
      }
  
      }else{
  
        isCheck=true; 
  
      }


      },
      error => {
 
       }
       
    );


    return isCheck;
  
}
}
