export class polypInformation{
  analyseHistoryId:string='';
  polypInformationId:string='';
  
  age:string='';
  sexFlg:string='';

  polypFirst:string='';//初次发现息肉大小
  polypNow:string='';//目前息肉大小
  isFakePolypFlg:string='';//是否明确息肉为假性息肉
  concomitantSymptoms:string='';//伴随症状
  relatedSymptoms:string='';//胆囊息肉伴随存在不能用其他原因解释的相关症状
  polypCount:string='';//息肉数目
  position:string='';//位置
  attachmentSurface:string='';//附着面
  basalPart:string='';//基底部
  gallbladderWall:string='';//胆囊壁（多选）
  gallstone:string='';//是否合并胆囊结石
  size:string='';//（最大径）（mm）
  gallstoneTime:string='';//发现胆囊结石时间
  primarySclerosingCholangitis:string='';//合并原发性硬化性胆管炎
  cholecystitis:string='';//胆囊炎：至少发作一次
  isAcuteCholecystitis:string='';//是否有過急性胆囊炎
  isJaundice:string='';//目前合并黄疸
  isFattyIiver:string='';//是否合并脂肪肝
  isCholelithiasis:string='';//是否合并胆道结石
  isPancreaticAbnormalities:string='';//是否合并胰腺异常
}