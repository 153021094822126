
    <div class="head">
    
        
        管理员登录
        
        
        </div>
        
        <div class="zhuce">
            <div class="text">
                <span >管理员号</span>
                <input type="text" placeholder="请输入管理员号" id="phoneNumber"   class="input" maxlength=11>
        
            </div>           
            <div class="text">
                <span >密 码</span>
                <input type="password" placeholder="请输入密码" id="password"   class="input" maxlength=16>
            </div>
            <div class="btndl"><input type="submit" value="登 錄"  (click)="doSubmit()" ></div>
        </div>
         