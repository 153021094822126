import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { AdminService } from '../../services/admin.service';
import { patientInfo } from '../../stage/patientInfo';

//npm install xlsx
 
//npm install @types/xlsx
@Component({
  selector: 'app-admin-importPatientInfo',
  templateUrl: './importPatientInfo.component.html',
  styleUrls: ['./importPatientInfo.component.scss']
})
export class ImportPatientInfoComponent implements OnInit {

  constructor(private adminService: AdminService) { }

  private data:any;//存放Excel中的数据

  private patientInfoList:any;

  private newpatientInfo:any;

  private rowName: string[] = [ 
    "日期",
    "姓名",
    "性别",
    "年龄",
    "入院日期",
    "联系方式",
    "身份证",
    "住院号",
    "病理号",
    "临床诊断",
    "术中冰冻",
    "病理诊断",
    "特殊检查",
    "肉眼所见",
    "身高",
    "体重",
    "高血压",
    "糖尿病",
    "高血脂",
    "慢病",
    "临床诊断",
    "CA",
    "CAper",
    "DCA",
    "DCAper",
    "CDCA",
    "CDCA per",
    "UDCA/HDCA",
    "UDCA per",
    "LCA",
    "LCA per",
    "GCA",
    "GCA per",
    "GLCA",
    "GLCA per",
    "GDCA",
    "GDCA per",
    "GCDCA",
    "GCDCA per",
    "GUDCA",
    "GUDCA per",
    "TCA",
    "TCA per",
    "TLCA",
    "TLCA per",
    "TDCA",
    "TDCA per",
    "TCDCA",
    "TCDCA per",
    "TUDCA",
    "TUDCA per",
    "15种胆汁酸",
    "国际标准化比率",
    "凝血酶原时间",
    "凝血酶时间",
    "纤维蛋白原",
    "部分凝血活酶时间",
    "ABO血型",
    "有核红细胞百分比",
    "有核红细胞绝对值",
    "嗜碱性粒细胞绝对值",
    "嗜酸性粒细胞绝对值",
    "单核细胞绝对值",
    "红细胞压积",
    "嗜碱性粒细胞百分比",
    "平均血小板体积",
    "血小板分布宽度",
    "红细胞分布宽度CV",
    "血红蛋白",
    "淋巴细胞绝对值",
    "血小板比积",
    "嗜酸性粒细胞百分比",
    "血小板计数",
    "大血小板比率",
    "平均血红蛋白量",
    "嗜中性粒细胞绝对值",
    "平均血红蛋白浓度",
    "淋巴细胞百分比",
    "红细胞分布宽度SD",
    "红细胞计数",
    "白细胞计数",
    "嗜中性粒细胞百分比",
    "单核细胞百分比",
    "平均红细胞体积",
    "血清游离脂肪酸(NEFA)",
    "小而密低密度脂蛋白(sd-LDL)",
    "甘油三脂(TG)",
    "载脂蛋白B(APOB)",
    "胱抑素C(CysC)",
    "甘胆酸(CG)",
    "载脂蛋白A1(APOA)",
    "高密度脂蛋白胆固醇(HDL)",
    "白球比例(A:G)",
    "低密度脂蛋白胆固醇(LDL)",
    "氯(CL)",
    "天门冬氨酸氨基转移酶(AST)",
    "eGFR-MDRD(eGFR(MDRD))",
    "eGFR-EPI Cr",
    "丙氨酸氨基转移酶(ALT)",
    "钠(NA)",
    "岩藻糖苷酶(AFU)",
    "乳酸脱氢酶(LDH)",
    "脂蛋白(a)(LPa)",
    "非高密度脂蛋白胆固醇(NHDL)",
    "直接胆红素(DBIL)",
    "谷氨酸脱氢酶(GLDH)",
    "尿酸(URIC)",
    "前白蛋白(PA)",
    "球蛋白(GLB)",
    "载脂蛋白E(APOE)",
    "总胆固醇(CHOL)",
    "尿素氮(BUN)",
    "纤维连接蛋白(FN)",
    "谷氨酰转肽酶(GGT)",
    "钾(K)",
    "白蛋白(ALB)",
    "总胆红素(TBIL)",
    "空腹血糖(GLU)",
    "总胆汁酸(TBA)",
    "碱性磷酸酶(ALP)",
    "肌酐(CREA)",
    "总蛋白(TP)",
    "癌胚抗原(CEA)",
    "糖类抗原(CA724)",
    "糖类抗原(CA125)",
    "甲胎蛋白(AFP)",
    "糖类抗原(CA19-9)",
    "糖类抗原CA50",
    "糖类抗原242",
    "石胆酸",
    "牛磺石胆酸",
    "甘氨石胆酸",
    "熊脱氧胆酸",
    "胆酸",
    "谷氨酸",
    "鹅脱氧胆酸",
    "鸟氨酸",
    "牛磺鹅脱氧胆酸",
    "甘氨鹅脱氧胆酸",
    "脱氧胆酸",
    "亮氨酸",
    "脯氨酸",
    "缬氨酸",
    "甘氨脱氧胆酸",
    "甘氨熊脱氧胆酸",
    "牛磺脱氧胆酸",
    "丙氨酸",
    "瓜氨酸",
    "甘氨酸",
    "牛磺胆酸",
    "甘氨胆酸",
    "牛磺熊脱氧胆酸",
    "酪氨酸",
    "同型半胱氨酸(质谱)",
    "精氨酸",
    "淋巴细胞绝对值",
    "CD4/CD8比值",
    "T淋巴细胞绝对值",
    "B淋巴细胞绝对值",
    "自然杀伤细胞CD56+CD16",
    "Ts淋巴细胞  CD8",
    "自然杀伤细胞绝对值",
    "Ts淋巴细胞绝对值",
    "Th淋巴细胞CD4",
    "T淋巴细胞CD3",
    "Th淋巴细胞绝对值",
    "B淋巴细胞CD19",
    "乙肝表面抗原(YP)",
    "乙肝表面抗体(YP)",
    "HIV Ag/Ab",
    "丙肝抗体",
    "乙肝核心抗体(YP)",
    "乙肝e抗原(YP)",
    "乙肝e抗体(YP)",
    "初次发现息肉大小",
    "目前息肉大小",
    "是否明确息肉为假性息肉 ",
    "胆囊息肉伴随存在不能用其他原因解释的相关症状 ",
    "息肉数目 ",
    "位置 ",
    "附着面 ",
    "基底部 ",
    "胆囊壁（多选）  ",
    "是否合并胆囊结石  ",
    "发现胆囊结石时间",
    "合并原发性硬化性胆管炎",
    "胆囊炎：至少发作一次 ",
    "目前是否合并急性胆囊炎 ",
    "目前合并黄疸 ",
    "是否合并脂肪肝 ",
    "是否合并胆道结石 ",
    "是否合并胰腺异常 "]
    
 

  ngOnInit(): void {
  }



  importData(patientInfo:Array<patientInfo>) {
    this.adminService.importPatientInfo(patientInfo,'123').toPromise().then(dataJson=>{
    

      const response = JSON.parse(JSON.stringify((<any>dataJson) ))

      console.log(response);

      const message = response.code;

      alert(message);
    
    }).catch(err =>{
     

    })


  }


  updateAbC($event:any) {

    
    const target: DataTransfer = ($event.target) as DataTransfer;
    if (target.files.length !== 0) {
      if (target.files.length !== 1) { throw new Error('Cannot use multiple files'); }
    }
 
    const reader: FileReader = new FileReader();
 
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];


      const sheet2JSONOpts = {
        header: 1,
        /** Default value for null/undefined values */
        defval: ''//给defval赋值为空的字符串
}

      const results = XLSX.utils.sheet_to_json(ws, sheet2JSONOpts)

      // this.data =JSON.stringify(results);
      // console.log(results);


   this.data=JSON.parse(JSON.stringify((<any>results) ))
 // console.log(this.data);
   
// this.data =JSON.stringify(XLSX.utils.sheet_to_json(ws));
//       console.log(this.data);
    };
    reader.readAsBinaryString(target.files[0]);
   
}
doSubmit(){
this.excelCheck();
this.getDataFromExcel();
this.importData(this.patientInfoList);
console.log(JSON.parse(JSON.stringify((this.newpatientInfo))));


}

excelCheck(){
  console.log(this.data[0].length);

  if(this.data[0].length!=this.rowName.length){
    alert('导入的列数不符合')
    console.log(""+this.data[0].length+""+this.rowName.length)
    return
  }
  
for (let index = 0; index < this.data[0].length; index++) {
  if(this.data[0][index].trim!=this.rowName[index].trim){

  alert("格式不正确"+this.data[0][index]+" != "+this.rowName[index])
  return
  }
  
}
alert('格式正确')
  
}

getDataFromExcel(){
  console.log(this.data[0].length);

  this.patientInfoList=new Array<patientInfo>()

  for (let row = 1; row < this.data.length; row++) {

    let count=0

    this.newpatientInfo=new patientInfo();

  //一般情况
  //日期
  this.newpatientInfo.recordDate =this.data[row][count++];
	//姓名
  this.newpatientInfo.name =this.data[row][count++];
	//性别
  this.newpatientInfo.sexFlg =this.data[row][count++];
	//年齡
  this.newpatientInfo.age =this.data[row][count++];
  //入院日期
  this.newpatientInfo.dateOfAdmission =this.data[row][count++];
	//联系方式
  this.newpatientInfo.phoneNumber =this.data[row][count++];
	//身份证
  this.newpatientInfo.idNumber =this.data[row][count++];
  //住院号
  this.newpatientInfo.inpatientNumber =this.data[row][count++];

  //病理检查
	//病理号
	this.newpatientInfo.pathologicalExamination.pathologyNumber=this.data[row][count++];
	//临床诊断
	this.newpatientInfo.pathologicalExamination.clinicalDiagnosis=this.data[row][count++];
	//术中冰冻
	this.newpatientInfo.pathologicalExamination.intraoperativeFreezing=this.data[row][count++];
	//病理诊断
	this.newpatientInfo.pathologicalExamination.pathologicDiagnosis=this.data[row][count++];
	//特殊检查
	this.newpatientInfo.pathologicalExamination.specialInspection=this.data[row][count++];
	//肉眼所见
	this.newpatientInfo.pathologicalExamination.visibleToTheNakedEye=this.data[row][count++];

  //基本情况
  //身高
  this.newpatientInfo.height=this.data[row][count++];
  //体重
  this.newpatientInfo.weight=this.data[row][count++];
  //高血压
  this.newpatientInfo.hypertensionFlg=this.data[row][count++];
  //糖尿病
  this.newpatientInfo.diabetesMellitusFlg=this.data[row][count++];
  //高血脂
  this.newpatientInfo.hyperlipemiaFlg=this.data[row][count++];
  //慢病
  this.newpatientInfo.chronicDisease=this.data[row][count++];

	//临床诊断
  this.newpatientInfo.clinicalDiagnosis=this.data[row][count++];

  //胆汁酸
  this.newpatientInfo.bileAcid.cA=this.data[row][count++];
  this.newpatientInfo.bileAcid.cAPer=this.data[row][count++];
  this.newpatientInfo.bileAcid.dCA=this.data[row][count++];
  this.newpatientInfo.bileAcid.cDCA=this.data[row][count++];
  this.newpatientInfo.bileAcid.dCAPer=this.data[row][count++];
  this.newpatientInfo.bileAcid.cDCAPer=this.data[row][count++];
  this.newpatientInfo.bileAcid.uDCAOrHDCA=this.data[row][count++];
  this.newpatientInfo.bileAcid.uDCAPer=this.data[row][count++];
  this.newpatientInfo.bileAcid.lCA=this.data[row][count++];
  this.newpatientInfo.bileAcid.lCAPer=this.data[row][count++];
  this.newpatientInfo.bileAcid.gCA=this.data[row][count++];
  this.newpatientInfo.bileAcid.gCAPer=this.data[row][count++];
  this.newpatientInfo.bileAcid.gLCA=this.data[row][count++];
  this.newpatientInfo.bileAcid.gLCAPer=this.data[row][count++];
  this.newpatientInfo.bileAcid.gDCA=this.data[row][count++];
  this.newpatientInfo.bileAcid.gDCAPer=this.data[row][count++];
  this.newpatientInfo.bileAcid.gCDCA=this.data[row][count++];
  this.newpatientInfo.bileAcid.gCDCAPer=this.data[row][count++];
  this.newpatientInfo.bileAcid.gUDCA=this.data[row][count++];
  this.newpatientInfo.bileAcid.gUDCAPer=this.data[row][count++];
  this.newpatientInfo.bileAcid.tCA=this.data[row][count++];
  this.newpatientInfo.bileAcid.tCAPer=this.data[row][count++];
  this.newpatientInfo.bileAcid.tLCA=this.data[row][count++];
  this.newpatientInfo.bileAcid.tLCAPer=this.data[row][count++];
  this.newpatientInfo.bileAcid.tDCA=this.data[row][count++];
  this.newpatientInfo.bileAcid.tDCAPer=this.data[row][count++];
  this.newpatientInfo.bileAcid.tCDCA=this.data[row][count++];
  this.newpatientInfo.bileAcid.tCDCAPer=this.data[row][count++];
  this.newpatientInfo.bileAcid.tUDCA=this.data[row][count++];
  this.newpatientInfo.bileAcid.tUDCAPer=this.data[row][count++];
  this.newpatientInfo.bileAcid.bileAcid15=this.data[row][count++];
  
  //凝血常规
  //国际标准化比率
  this.newpatientInfo.coagulationRoutine.internationalStandardizationRatio=this.data[row][count++];
	//凝血酶原时间
	this.newpatientInfo.coagulationRoutine.prothrombinTime=this.data[row][count++];
	//凝血酶时间
	this.newpatientInfo.coagulationRoutine.ThrombinTime=this.data[row][count++];
	//纤维蛋白原
	this.newpatientInfo.coagulationRoutine.fibrinogen=this.data[row][count++];
	//部分凝血活酶时间
  this.newpatientInfo.coagulationRoutine.partialThromboplastinTime=this.data[row][count++];

  //血常规
  //  ABO血型
  this.newpatientInfo.routineBloodTest.aBOBloodGroup=this.data[row][count++];
  //  有核红细胞百分比
  this.newpatientInfo.routineBloodTest.percentageOfNucleatedRedBloodCells=this.data[row][count++];
  //  有核红细胞绝对值
  this.newpatientInfo.routineBloodTest.absoluteValueOfNucleatedRedBloodCells=this.data[row][count++];
  //  嗜碱性粒细胞绝对值
  this.newpatientInfo.routineBloodTest.basophilAbsoluteValue=this.data[row][count++];
  //  嗜酸性粒细胞绝对值
  this.newpatientInfo.routineBloodTest.eosinophilAbsoluteValue=this.data[row][count++];
  //  单核细胞绝对值
  this.newpatientInfo.routineBloodTest.absoluteValueOfMonocytes=this.data[row][count++];
  //  红细胞压积
  this.newpatientInfo.routineBloodTest.hematocrit=this.data[row][count++];
  //  嗜碱性粒细胞百分比
  this.newpatientInfo.routineBloodTest.percentageOfBasophils=this.data[row][count++];
  //  平均血小板体积
  this.newpatientInfo.routineBloodTest.meanPlateletVolume=this.data[row][count++];
  //  血小板分布宽度
  this.newpatientInfo.routineBloodTest.plateletDistributionWidth=this.data[row][count++];
  //  红细胞分布宽度CV
  this.newpatientInfo.routineBloodTest.erythrocyteDistributionWidthCV=this.data[row][count++];
  //  血红蛋白
  this.newpatientInfo.routineBloodTest.hemoglobin=this.data[row][count++];
  //  淋巴细胞绝对值
  this.newpatientInfo.routineBloodTest.lymphocyteAbsoluteValue=this.data[row][count++];
  //  血小板比积
  this.newpatientInfo.routineBloodTest.plateletSpecificVolume=this.data[row][count++];
  //  嗜酸性粒细胞百分比
  this.newpatientInfo.routineBloodTest.percentageOfEosinophils=this.data[row][count++];
  //  血小板计数
  this.newpatientInfo.routineBloodTest.plateletCount=this.data[row][count++];
  //  大血小板比率
  this.newpatientInfo.routineBloodTest.largePlateletRatio=this.data[row][count++];
  //  平均血红蛋白量
  this.newpatientInfo.routineBloodTest.meanHemoglobin=this.data[row][count++];
  //  嗜中性粒细胞绝对值
  this.newpatientInfo.routineBloodTest.neutrophilAbsoluteValue=this.data[row][count++];
  //  平均血红蛋白浓度
  this.newpatientInfo.routineBloodTest.meanHemoglobinConcentration=this.data[row][count++];
  //  淋巴细胞百分比
  this.newpatientInfo.routineBloodTest.percentageOfLymphocytes=this.data[row][count++];
  //  红细胞分布宽度SD
  this.newpatientInfo.routineBloodTest.redBloodCellDistributionWidthSd=this.data[row][count++];
  //  红细胞计数
  this.newpatientInfo.routineBloodTest.redBloodCellCount=this.data[row][count++];
  //  白细胞计数
  this.newpatientInfo.routineBloodTest.leukocyteCount=this.data[row][count++];
  //  嗜中性粒细胞百分比
  this.newpatientInfo.routineBloodTest.percentageOfNeutrophils=this.data[row][count++];
  //  单核细胞百分比
  this.newpatientInfo.routineBloodTest.percentageOfMonocytes=this.data[row][count++];
  //  平均红细胞体积
  this.newpatientInfo.routineBloodTest.meanRedBloodCellVolume=this.data[row][count++];

  //肝肾功能及血脂全套
  //血清游离脂肪酸(NEFA)
  this.newpatientInfo.liverAndKidneyFunction.serumFreeFattyAcids_NEFA=this.data[row][count++];
  //小而密低密度脂蛋白(sd-LDL)
  this.newpatientInfo.liverAndKidneyFunction.smallDenseLowDensityLipoprotein_SD_LDL=this.data[row][count++];
  //甘油三脂(TG)
  this.newpatientInfo.liverAndKidneyFunction.triglyceride_TG=this.data[row][count++];
  //载脂蛋白B(APOB)
  this.newpatientInfo.liverAndKidneyFunction.apolipoproteinB_apoB=this.data[row][count++];
  //胱抑素C(CysC)
  this.newpatientInfo.liverAndKidneyFunction.cystatinC_CysC=this.data[row][count++];
  //甘胆酸(CG)
  this.newpatientInfo.liverAndKidneyFunction.glycocholicAcid_CG=this.data[row][count++];
  //载脂蛋白A1(APOA)
  this.newpatientInfo.liverAndKidneyFunction.ApolipoproteinA1_ApoA=this.data[row][count++];
  //高密度脂蛋白胆固醇(HDL)
  this.newpatientInfo.liverAndKidneyFunction.highDensityLipoproteinCholesterol_HDL=this.data[row][count++];
  //白球比例(A:G)
  this.newpatientInfo.liverAndKidneyFunction.whiteballRatio_A_G=this.data[row][count++];
  //低密度脂蛋白胆固醇(LDL)
  this.newpatientInfo.liverAndKidneyFunction.lowDensityLipoproteinCholesterol_LDL=this.data[row][count++];
  //氯(CL)
  this.newpatientInfo.liverAndKidneyFunction.chlorine_CL=this.data[row][count++];
  //天门冬氨酸氨基转移酶(AST)
  this.newpatientInfo.liverAndKidneyFunction.aspartateAminotransferase_AST=this.data[row][count++];
  //eGFR-MDRD(eGFR(MDRD))
  this.newpatientInfo.liverAndKidneyFunction.eGFR_MDRD_eGFR_MDRD=this.data[row][count++];
  //eGFR-EPI Cr
  this.newpatientInfo.liverAndKidneyFunction.eGFR_EPI_Cr=this.data[row][count++];
  //丙氨酸氨基转移酶(ALT)
  this.newpatientInfo.liverAndKidneyFunction.alanineAminotransferase_ALT=this.data[row][count++];
  //钠(NA)
  this.newpatientInfo.liverAndKidneyFunction.sodium_NA=this.data[row][count++];
  //岩藻糖苷酶(AFU)
  this.newpatientInfo.liverAndKidneyFunction.fucosidase_AFU=this.data[row][count++];
  //乳酸脱氢酶(LDH)
  this.newpatientInfo.liverAndKidneyFunction.lactateDehydrogenase_LDH=this.data[row][count++];
  //脂蛋白(a)(LPa)
  this.newpatientInfo.liverAndKidneyFunction.lipoprotein_a_LPA=this.data[row][count++];
  //非高密度脂蛋白胆固醇(NHDL)
  this.newpatientInfo.liverAndKidneyFunction.nonHighDensityLipoproteinCholesterol_nhdl=this.data[row][count++];
  //直接胆红素(DBIL)
  this.newpatientInfo.liverAndKidneyFunction.directBilirubin_DBIL=this.data[row][count++];
  //谷氨酸脱氢酶(GLDH)
  this.newpatientInfo.liverAndKidneyFunction.glutamateDehydrogenase_GLDH=this.data[row][count++];
  //尿酸(URIC)
  this.newpatientInfo.liverAndKidneyFunction.uricAcid_uric=this.data[row][count++];
  //前白蛋白(PA)
  this.newpatientInfo.liverAndKidneyFunction.prealbumin_PA=this.data[row][count++];
  //球蛋白(GLB)
  this.newpatientInfo.liverAndKidneyFunction.globulin_GLB=this.data[row][count++];
  //载脂蛋白E(APOE)
  this.newpatientInfo.liverAndKidneyFunction.apolipoproteinE_ApoE=this.data[row][count++];
  //总胆固醇(CHOL)
  this.newpatientInfo.liverAndKidneyFunction.totalCholesterol_Chol=this.data[row][count++];
  //尿素氮(BUN)
  this.newpatientInfo.liverAndKidneyFunction.ureaNitrogen_BUN=this.data[row][count++];
  //纤维连接蛋白(FN)
  this.newpatientInfo.liverAndKidneyFunction.Fibronectin_FN=this.data[row][count++];
  //谷氨酰转肽酶(GGT)
  this.newpatientInfo.liverAndKidneyFunction.glutamyl_transpeptidase_GGT=this.data[row][count++];
  //钾(K)
  this.newpatientInfo.liverAndKidneyFunction.potassium_k=this.data[row][count++];
  //白蛋白(ALB)
  this.newpatientInfo.liverAndKidneyFunction.albumin_ALB=this.data[row][count++];
  //总胆红素(TBIL)
  this.newpatientInfo.liverAndKidneyFunction.totalBilirubin_TBIL=this.data[row][count++];
  //空腹血糖(GLU)
  this.newpatientInfo.liverAndKidneyFunction.fastingBloodGlucose_Glu=this.data[row][count++];
  //总胆汁酸(TBA)
  this.newpatientInfo.liverAndKidneyFunction.totalBileAcid_TBA=this.data[row][count++];
  //碱性磷酸酶(ALP)
  this.newpatientInfo.liverAndKidneyFunction.alkalinePhosphatase_ALP=this.data[row][count++];
  //肌酐(CREA)
  this.newpatientInfo.liverAndKidneyFunction.creatinineCrea=this.data[row][count++];
  //总蛋白(TP)
  this.newpatientInfo.liverAndKidneyFunction.totalProtein_TP=this.data[row][count++];

  //肿瘤标记物
  //癌胚抗原(CEA)
  this.newpatientInfo. laboratoryExamination.cea=this.data[row][count++];
  //糖类抗原(CA724)
  this.newpatientInfo.laboratoryExamination.ca724=this.data[row][count++];
  //糖类抗原(CA125)
  this.newpatientInfo.laboratoryExamination.ca125=this.data[row][count++];
  //甲胎蛋白(AFP)
  this.newpatientInfo. laboratoryExamination.afp=this.data[row][count++];
  //糖类抗原(CA19-9)
  this.newpatientInfo.laboratoryExamination.ca199=this.data[row][count++];
  //糖类抗原CA50
  this.newpatientInfo.laboratoryExamination.ca50=this.data[row][count++];
  //糖类抗原242
  this.newpatientInfo. laboratoryExamination.c242=this.data[row][count++];
  
  //胆汁酸全套
  //石胆酸
  this.newpatientInfo.completeSetOfBileAcids.lithocholicAcid=this.data[row][count++];
  //牛磺石胆酸
  this.newpatientInfo.completeSetOfBileAcids.taurocholicAcid1=this.data[row][count++];
  //甘氨石胆酸
  this.newpatientInfo.completeSetOfBileAcids.glycineCholicAcid=this.data[row][count++];
  //熊脱氧胆酸
  this.newpatientInfo.completeSetOfBileAcids.ursodeoxycholicAcid=this.data[row][count++];
  //胆酸
  this.newpatientInfo.completeSetOfBileAcids.cholicAcid=this.data[row][count++];
  //谷氨酸
  this.newpatientInfo.completeSetOfBileAcids.glutamate=this.data[row][count++];
  //鹅脱氧胆酸
  this.newpatientInfo.completeSetOfBileAcids.chenodeoxycholicAcid=this.data[row][count++];
  //鸟氨酸
  this.newpatientInfo.completeSetOfBileAcids.ornithine=this.data[row][count++];
  //牛磺鹅脱氧胆酸
  this.newpatientInfo.completeSetOfBileAcids.taurineChenodeoxycholicAcid=this.data[row][count++];
  //甘氨鹅脱氧胆酸
  this.newpatientInfo.completeSetOfBileAcids.glycineChenodeoxycholicAcid=this.data[row][count++];
  //脱氧胆酸
  this.newpatientInfo.completeSetOfBileAcids.deoxycholicAcid=this.data[row][count++];
  //亮氨酸
  this.newpatientInfo.completeSetOfBileAcids.leucine=this.data[row][count++];
  //脯氨酸
  this.newpatientInfo.completeSetOfBileAcids.proline=this.data[row][count++];
  //缬氨酸
  this.newpatientInfo.completeSetOfBileAcids.valine=this.data[row][count++];
  //甘氨脱氧胆酸
  this.newpatientInfo.completeSetOfBileAcids.glycodeoxycholicAcid=this.data[row][count++];
  //甘氨熊脱氧胆酸
  this.newpatientInfo.completeSetOfBileAcids.glycoursodeoxycholicAcid=this.data[row][count++];
  //牛磺脱氧胆酸
  this.newpatientInfo.completeSetOfBileAcids.taurodeoxycholicAcid=this.data[row][count++];
  //丙氨酸
  this.newpatientInfo.completeSetOfBileAcids.alanine=this.data[row][count++];
  //瓜氨酸
  this.newpatientInfo.completeSetOfBileAcids.citrulline=this.data[row][count++];
  //甘氨酸
  this.newpatientInfo.completeSetOfBileAcids.glycine=this.data[row][count++];
  //牛磺胆酸
  this.newpatientInfo.completeSetOfBileAcids.taurocholicAcid2=this.data[row][count++];
  //甘氨胆酸
  this.newpatientInfo.completeSetOfBileAcids.glycocholicAcid=this.data[row][count++];
  //牛磺熊脱氧胆酸
  this.newpatientInfo.completeSetOfBileAcids.taurineUrsodeoxycholicAcid=this.data[row][count++];
  //酪氨酸
  this.newpatientInfo.completeSetOfBileAcids.tyrosine=this.data[row][count++];
  //同型半胱氨酸(质谱)
  this.newpatientInfo.completeSetOfBileAcids.homocysteine_massSpectrometry=this.data[row][count++];
  //精氨酸
  this.newpatientInfo.completeSetOfBileAcids.arginine=this.data[row][count++];  

  //淋巴细胞技术
  //淋巴细胞绝对值
  this.newpatientInfo.lymphocyteTechnique.lymphocyteAbsoluteValue=this.data[row][count++];
  //CD4/CD8比值
  this.newpatientInfo.lymphocyteTechnique.cd4AndCd8Ratio=this.data[row][count++];
  //T淋巴细胞绝对值
  this.newpatientInfo.lymphocyteTechnique.tLymphocyteAbsoluteValue=this.data[row][count++];
  //B淋巴细胞绝对值
  this.newpatientInfo.lymphocyteTechnique.absoluteValueOfBLymphocytes=this.data[row][count++];
  //自然杀伤细胞CD56+CD16
  this.newpatientInfo.lymphocyteTechnique.naturalKillerCell_CD56_CD16=this.data[row][count++];
  //Ts淋巴细胞  CD8
  this.newpatientInfo.lymphocyteTechnique.tSLymphocyte_CD8=this.data[row][count++];
  //自然杀伤细胞绝对值
  this.newpatientInfo.lymphocyteTechnique.absoluteValueOfNaturalKillerCells=this.data[row][count++];
  //Ts淋巴细胞绝对值
  this.newpatientInfo.lymphocyteTechnique.tSLymphocyteAbsoluteValue=this.data[row][count++];
  //Th淋巴细胞CD4
  this.newpatientInfo.lymphocyteTechnique.thLymphocyte_CD4=this.data[row][count++];
  //T淋巴细胞CD3
  this.newpatientInfo.lymphocyteTechnique.tLymphocyte_CD3=this.data[row][count++];
  //Th淋巴细胞绝对值
  this.newpatientInfo.lymphocyteTechnique.absoluteValueOfThLymphocyte=this.data[row][count++];
  //B淋巴细胞CD19
  this.newpatientInfo.lymphocyteTechnique.bLymphocyte_CD19=this.data[row][count++];

  //传染病全套
  //乙肝表面抗原(YP)
  this.newpatientInfo.fullSetOfInfectiousDiseases.hepatitisBSurfaceAntigen_YP=this.data[row][count++];
  //乙肝表面抗体(YP)
  this.newpatientInfo.fullSetOfInfectiousDiseases. hepatitisBSurfaceAntibody_YP=this.data[row][count++];
  //HIV Ag/Ab
  this.newpatientInfo.fullSetOfInfectiousDiseases. hiv_Ag_Ab=this.data[row][count++];
  //丙肝抗体
  this.newpatientInfo.fullSetOfInfectiousDiseases. hepatitisCAntibody=this.data[row][count++];
  //乙肝核心抗体(YP)
  this.newpatientInfo.fullSetOfInfectiousDiseases. hepatitisBCoreAntibody_YP=this.data[row][count++];
  //乙肝e抗原(YP)
  this.newpatientInfo.fullSetOfInfectiousDiseases. hepatitisBEAntigen_YP=this.data[row][count++];
  //乙肝e抗体(YP)
  this.newpatientInfo.fullSetOfInfectiousDiseases. hepatitisBEAntibody_YP=this.data[row][count++];

  //超声结果接随访
  //初次发现息肉大小
  this.newpatientInfo.polypInformation.polypFirst=this.data[row][count++];
  //目前息肉大小
  this.newpatientInfo.polypInformation.polypNow=this.data[row][count++];
  //是否明确息肉为假性息肉 
  this.newpatientInfo.polypInformation.isFakePolypFlg=this.data[row][count++];
  //胆囊息肉伴随存在不能用其他原因解释的相关症状 
  this.newpatientInfo.polypInformation.relatedSymptoms=this.data[row][count++];
  //息肉数目 
  this.newpatientInfo.polypInformation.polypCount=this.data[row][count++];
  //位置 
  this.newpatientInfo.polypInformation.position=this.data[row][count++];
  //附着面 
  this.newpatientInfo.polypInformation.attachmentSurface=this.data[row][count++];
  //基底部 
  this.newpatientInfo.polypInformation.basalPart=this.data[row][count++];
  //胆囊壁（多选）  
  this.newpatientInfo.polypInformation.gallbladderWall=this.data[row][count++];
  //是否合并胆囊结石  
  this.newpatientInfo.polypInformation.gallstone=this.data[row][count++];
  //发现胆囊结石时间
  this.newpatientInfo.polypInformation.gallstoneTime=this.data[row][count++];
  //合并原发性硬化性胆管炎
  this.newpatientInfo.polypInformation.primarySclerosingCholangitis=this.data[row][count++];
  //胆囊炎：至少发作一次 
  this.newpatientInfo.polypInformation.cholecystitis=this.data[row][count++];
  //目前是否合并急性胆囊炎
  this.newpatientInfo.polypInformation.isAcuteCholecystitis=this.data[row][count++];
  //目前合并黄疸 
  this.newpatientInfo.polypInformation.isJaundice=this.data[row][count++];
  //是否合并脂肪肝 
  this.newpatientInfo.polypInformation.isFattyIiver=this.data[row][count++];
  //是否合并胆道结石 
  this.newpatientInfo.polypInformation.isCholelithiasis=this.data[row][count++];
  //是否合并胰腺异常  
  this.newpatientInfo.polypInformation.isPancreaticAbnormalities=this.data[row][count++];

  this.patientInfoList.push(this.newpatientInfo);

}

  
}

}
