//胆汁酸
export class bileAcid{
    cA:string='';
	cAPer:string='';
	dCA:string='';
	dCAPer:string='';
	cDCA:string='';
	cDCAPer:string='';
	uDCAOrHDCA:string='';
	uDCAPer:string='';
	lCA:string='';
	lCAPer:string='';
	gCA:string='';
	gCAPer:string='';
	gLCA:string='';
	gLCAPer:string='';
	gDCA:string='';
	gDCAPer:string='';
	gCDCA:string='';
	gCDCAPer:string='';
	gUDCA:string='';
	gUDCAPer:string='';
	tCA:string='';
	tCAPer:string='';
	tLCA:string='';
	tLCAPer:string='';
	tDCA:string='';
	tDCAPer:string='';
	tCDCA:string='';
	tCDCAPer:string='';
	tUDCA:string='';
	tUDCAPer:string='';
	bileAcid15:string='';	
};