import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http'
import { Observable } from 'rxjs';
import { analyseHistory } from '../stage/analyseHistory';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AnalyseService {

  //public apiUrl:String = "http://localhost:8080/"
  public apiUrl:String = environment.apiUrl
  constructor(public httpClient:HttpClient) { }


  changeAnalyseHistory(analyseHistory:analyseHistory,token:string): Observable<{}>{

    const httpOptions ={headers: new HttpHeaders({'Content-Type':'application/json','token':token}) } 
    return this.httpClient.post(this.apiUrl+"changeAnalyseHistory",analyseHistory,httpOptions)

  }

  getAnalyseInfoById(analyseHistoryId:String,token:string): Observable<{}>{


    const httpOptions ={headers: new HttpHeaders({'Content-Type':'application/json','token':token}) } 
    return this.httpClient.post(this.apiUrl+"getAnalyseInfoById",analyseHistoryId,httpOptions)

  }

  getAnalyseInfo(analyseHistory:analyseHistory,token:string): Observable<{}>{


    const httpOptions ={headers: new HttpHeaders({'Content-Type':'application/json','token':token}) } 
    return this.httpClient.post(this.apiUrl+"getAnalyseInfo",analyseHistory,httpOptions)

  }


  getAnalyseHistoryList(userId:string,token:string): Observable<{}>{


    const httpOptions ={headers: new HttpHeaders({'Content-Type':'application/json','token':token}) } 
    return this.httpClient.post(this.apiUrl+"getAnalyseHistoryList",userId,httpOptions)

  }

}
