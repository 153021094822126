import { Component, OnInit} from '@angular/core';
import { Router,NavigationExtras } from '@angular/router';
import { analyseHistory} from '../../../stage/analyseHistory';
import { MatDialog } from '@angular/material/dialog';
import { MessageComponent } from '../../../message/message.component';
import { DuplicateMessageComponent } from '../../../duplicateMessage/duplicateMessage.component';
import { SessionService } from '../../../services/session.service';
import { CommonService } from '../../../services/common.service';
import { AnalyseService } from '../../../services/analyse.service';
import { BusinessCheckService } from '../../../services/businessCheck.service';
import { LoadingSerProvider } from '../../../../common/loading/loadingSerProvider'
import { ValidationService } from '../../../services/validation.service';
@Component({
  selector: 'app-analyse-history',
  templateUrl: './analyse-history.component.html',
  styleUrls: ['./analyse-history.component.scss']
})
export class AnalyseHistoryComponent implements OnInit {




  public analyseHistoryList:any;

  public analyseHistory:analyseHistory=new analyseHistory();
  
  public userId:string="";
  
  public count:any=0;

  public date =this.commonService.getCurrentDate()

  constructor(
    private dialog: MatDialog,
    public router:Router,
    public sessionService:SessionService,
    public commonService:CommonService,
    public analyseService:AnalyseService,
    public businessCheckService:BusinessCheckService,
    private loadingSerProvider:LoadingSerProvider,
    private validationService:ValidationService
    ) { }



  
  ngOnInit(): void {
    const user=this.sessionService.getSession("user");

     if(!user){
      this.router.navigate(['/login']); 
      return;
     }


    this.userId=user.userId

    this.loadingSerProvider.show("请稍等");
    this.analyseService.getAnalyseHistoryList(user.userId,user.token).toPromise().then(dataJson=>{
    

        const response = JSON.parse(JSON.stringify((<any>dataJson) ))

        this.count=response.data.length
        this.analyseHistoryList = response.data;
   
        this.loadingSerProvider.hide()
      }).catch(err =>{
        this.loadingSerProvider.hide()
        this.validationService.showSysErroMessage();
        return;
      })
  }
  next(){

    const user=this.sessionService.getSession("user");

    if(this.businessCheckService.personInfoCheck(user.userId,user.token)){

      let dialogRef =this.dialog.open(MessageComponent,{ data: {
        message: ['請完善[我的資料]和[身體情況]']
    
        }});
    
       dialogRef.afterClosed().subscribe(result => {
    
          this.router.navigate(['/personalInfo']); 
  
      }); 


    }else {

   

    let remake:boolean= false;
    
      if(this.count!=0&&this.analyseHistoryList.lenght!=0)
      {
     
        if(this.analyseHistoryList[0].analyseDate == this.date){
          remake=true;
       
        }
      }
    if(remake){
    let dialogRef =this.dialog.open(DuplicateMessageComponent,{ data: {
      message: ['今天已经分析过了，是否重新分析覆盖今天分析的记录']
  
      }});
  
     dialogRef.afterClosed().toPromise().then(result => {
      if(result=='1'){

        this.show();

        this.analyseService.getAnalyseInfoById(this.analyseHistoryList[0].analyseHistoryId,user.token).toPromise().then(dataJson=>{
        
            console.log(this.analyseHistoryList[0]);
            
            const response = JSON.parse(JSON.stringify((<any>dataJson) ))

            this.sessionService.putSession("newAnalyse",response.data);
         
          

          }).catch(err =>{
            this.validationService.showSysErroMessage();
            return;
          })

 
      }

    }); 

  }else{

        
  this.show();
  }
 

  }

}

show(){

  this.analyseHistory.userId=this.userId;

  this.analyseHistory.analyseDate=this.date;

  this.sessionService.putSession("newAnalyse",this.analyseHistory);

  let dialogRef =this.dialog.open(MessageComponent,{ data: {
    message: ['为了更好的填写资料，请事先准备好以下资料','1.有关胆囊息肉的B超报告或者腹部超声报告','2.肿瘤指标报告','3.血脂系列报告']

    }});

   dialogRef.afterClosed().toPromise().then(result => {
    this.router.navigate(['/polypInformation']); 
  });

}


find(analyseHistoryId:string){

  let NavigationExtras:NavigationExtras={
    queryParams:{'analyseHistoryId':analyseHistoryId},
    fragment:'anchor'
    
    }

  this.router.navigate(['/historyInformation'],NavigationExtras); 
}


}
