import { polypInformation } from './polypInformation';
import { laboratoryExamination } from './laboratoryExamination';
export class analyseHistory{
  userId:string='';
  analyseHistoryId:string='';
  analyseDate:string='';//分析时间
  analyseResult:string='';//分析时间
  polypInformation:polypInformation=new polypInformation;
  laboratoryExamination:laboratoryExamination=new laboratoryExamination;
 
}